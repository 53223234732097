import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';

import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { UtilityService } from '../../utility/utility.service';
import { ChatService } from '../../chat-service/chat.service';

@Component({
  selector: 'app-event-join',
  templateUrl: './event-join.component.html',
  styleUrls: ['./event-join.component.css']
})
export class EventJoinComponent implements OnInit {
  userOnlineList: any = [];
  requested_users: any = [];
  btnindex: number = -1;
  notfound: boolean = false;
  itemsPerPage = 16;
  totalPages : 1; 
  activePage:number = 1;

  constructor(public chat: ChatService, public api: ApicallService, public user: UserinfoService, public toastr: ToastsManager, vcr: ViewContainerRef, public utility: UtilityService) {
    // this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.user.listen.subscribe((data) => { if (data) this.initAfterService() });
    this.chat.listen.subscribe((data)=> {if(data) this.initChatActivities()});

  }

  initChatActivities(): void {
    this.chat.sendNewUserNotification();
    this.getLoginUserInfo();
    this.getNewUser();
    this.onDisconnect();
  }

  getNewUser(): void {
    this.chat.getNewUserNotification()
      .subscribe(value => {
        let data = JSON.parse(value);
        console.log(data);
        let r = this.userOnlineList.filter(item => item.id === data.id);
        if(r.length == 0) 
          this.userOnlineList.push(data);
        this.requested_users.forEach(item => {
          if (data.id == item.user_uid) {
            item.isOnline = true;
          }
        });
        setTimeout(() => {
          this.sendLoginUserInfo(data);
        }, 1000);
      });
  }

  getLoginUserInfo(): void {
    this.chat.getPrivateMessage('loginuser')
      .subscribe(value => {
        let data = JSON.parse(value);
        console.log(data);
        let r = this.userOnlineList.filter(item => item.id === data.id);
        if(r.length == 0) 
          this.userOnlineList.push(data);
        this.requested_users.forEach(item => {
          if (data.id == item.user_uid) {
            item.isOnline = true;
          }
        });
        
      });
  }

  sendLoginUserInfo(data): void {
    this.chat.sendPrivateMesssage(data.id, 'loginuser', this.user.getUserGeneralInfo());
  }
  
  onDisconnect():void { 
    this.chat.onDisconnect()
    .subscribe( value => {
      let index:number = 0;
      this.requested_users.forEach(usr => {
        if(usr.id == value.id){
          this.requested_users[index].isOnline = false;
        }
        index++;
      });
    });
  }

  initAfterService(): void {
    this.getJoinRequestUsers();
  }

  displayActivePage(activePageNumber:number){  
    this.activePage = activePageNumber  
    //this.page  = activePageNumber  
    this.getJoinRequestUsers();
  }

  getJoinRequestUsers(): void {
    let param: URLSearchParams = new URLSearchParams();
    this.api.PostWithToken(param, 'get-user-join-request')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }
        //console.log(data.response);
        let eventobj = function () { }
        this.userOnlineList = [];
        data.response.forEach(el => {
          let tmp = new eventobj();
          //console.log(el);
          if(el.event != null) {
            tmp.join_id = el.id;
            tmp.event_title = el.event.title;
            tmp.event_id = el.event.id;
            tmp.user_uid = el.user.id;
            tmp.user_name = el.user.name;
            tmp.user_slug = el.user.slug;
            tmp.user_city = el.user.city;
            tmp.status = el.status;
            tmp.user_county = el.user.country_detail.cname;
            tmp.user_img = el.user.gender == 1 ? this.utility.maleimg : this.utility.femaleimg;
            tmp.user_img = el.user.user_profile_image.length > 0 ? el.user.user_profile_image[0].image_url : tmp.user_img;
            let arr = this.userOnlineList.filter(item => item.id === tmp.user_uid);
            if(arr.length > 0)
              tmp.isOnline = true;

            this.requested_users.push(tmp);
            
            console.log(this.requested_users);
          }
        });
        if (this.requested_users.length == 0) {
          this.notfound = true;
        }
      })
      .catch(error => {
        console.log(error);
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })
  }

  AllowOrDeny(event, index, action): void {
    this.btnindex = index;
    let param: URLSearchParams = new URLSearchParams();
    console.log(event);
    param.append('jid', event.join_id)
    param.append('action', action)
    this.api.PostWithToken(param, 'allow-deny-join-request')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }
        this.toastr.success('You have sucessfully ' + action + ' ' + event.user_name + ' to your favorite list. ', 'Success');
        this.btnindex = -1;
        event.status = action;
        if(action == 'allow')
          this.chat.sendNewMsgNotification({id: event.user_uid, msg: data.response, action: 'event-allow-deny-join-request'});

        //this.requested_users.splice(index, 1);
        // if (this.requested_users.length == 0) {
        //   this.notfound = true;
        // }

      })
      .catch(error => {
        console.log(error);
        this.btnindex = -1;
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })
  }

}
