import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { URLSearchParams } from '@angular/http';

import { ChatService } from '../chat-service/chat.service';
import { LoginService } from '../login/login.service';
import { UserinfoService } from '../userinfo/userinfo.service';
import { ApicallService } from '../app-service/apicall.service';
import { NotificationService } from '../notification/notification.service';

declare var $:any;

@Component({
  selector: 'app-inner-nav',
  templateUrl: './inner-nav.component.html',
  styleUrls: ['./inner-nav.component.css']
})
export class InnerNavComponent implements OnInit {

  unread_activity:number = 0;
  constructor(public login:LoginService, private router: Router, private chat:ChatService, public userinfo:UserinfoService, public observe:NotificationService, private api:ApicallService) { }

  ngOnInit() {
    this.menuToggle();
    //this.observe.listen.subscribe(data=> this.initChatActivities());
    //this.observe.getNewActivity();
    //this.observe.getNewMessage();
  }

  initChatActivities():void {

  }

  logout():void {
    this.login.logOut()
    .then(data => {
      if(data.error > 0)
        throw data.errmsg;

      this.chat.closeSocket();
      this.login.removeToken();
      this.userinfo.defaultValue();
      // this.router.navigate(['/home']);
      // window.location.reload();
      window.location.href = "/home";
    })
    .catch(error =>{
      console.log(error);
    })
  }

  menuToggle():void {
    $('.dropdown-submenu a.test').on("click", function(e){
        $(this).next('ul').toggle();
        e.stopPropagation();
        e.preventDefault();
    });
  }
}
