import { Component, OnInit, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastsManager } from 'ng2-toastr/ng2-toastr';
import { URLSearchParams } from '@angular/http';

import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { UtilityService } from '../../utility/utility.service';

declare var $: any;

@Component({
  selector: 'app-new-event',
  templateUrl: './new-event.component.html',
  styleUrls: ['./new-event.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NewEventComponent implements OnInit {

  loader: boolean = false;
  event_title: string = '';
  event_city: string = '';
  event_country: number = 1;
  country: any = [];
  is_group: string = '0';
  group_member: string = '0';
  posted_date: any = '';
  event_desc: string = '';
  feature_img: any;
  event_id: string = '';
  edit_mode: boolean = false;
  slug: string = '';
  autocomplete = {
    inputString: ''
  }

  constructor(public toastr: ToastsManager, vcr: ViewContainerRef, private route: ActivatedRoute, private api: ApicallService, public user: UserinfoService, private redirect: Router, public pdata: OptionDataService, public utility: UtilityService) { }

  ngOnInit() {
    this.user.listen.subscribe((data) => { if (data) this.initAfterService() });
    this.loadCountry();
    this.loadDatePicker();
  }

  initAfterService(): void {
    if (this.route.snapshot.params['slug'] != 0) {
      this.setvalue(this.route.snapshot.params['slug'])
    }
    this.autocomplete.inputString = this.event_city;
    this.autocomplete = Object.assign({}, this.autocomplete);
  }

  loadDatePicker(): void {
    let self = this;
    var date_input = $('input[name="date"]');
    let options = {
      format: 'mm/dd/yyyy',
      todayHighlight: true,
      autoclose: true,
      startDate: '-0m'
    };
    date_input.change(function () {
      self.posted_date = $(this).val();
      if ($(this).val() == '') {
        $(this).val(self.pdata.defultEndDate);
      }
    });
    date_input.datepicker(options);
  }

  setvalue(slug): void {
    let parm: URLSearchParams = new URLSearchParams();
    parm.append('slug', slug);
    console.log(parm.toString());
    this.api.PostWithToken(parm, 'get-event-details')
      .then(data => {
        if (data.error) {
          throw data.error;
        }
        this.event_id = data.response.id;
        this.event_title = data.response.title;
        this.event_desc = data.response.post_details;
        this.event_city = data.response.city;
        this.event_country = data.response.country.code;
        this.posted_date = this.utility.fromMySqlDateFormat(data.response.posted_date);
        this.is_group = data.response.is_group.toString();
        this.group_member = data.response.group_member;
        this.edit_mode = true;
        this.slug = slug;
        this.autocomplete.inputString = this.event_city;
        this.autocomplete = Object.assign({}, this.autocomplete);
        console.log(data);
      })
      .catch(error => {
        this.toastr.error(error, 'Error');
      })
  }


  createEvent(isvalid): boolean {
    if (!isvalid) {
      return false;
    }
    this.loader = true;
    let dobstr = $('input[name="date"]').val();
    let formData: FormData = new FormData();
    formData.append('title', this.event_title);
    formData.append('description', this.event_desc);
    formData.append('feature_image', this.feature_img);
    formData.append('city', this.event_city);
    formData.append('country_id', this.event_country.toString());
    formData.append('is_group', this.is_group);
    formData.append('posted_date', this.utility.toMySqlDateFormat(dobstr));
    formData.append('group_member', this.group_member);
    if (this.edit_mode) {
      formData.append('slug', this.slug);
    }
    this.api.PostWithTokenImage(formData, 'create-event')
      .then(data => {
        if (data.error) {
          throw data.error;
        }
        this.loader = false;
        if (this.edit_mode) {
          this.toastr.success('Event has been updated successfully.', 'Success');
        }
        else {
          this.toastr.success('Event has been added successfully.', 'Success');
        }
        this.redirect.navigate(['/post-list']);
        //window.location.href = "/post-list"
        this.event_title = '';
        this.event_desc = '';
      })
      .catch(error => {
        this.toastr.error('Can not process. please try later.', 'Error');
      })
    return true;
  }

  previewImage(img: any) {
    this.feature_img = img.target.files[0];

  }

  loadCountry(): void {
    this.api.PostWithOutToken(new URLSearchParams(), 'country-list')
      .then(data => {
        if (data.error) {
          throw data.error;
        }
        //console.log(data);
        this.country = data
      })
      .catch(error => {
        console.log(error);
      })
  }

  autoComplete(event: any) {
    console.log(event.data);
    if (event.data.address_components) {
      this.event_city = event.data.description;
      event.data.address_components.forEach(attr => {
        //console.log(attr.types);
        if (attr.types[0] = "country") {
          this.event_country = this.getCountryCodeByName(attr.long_name);
          console.log(this.event_country);
        }
      });
    } else {
      this.event_city = '';
    }
  }

  getCountryCodeByName(data: string): number {
    let country_id = 1;;
    this.country.forEach(item => {
      if (item.cname == data) {
        country_id = item.id;
        return item.id;
      }
    });
    return country_id;
  }
}
