import { Component, OnInit } from '@angular/core';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { UtilityService } from '../../utility/utility.service';
import { OptionDataService } from '../../option-data/option-data.service';

@Component({
  selector: 'profile-description-edit',
  templateUrl: './description-edit.component.html',
  styleUrls: ['./description-edit.component.css']
})
export class DescriptionEditComponent implements OnInit {

  constructor(public user:UserinfoService, public utility:UtilityService, public optdata:OptionDataService) { }

  ngOnInit() {
  }

}
