import { Component, OnInit, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastsManager } from 'ng2-toastr/ng2-toastr';
import { URLSearchParams } from '@angular/http';

import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { ChatService } from '../../chat-service/chat.service';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css']
})
export class EventDetailsComponent implements OnInit {

  show_join_button = true;
  loader: boolean = false;
  question: string = '';
  event: any = {
    title: '',
    city: '',
    country: '',
    desc: '',
    feature_img: '',
    id: '',
    uid: ''
  }
  constructor(public chat: ChatService, public toastr: ToastsManager, vcr: ViewContainerRef, private route: ActivatedRoute, private api: ApicallService, public user: UserinfoService, private redirect: Router) { }

  ngOnInit() {
    this.user.listen.subscribe((data) => { if (data) this.initAfterService() });
  }

  initAfterService(): void {
    this.setvalue(this.route.snapshot.params['slug'])
  }
  setvalue(slug): void {
    let parm: URLSearchParams = new URLSearchParams();
    parm.append('slug', slug);
    this.api.PostWithToken(parm, 'get-event-details')
      .then(data => {
        if (data.error) {
          throw data.error;
        }
        console.log(data.response);
        this.event.id = data.response.id;
        this.event.title = data.response.title;
        this.event.desc = data.response.post_details;
        this.event.city = data.response.city;
        this.event.slug = data.response.slug;
        this.event.country = data.response.country.cname
        this.event.posted_on = data.response.posted_date;
        this.event.is_group = data.response.is_group;
        this.event.joint_user = data.response.join_user.length;
        this.event.feature_img = (data.response.featureimage.length > 0) ? data.response.featureimage[0].image_url : 'assets/img1.png';
        this.event.uid = data.response.user_id;
        if (this.user.uid == this.event.uid || data.response.is_user_join.length) {
          this.show_join_button = false;
        }
      })
      .catch(error => {
        this.toastr.error(error, 'Error');
      })
  }

  joinEvent(): void {
    let parm: URLSearchParams = new URLSearchParams();
    parm.append('uid', this.event.uid);
    parm.append('eid', this.event.id);
    this.api.PostWithToken(parm, 'join-event')
      .then(data => {
        if (data.error) {
          throw data.error;
        }
        this.chat.sendNewMsgNotification({id: this.event.id, msg: data.response, action: 'event-join'});
        this.show_join_button = false;
        this.toastr.success('Event request has been sent successfully', 'Success');
      })
      .catch(error => {
        this.toastr.error(error, 'Error');
      })
  }

  askQuestion(): boolean {
    if (this.question.trim() == '' || this.question.length > 1200) {
      this.toastr.error('Make sure you have entered conrrect informatioin', 'Error');
      return false;
    }
    this.loader = true;
    let parm: URLSearchParams = new URLSearchParams();
    parm.append('uid', this.event.uid);
    parm.append('eid', this.event.id);
    parm.append('question', this.question);
    this.api.PostWithToken(parm, 'add-event-question')
      .then(data => {
        if (data.error) {
          throw data.error;
        }
        this.loader = false;
        this.question = '';
        this.toastr.success('Your question has been sent successfully', 'Success');
        this.chat.sendNewMsgNotification({id: this.event.id, slug: this.event.slug ,msg: data.response, action: 'event-question'});
      })
      .catch(error => {
        this.toastr.error(error, 'Error');
        this.loader = false;
      })
    return true;
  }

}
