import { Router } from '@angular/router';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';

import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { UtilityService } from '../../utility/utility.service';
import { ChatService } from '../../chat-service/chat.service';

declare var $: any;

@Component({
  selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.css']
})
export class VideoListComponent implements OnInit {

  posts: any = [];
  notfound: boolean = false;
  page: number = 0;
  loader: boolean = false;
  index_loader: number = -1;
  showMoreBtn: boolean = true;
  pagination: any = [];
  like_users: any = [];
  activePage:number = 0; 
  itemsPerPage = 16;
  totalPages : any; 

  constructor(public chat: ChatService, public api: ApicallService, public user: UserinfoService, public pdata: OptionDataService, public toastr: ToastsManager, private vcr: ViewContainerRef, private u: UtilityService, private router: Router) { }

  ngOnInit() {
    this.user.listen.subscribe((data) => { if (data) this.initAfterService() });
  }

  initAfterService() {
    this.loadPost({});
  }

  displayActivePage(activePageNumber:number){  
    this.activePage = activePageNumber  
    this.page  = activePageNumber  
    this.loadPost({});
  } 

  loadPost(search: any): void {
    this.loader = true;
    let param: URLSearchParams = new URLSearchParams();
    let eventobj = function () { };

    if (search.title) {
      param.append('title', search.title);
    }
    if (search.date) {
      param.append('date', this.u.toMySqlDateFormat(search.date));
    }

    param.append('page', this.page.toString());
    this.api.PostWithToken(param, 'login-user-post-list')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }

        this.pagination = data.pagination;
        this.totalPages = data.pagination.length;
        //console.log(data.response)
        data.response.forEach(el => {
          console.log(el);
          
          let tmp = new eventobj();
          tmp.event_id = el.id;
          tmp.post_title = el.title.substring(0, 50);
          tmp.description = el.post_details.substring(0, 100);
          tmp.img = (el.thumbnail === null || el.thumbnail === "") ? 'assets/default-video.jpg' : el.thumbnail;
          
          tmp.posted_on = el.posted_date;
          tmp.slug = el.slug;
          tmp.city = el.city;
          // tmp.country = el.country.cname;
          tmp.like = el.like.length;
          tmp.login_user_like = el.user_has_like.length;
          tmp.user_id = el.user_id;
          tmp.post_id = el.post_id;
          tmp.view_count = el.view_count === null ? 0 : el.view_count;
          tmp.question = el.post_question_count.length;
          this.posts.push(tmp);
        });
        this.loader = false;

        if (!this.posts.length) {
          this.notfound = true;

        }

        if (this.pagination.length > this.page + 1) {
         // this.page++;
        } else {
          this.showMoreBtn = false
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  deleteEvent(slug: string, index): void {
    this.index_loader = index;
    let param: URLSearchParams = new URLSearchParams();

    param.append('slug', slug);
    this.api.PostWithToken(param, 'remove-post')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }
        this.posts.splice(index, 1);;
        this.toastr.success('Post has been removed successfully.', 'Success');
        this.index_loader = -1;
      })
      .catch(error => {
        console.log(error);
      })
  }

  like_dislike(event): void {
    let param: URLSearchParams = new URLSearchParams();
    param.append('uid', event.user_id);
    param.append('eid', event.event_id);

    this.api.PostWithToken(param, 'post-like-dislike')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }
        console.log(data.response);
        if (event.login_user_like) {
          event.login_user_like = 0;
          event.like--;
        } else {
          event.login_user_like = 1;
          event.like++;
          this.chat.sendNewMsgNotification({id: event.event_id, slug: this.user.uslug, msg: data.response, action: 'post-like'});
        }
 
      })
      .catch(error => {
        console.log(error);
      })
  }

  search(qur): void {
    this.posts = [];
    this.showMoreBtn = true;
    this.loadPost(qur);
  }

  userLikeList(likes): void {
    likes.forEach(el => {
      let likeobj = new function () { }
      likeobj.slug = el.user.slug;
      likeobj.name = el.user.name;
      likeobj.img = el.user.gender == 1 ? this.u.maleimg : this.u.femaleimg;
      likeobj.img = el.user.user_profile_image.length > 0 ? el.user.user_profile_image[0].image_url : likeobj.img;
      this.like_users.push(likeobj);
    });
    console.log(this.like_users)
    $('#profile-user').modal('show');
  }

  showUserProfile(slug): void {
    $('#profile-user').modal('hide');
    this.router.navigate(['/profile', slug]);
  }

  deletePost(slug: string, index): void {
    this.index_loader = index;
    let param: URLSearchParams = new URLSearchParams();

    param.append('slug', slug);
    this.api.PostWithToken(param, 'remove-post')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }
        this.posts.splice(index, 1);;
        this.toastr.success('Post has been removed successfully.', 'Success');
        this.index_loader = -1;
      })
      .catch(error => {
        console.log(error);
      })
  }
}
