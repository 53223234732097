import { Component, OnInit } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { DatePipe } from '@angular/common';

import { UtilityService } from '../utility/utility.service'
import { ApicallService } from '../app-service/apicall.service';
import { UserinfoService } from '../userinfo/userinfo.service';
import { OptionDataService } from '../option-data/option-data.service';

declare var $:any;

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css']
})
export class PaymentHistoryComponent implements OnInit {
  isReactiveButton: boolean = true;
  plan:any = [];
  invoicelist:any = [];
  pagination:any = [];
  constructor(public utility:UtilityService, public api:ApicallService, public pdata:OptionDataService, public user:UserinfoService) { }

  ngOnInit() {
    this.initTabs();
    this.user.listen.subscribe((data)=> {if(data) this.initAfterService()});
  }

  initAfterService():void {
    console.log(this.user);
    if(!this.user.subscribe_expired && this.user.is_subscribe) {
      let name = "", monthly = this.user.net_expense, duration = "", status = "Cancel";
      if(this.user.subscribe_option == '1 Week') {
        name = "5/week";
        duration = "1 Week"
      } else if(this.user.subscribe_option == '1 Month') {
        name = "42.95/mo";
        duration = "1 Month";
      } else if(this.user.subscribe_option == '6 Month') {
        name = "30.95/mo";
        duration = "6 Month";
      } else if(this.user.subscribe_option == '1 Year') {
        name = "22.95/mo";
        duration = "1 Year";
      }

      this.plan = {
        name: name,
        monthly: monthly,
        duration: duration,
        status: status,
        lastdate: this.user.subscribe_date,
        nextdate: this.user.subscribe_expired_date
      };
      this.isReactiveButton = false;
    }
    this.getplanInfo();
    this.getInvoiceList(0);
  }

  initTabs():void {
    $('#horizontalTab').responsiveTabs({
      rotate: false,
      startCollapsed: 'accordion',
      collapsible: 'accordion',
      setHash: false,
    });
    
    $("#old_data").click(function(){
			$("#panel").slideToggle("slow");
		});
  }

  getplanInfo():void {
    let param:URLSearchParams = new URLSearchParams();
    this.api.PostWithToken( param, 'get-login-subscription')
    .then(data => {
      console.log(data.response);
      if(data.response){
        this.plan = data.response;
      }
    })
    .catch(error =>{
      console.log(error);
    })
  }

  setCancelPlan():void {
    let param:URLSearchParams = new URLSearchParams();
    this.api.PostWithToken( param, 'cancel-subscription')
    .then(data => {
      if(data.error){
        throw data.error;
      }
      this.getInvoiceList(0);
      this.getplanInfo();
      
    })
    .catch(error =>{
      alert(error);
    })
  }

  setActivePlan():void {
    let param:URLSearchParams = new URLSearchParams();
    this.api.PostWithToken( param, 'activate-subscription')
    .then(data => {
      if(data.error){
        throw data.error;
      }
        
        
      this.getInvoiceList(0);
      this.getplanInfo();
    })
    .catch(error =>{
      console.log(error);
    })
  }

  getInvoiceList(page):void {
    let param:URLSearchParams = new URLSearchParams();
    param.append('page', page);
    this.api.PostWithToken( param, 'payment-history')
    .then(data => {
      if(data.error)
        throw data.error;
      
      
      this.invoicelist = data.response.payment;
      this.pagination = data.response.pagination;
    })
    .catch(error =>{
      console.log(error);
    })
  }

}
