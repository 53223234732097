import { Component, OnInit } from '@angular/core';

import { URLSearchParams } from '@angular/http';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { UtilityService } from '../utility/utility.service'
import { ApicallService } from '../app-service/apicall.service';
import { UserinfoService } from '../userinfo/userinfo.service';
import { OptionDataService } from '../option-data/option-data.service';
import { ChatService } from '../chat-service/chat.service';

@Component({
  selector: 'app-viewed-me',
  templateUrl: './viewed-me.component.html',
  styleUrls: ['./viewed-me.component.css']
})
export class ViewedMeComponent implements OnInit {
  userOnlineList:any = [];
  matcheslist: any = [];
  notfound: boolean = false;
  btnindex: number = -1;
  username: string = '';
  page: number = 1;
  showMoreBtn: boolean = true;
  loader: boolean = false;
  itemsPerPage = 16;
  totalPages : any; 
  activePage:number = 1;
  search: any = {
    agefrom: '0',
    ageto: '0',
    search: '',
    isimage: false
  };

  constructor(public chat: ChatService, public utility: UtilityService, public api: ApicallService, public pdata: OptionDataService, public user: UserinfoService, private flash: CookieService, private router: Router) { }

  ngOnInit() {
    this.user.listen.subscribe((data) => { if (data) this.initAfterService() });
    this.chat.listen.subscribe((data)=> {if(data) this.initChatActivities()});

  }

  initChatActivities(): void {
    this.chat.sendNewUserNotification();
    this.getLoginUserInfo();
    this.getNewUser();
    this.onDisconnect();
  }

  displayActivePage(activePageNumber:number){  
    this.activePage = activePageNumber  
    this.page = activePageNumber  
    this.applySearch(false);
  } 

  getNewUser(): void {
    this.chat.getNewUserNotification()
      .subscribe(value => {
        let data = JSON.parse(value);
        // console.log(data);
        let r = this.userOnlineList.filter(item => item.id === data.id);
        if(r.length == 0) 
          this.userOnlineList.push(data);
        this.matcheslist.forEach(item => {
          if (data.id == item.id) {
            item.isOnline = true;
          }
        });
        setTimeout(() => {
          this.sendLoginUserInfo(data);
        }, 1000);
      });
  }

  getLoginUserInfo(): void {
    this.chat.getPrivateMessage('loginuser')
      .subscribe(value => {
        let data = JSON.parse(value);
        let r = this.userOnlineList.filter(item => item.id === data.id);
        if(r.length == 0) 
          this.userOnlineList.push(data);
        this.matcheslist.forEach(item => {
          if (data.id == item.id) {
            item.isOnline = true;
          }
        });
        
      });
  }

  sendLoginUserInfo(data): void {
    this.chat.sendPrivateMesssage(data.id, 'loginuser', this.user.getUserGeneralInfo());
  }
  
  onDisconnect():void { 
    this.chat.onDisconnect()
    .subscribe( value => {
      let index:number = 0;
      this.matcheslist.forEach(usr => {
        if(usr.id == value.id){
          this.matcheslist[index].isOnline = false;
        }
        index++;
      });
    });
  }

  initAfterService(): void {
    this.getMateches(new URLSearchParams());
  }

  getMateches(param: URLSearchParams): void {
    let userobj = function () { };
    this.loader = true;
    let pagination: number = 0;
    param.append('fname', 'me_as_fav');
    this.api.PostWithToken(param, 'get-profile-view-list')
      .then(data => {
        console.log(data);
        if (data.error > 0)
          throw data.errmsg;


        this.notfound = false;
        pagination = data.pagination.length;

        this.totalPages = pagination;
        this.matcheslist = [];

        data.response.forEach(item => {
          if (item != null) {
            let user = new userobj();
            user.id = item.id;
            user.name = item.name;
            user.city = item.city;
            user.img = item.gender == 1 ? this.utility.maleimg : this.utility.femaleimg;
            user.img = item.user_image.length > 0 ? item.user_image[0].image_url : user.img;
            user.country = item.country_detail.cname;
            user.age = this.utility.dateDiff(item.dob);
            user.ismutual = item.match_mutual_favorite.length > 0 ? true : false;
            user.uslug = item.slug;
            let arr = this.userOnlineList.filter(item => item.id === user.id);
            if(arr.length > 0)
              user.isOnline = true;
            this.matcheslist.push(user);
          }

        });
        this.notfound = this.matcheslist.length ? false : true;
        this.loader = false;
        if (this.page >= pagination) {
          this.showMoreBtn = false;
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  applySearch(isnewsearch: boolean): void {
    let parm = new URLSearchParams();
    //this.page++;
    if (isnewsearch) {
      this.matcheslist = [];
      this.showMoreBtn = true;
      this.notfound = false;
      this.page = 1;
    }

    parm.append('page', this.page.toString());
    if (this.search.agefrom != '0' && this.search.ageto != '0') {
      parm.append('agefrom', this.search.agefrom);
      parm.append('ageto', this.search.ageto);
    }
    if (this.search.search != '') {
      parm.append('name', this.search.search);
    }
    if (this.search.isimage) {
      parm.append('isimg', this.search.isimage);
    }
    console.log(this.search);
    this.getMateches(parm);
  }

  resetSearch(): void {
    this.search.agefrom = '0';
    this.search.ageto = '0';
    this.search.search = '';
    this.search.isimage = false;
    this.getMateches(new URLSearchParams());
  }

  addToFavt(user, index): void {
    this.btnindex = index;
    let param: URLSearchParams = new URLSearchParams();
    param.append('fid', user.id)
    this.api.PostWithToken(param, 'add-as-favorite')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }


        this.btnindex = -1;
        this.matcheslist.splice(index, 1);
        if (this.matcheslist.length == 0)
          this.notfound = true;
      })
      .catch(error => {
        this.btnindex = -1;

      })
  }

  openChatWindwo(user): void {
    this.flash.set('huser', user.id, (0.5 / 1440));
    this.router.navigate(['/messages']);
  }

}
