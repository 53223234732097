import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dialog-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {

  @Output() notifyConfirm : EventEmitter<any> = new EventEmitter<any>()
  constructor() { }

  ngOnInit() {
  }

  confirmYes(): void {
    this.notifyConfirm.emit(true);
  }

  confimNo(): void {
    this.notifyConfirm.emit(false);
  }

}
