import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';

import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { UtilityService } from '../../utility/utility.service'
import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { ChatService } from '../../chat-service/chat.service';

declare var $:any;

@Component({
  selector: 'app-smoke',
  templateUrl: './smoke.component.html',
  styleUrls: ['./smoke.component.css']
})
export class SmokeComponent implements OnInit {

  Object = Object;
  smoke:string = '0';
  drink:string = '0';
  cho_smoke:string = '0';
  cho_drink:string = '0';
  smokeimpt:string = '1';
  drinkimpt:string = '1';
  constructor(public chat: ChatService, public utility:UtilityService, public api:ApicallService, public pdata:OptionDataService, public user:UserinfoService,public toastr: ToastsManager, public vcr: ViewContainerRef) { }

  ngOnInit() {
    this.user.listen.subscribe(data=> this.initAfterService());
  }

  initAfterService():void {
    if(this.user.prefsmoke == null)
      this.user.prefsmoke = '0';  
    if(this.user.prefdrink == null)
      this.user.prefdrink = '0';
  }

  saveProfile(field, value):void {
    let param:URLSearchParams = new URLSearchParams();
    param.append(field, value);

    this.api.PostWithToken( param,'update-profile')
    .then(data => {
      console.log(data);
      if(data.error > 0)
        throw data.errmsg;

      switch(field){
        case 'smoke':
          this.user.usmoke = this.smoke;
          break;
        case 'drink':
          this.user.udrink = this.drink;
          break;
      }
      this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});

      $("#smok").modal('hide');
      $("#drink").modal('hide');
      this.toastr.success('Profile has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

  savePreference(field, value):void {
    
    let param:URLSearchParams = new URLSearchParams();
    param.append(field, value);
    
    this.api.PostWithToken( param,'update-profile-preference')
    .then(data => {
      if(data.error > 0)
        throw data.errmsg;

        switch(field){
          case 'smoke':
            this.user.prefsmoke = this.cho_smoke;
            break;
          case 'drink':
            this.user.prefdrink = this.cho_drink;
            break;
          case 'smoke_important':
            this.user.prefsmokeimt = this.smokeimpt;
            break;
          case 'drink_important':
            this.user.prefdringimt = this.drinkimpt;
            break;
        }
        $("#cho-smok").modal('hide');
        $("#cho-drink").modal('hide');
        $("#smok-impt").modal('hide');
        $("#drink-impt").modal('hide');
        this.toastr.success('Your preference infomration has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }
}
