import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { Router } from '@angular/router';

import { ToastsManager } from 'ng2-toastr/ng2-toastr';
import { ChatService } from '../chat-service/chat.service';
import { ApicallService } from '../app-service/apicall.service';
import { UserinfoService } from '../userinfo/userinfo.service';
import { NotificationService } from '../notification/notification.service';
import { UtilityService } from '../utility/utility.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login-footer',
  templateUrl: './login-footer.component.html',
  styleUrls: ['./login-footer.component.css']
})
export class LoginFooterComponent implements OnInit {
  chatlist: any = [];
  // favoriteList: any = [];
  eventList: any = [  ];
  constructor(private flash: CookieService, public chat: ChatService, public api: ApicallService, private router: Router, public toastr: ToastsManager, vcr: ViewContainerRef, public userinfo: UserinfoService, public observe: NotificationService, public helper: UtilityService) {
    this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    try {
      if(this.userinfo.listen != null)
        this.userinfo.listen.subscribe((data) => { if (data) this.initAfterService() });
    } catch (error) {
      
    }
      
    this.chat.listen.subscribe((data) => { if (data) this.initChatService() });
    console.log(this.router.url);
    
  }

  initAfterService() {
    
  }
  
  initChatService(): void {
    this.getNewMsgNotification();
    this.getChatUserList();
    // this.getEventList();
  }

  getChatUserList(): Promise<any> {
    this.chatlist = [];
    let parm: URLSearchParams = new URLSearchParams();
    return this.api.PostWithToken(parm, 'user-chat-list')
      .then(data => {
        if (data.error > 0) {
          throw data.error;
        }
        if(data.response) {
          data.response.forEach(item => {
            if(item.user != null)
              this.chatlist.push(item.user.id);
          });
        }
        return "OK";
      })
      .catch(error => {
        console.log(error);
        return error;
      })
  }

  getEventList(): Promise<any> {
    this.eventList = [];
    let param: URLSearchParams = new URLSearchParams();
    
    return this.api.PostWithToken(param, 'login-user-event-list')
      .then(data => {
        if (data.error > 0) {
          throw data.error;
        }
        data.response.forEach(el => {
          this.eventList.push(el.id);
        });
        return "OK";
      })
      .catch(error => {
        console.log(error);
        return error;
      })
  }

  getNewMsgNotification1(): void {
    this.chat.getNewMsgNotification()
      .subscribe(value => {
        let data = JSON.parse(value);    
      });
  }

  getNewMsgNotification() {
    
    this.chat.getNewMsgNotification()
    .subscribe(value => {
          
      let data = JSON.parse(value);
      // console.log(data.data.id +"=="+ this.userinfo.uid);
      // console.log(data);
      this.getChatUserList().then(v => {
      
        if(data.action == 'chat-message') {
          if (this.router.url != '/messages') {
            if ((this.helper.inArray(data.data.id, this.chatlist) && data.receiver_id == this.userinfo.uid)|| data.slug == this.userinfo.uslug) {
              // this.toastr.info('You have a new message from ' + data.name);
              this.flash.set('huser', data.data.id, (0.5 / 1440));
              
              this.toastr.onClickToast()
              .subscribe( toast => {  
                  if(data.authUserSlug != null)
                    // this.router.navigate(['/myprofile/' + data.authUserSlug]);
                    window.location.href = '/myprofile/' + data.authUserSlug;
                  else
                    this.router.navigate(['messages']);
              });
              // console.log('show notify');
              this.toastr.success('You have a new message from ' + data.data.name, null, {toastLife: 5000});
              this.observe.getNewMessage();
            }
          } 
        } else {
          // if(this.userinfo.uid == '613')
          //   debugger
          if (this.router.url != '/activities') {
            this.getEventList().then(v => {
              // console.log('event list');
              // console.log(this.eventList);
              let url = 'activities'
              let isUpdate = false;
              if (data.id == this.userinfo.uid && (data.action == 'visitor' || data.action == 'add-as-favorite')) {
                isUpdate = true;
              } else if (data.action == 'update-profile' && this.helper.inArray(data.id, this.chatlist)) {
                isUpdate = true;
              } else if (data.action == 'event-add-reply-msg' && data.id == this.userinfo.uid) {
                
                url = '/event-question-reply/' + data.slug;
                isUpdate = true;
              } else if (data.action.startsWith('event') && this.helper.inArray(data.id, this.eventList)) {
                isUpdate = true;
              } else if(data.action == 'event-allow-deny-join-request' && data.id == this.userinfo.uid) {
                isUpdate = true;
              } 
              if (isUpdate) {
                this.toastr.onClickToast()
                .subscribe( toast => {         
                  
                  if(data.action == 'event-like' || data.action == 'visitor' || data.action == 'add-as-favorite' || data.action == 'update-profile')
                    url = '/myprofile/' + data.slug;
                  else if(data.action == 'event-join')
                    url = '/event-ask-to-join';
                  else if(data.action == 'event-question') 
                    url = '/event-question-reply/' + data.slug;
                  this.router.navigate([url]);
                });
                // console.log('show notification');
                this.toastr.success(data.msg, null, {toastLife: 5000});
                this.observe.getNewActivity();
              }
            });
          }
        }
      });
    });
    
  }

  

}
