import { Injectable } from '@angular/core';

@Injectable()
export class UtilityService {

  public noImg = 'assets/receiver-profile.png';
  public maleimg = 'assets/male.png';
  public femaleimg = 'assets/female.png';
  public serverTime:string = '';
  constructor() { }
  
  arrjoin(obj, dlm):string {
    ///console.log(obj);
    return Object.keys(obj).map(function(x){return obj[x];}).join(dlm);
  }

  dateDiff(dob):string {
    let dobdate:number = new Date(dob).getFullYear();
    let curdata:number = new Date().getFullYear();
    return (curdata - dobdate).toString();
  }

  inArray(key, arr):boolean {
    for(let i = 0 ; i < arr.length; i++ ) {
      if(arr[i] == key)
        return true;
    }
    return false;
  }

  postedOn(datestr):string {
    let today:any = new Date(this.serverTime);
    let past:any = new Date(datestr);
    var diffMs = (today - past); // milliseconds between now & Christmas

    //console.log(past);

    var diffDays = Math.floor(diffMs / 86400000); // days
    if(diffDays > 0)
      return diffDays.toString() + ' days ago';

    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    if(diffHrs > 0)
      return diffHrs.toString() + ' hours ago';

    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    if(diffMins > 0)
      return diffMins.toString() + ' minutes ago';

    return 'just now';
  }

  isToday(datestr):boolean {
    let today:any = new Date(this.serverTime);
    let past:any = new Date(datestr);
    var diffMs = (today - past); // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
    //console.log(diffDays);
    return diffDays > 0 ? false : true;
  }

  toMySqlDateFormat(date:string):string {
    let strarr = date.split('/');
    let datestr = strarr[2]+'-'+strarr[0] + '-' + strarr[1]
    return datestr;
    //03-22-2016
  }

  fromMySqlDateFormat(date:string):string {
    let strarr = date.split('-');
    let datestr = strarr[1] + '/' + strarr[2] + '/' + strarr[0];
    return datestr;
  }
//2016-03-22
}
