import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { ToastsManager } from 'ng2-toastr/ng2-toastr';


import { UserinfoService } from '../../userinfo/userinfo.service';
import { UtilityService } from '../../utility/utility.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { ApicallService } from '../../app-service/apicall.service';
import { ChatService } from '../../chat-service/chat.service';


declare var $:any;

@Component({
  selector: 'profile-description-modal',
  templateUrl: './description-modal.component.html',
  styleUrls: ['./description-modal.component.css']
})
export class DescriptionModalComponent implements OnInit {
  Object = Object;
  uoccupation:string = "";
  ueducation:string = "0";
  uspirituality:string = "0";
  usmoke:string = "";
  udrink:string = "";
  thanks:any = {'one': '','two': '','three': ''};
  life:any = {'one': '','two': '','three': '','four': '','five': ''};
  skill:any = {'one': '','two': '','three': ''};
  frnds:any = {'one': '','two': '','three': '', 'four':''};
  kids:string = '';
  passion:string = "";
  leisure:string = "";
  sportplaying:any = [];
  sportwatching:any = {'one': '','two': '','three': ''};
  lookingpersion:string = "";
  influential:string = "";
  likereading:any = {'one': '','two': '','three': ''};
  favouritebook:any = {'one': '','two': '','three': ''};
  movieenjoy:any = {'one': '','two': '','three': ''};
  favouritemovie:any = {'one': '','two': '','three': ''};
  noticeaboutme:string = "";
  readenjoy:string = "";
  listenmusic:any = {'one': '','two': '','three': ''};
  favband:any = {'one': '','two': '','three': ''};
  likeplaying:any = {'one': '','two': '','three': ''};
  tvwatching:any = {'one': '','two': '','three': ''};
  favtv:any = {'one': '','two': '','three': ''};
  aboutme:string = "";
  peoplenotice:string = "";
  pet:any;
  school:string;
  politcal:string = '0';
  loader:boolean = false;
  
  constructor(public chat: ChatService, public user:UserinfoService, public utility:UtilityService, public pdata:OptionDataService, public api:ApicallService, public toastr: ToastsManager, vcr: ViewContainerRef) {
    // this.toastr.setRootViewContainerRef(vcr);
   }

  ngOnInit() {
    this.user.listen.subscribe((data)=> {if(data) this.initAfterService()});
  }

  initAfterService():void {
    
    this.uoccupation = this.user.ucareer;
    this.ueducation = this.user.ueducation;
    this.uspirituality = this.user.uspirituality;
    this.usmoke = this.user.usmoke;
    this.udrink = this.user.udrink;

    this.school = this.user.uschool;
    this.politcal = this.user.upolitical;

    this.thanks.one = this.user.thankful.one;
    this.thanks.two = this.user.thankful.two;
    this.thanks.three = this.user.thankful.three;
  
    this.life.one = this.user.livewithout.one;
    this.life.two = this.user.livewithout.two;
    this.life.three = this.user.livewithout.three;
    this.life.four = this.user.livewithout.four;
    this.life.five = this.user.livewithout.five;
 
    this.skill.one = this.user.skill.one;
    this.skill.two = this.user.skill.two;
    this.skill.three = this.user.skill.three;

    this.frnds.one = this.user.frnddesc.one;
    this.frnds.two = this.user.frnddesc.two;
    this.frnds.three = this.user.frnddesc.three;
    this.frnds.four = this.user.frnddesc.four;
  
    this.kids = this.user.ukids;
    this.passion = this.user.passion;
    this.leisure = this.user.leisuretime;

    this.sportwatching.one = this.user.sportwatching.one;
    this.sportwatching.two = this.user.sportwatching.two;
    this.sportwatching.three = this.user.sportwatching.three;
  
    this.lookingpersion = this.user.lookinginperson;
    this.influential = this.user.influential;

    this.likereading.one = this.user.likeread.one;
    this.likereading.two = this.user.likeread.two;
    this.likereading.three = this.user.likeread.three;
 
    this.favouritebook.one = this.user.favbook.one;
    this.favouritebook.two = this.user.favbook.two;
    this.favouritebook.three = this.user.favbook.three;

    this.movieenjoy.one = this.user.movieenjoy.one;
    this.movieenjoy.two = this.user.movieenjoy.two;
    this.movieenjoy.three = this.user.movieenjoy.three;

    this.favouritemovie.one = this.user.favmovie.one;
    this.favouritemovie.two = this.user.favmovie.two;
    this.favouritemovie.three = this.user.favmovie.three;
  
    this.noticeaboutme = this.user.noticeaboutme;
    this.readenjoy = this.user.readenjoy;
  
    this.listenmusic.one = this.user.listenmusic.one;
    this.listenmusic.two = this.user.listenmusic.two;
    this.listenmusic.three = this.user.listenmusic.three;

    this.favband.one = this.user.favband.one;
    this.favband.two = this.user.favband.two;
    this.favband.three = this.user.favband.three;
  
    this.likeplaying.one = this.user.likeplaying.one;
    this.likeplaying.two = this.user.likeplaying.two;
    this.likeplaying.three = this.user.likeplaying.three;
  
    this.tvwatching.one = this.user.tvwatching.one;
    this.tvwatching.two = this.user.tvwatching.two;
    this.tvwatching.three = this.user.tvwatching.three;
  
    this.favtv.one = this.user.favtv.one;
    this.favtv.two = this.user.favtv.two;
    this.favtv.three = this.user.favtv.three;

    this.aboutme = this.user.aboutme;
    this.peoplenotice = this.user.peoplenotice;

    this.pet =this.user.upet;
  
  }

  saveSingleData(field:string, value:string){
    this.loader = true;
    let param:URLSearchParams = new URLSearchParams();
    console.log(value);
    param.append(field, value);
    this.api.PostWithToken( param,'update-profile')
    .then(data => {
      console.log(data);
      if(data.error > 0){
        throw data.errmsg;
      }
        
      this.closeAllModal();
      this.reloadUserInfo();
      this.toastr.success('Profile has been updated successfully. ', 'Success');
      this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
      this.loader = false;
    })
  }

  reloadUserInfo():void {
    this.user.loadLoginUserInfo()
    .then(data => {
      if(data.error > 0)
        throw data.errmsg;

      this.user.loadData(data.response);
      this.loader = false;
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
      this.loader = false;
    })
  }

  getvalue(event, listarr, index):void {
    if(event.target.checked === true){
      listarr.push(event.target.value);
      // if(listarr.length < 3)
      //   listarr.push(event.target.value);
      // else
      //   event.target.checked = false;
    }else{
      for(let i = 0; i < listarr.length; i++){
        if(listarr[i] == event.target.value){
          listarr.splice(i, 1);
          break;
        }
      }
    }
  }

  closeAllModal():void {
    $('.modal.in:visible').modal('hide');
  }
}
