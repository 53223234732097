import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { Router } from '@angular/router';

import { ToastsManager } from 'ng2-toastr/ng2-toastr';
import { CookieService } from 'ngx-cookie-service';

import { UtilityService } from '../../utility/utility.service'
import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { ChatService } from '../../chat-service/chat.service';

@Component({
  selector: 'app-myfavorite',
  templateUrl: './myfavorite.component.html',
  styleUrls: ['./myfavorite.component.css']
})
export class MyfavoriteComponent implements OnInit {
  userOnlineList:any = [];
  matcheslist:any = [];
  country:any = [];
  notfound:boolean = false;
  btnindex:number = -1;
  username:string = '';
  page:number = 1;
  showMoreBtn:boolean = true;
  loader:boolean = false;
  search:any = {
    agefrom:'0',
    ageto:'0',
    search:'',
    countryget:'',
    isimage:false
  };
  itemsPerPage = 16;
  totalPages : any; 
  activePage:number = 1;

  constructor(public chat: ChatService, public utility:UtilityService, public api:ApicallService, public pdata:OptionDataService, public user:UserinfoService, public toastr: ToastsManager, vcr: ViewContainerRef, private flash:CookieService, private router: Router) {
    // this.toastr.setRootViewContainerRef(vcr);
   }

  ngOnInit() {
    this.user.listen.subscribe((data)=> {if(data) this.initAfterService()});
    this.chat.listen.subscribe((data)=> {if(data) this.initChatActivities()});
    this.loadCountry();
    this.page = 1;
  }

  initChatActivities(): void {
    this.chat.sendNewUserNotification();
    this.getLoginUserInfo();
    this.getNewUser();
    this.onDisconnect();
  }

  loadCountry():void {
    this.api.PostWithOutToken( new URLSearchParams(),'country-list')
    .then(data => {
      this.country = data
    })
    .catch(error =>{
      console.log(error);
    })
  }

  getNewUser(): void {
    this.chat.getNewUserNotification()
      .subscribe(value => {
        let data = JSON.parse(value);
        // console.log(data);
        let r = this.userOnlineList.filter(item => item.id === data.id);
        if(r.length == 0) 
          this.userOnlineList.push(data);
        this.matcheslist.forEach(item => {
          if (data.id == item.id) {
            item.isOnline = true;
          }
        });
        setTimeout(() => {
          this.sendLoginUserInfo(data);
        }, 1000);
      });
  }

  getLoginUserInfo(): void {
    this.chat.getPrivateMessage('loginuser')
      .subscribe(value => {
        let data = JSON.parse(value);
        let r = this.userOnlineList.filter(item => item.id === data.id);
        if(r.length == 0) 
          this.userOnlineList.push(data);
        this.matcheslist.forEach(item => {
          if (data.id == item.id) {
            item.isOnline = true;
          }
        });
        
      });
  }

  sendLoginUserInfo(data): void {
    this.chat.sendPrivateMesssage(data.id, 'loginuser', this.user.getUserGeneralInfo());
  }
  
  onDisconnect():void { 
    this.chat.onDisconnect()
    .subscribe( value => {
      let index:number = 0;
      this.matcheslist.forEach(usr => {
        if(usr.id == value.id){
          this.matcheslist[index].isOnline = false;
        }
        index++;
      });
    });
  }

  initAfterService():void {
    this.page = 1;
    this.getMateches(new URLSearchParams());
  }

  displayActivePage(activePageNumber:number){  
    this.activePage = activePageNumber  
    this.page  = activePageNumber  
    this.applySearch(false);
  }

  getMateches(param:URLSearchParams):void {
    let userobj = function(){};
    this.loader = true;
    let pagination:number = 0;
    param.append('fname', 'myfav');
    this.api.PostWithToken( param,'get-favorite-list')
    .then(data => {
      console.log(data);
      if(data.error > 0)
        throw data.errmsg;

      this.notfound = false;
      pagination = data.pagination.length;
      this.totalPages = pagination;//alert(this.totalPages)
      this.matcheslist = [];
      data.response.forEach(item => {
        if(item != null){
          let user = new userobj();
          user.id = item.id;
          user.name = item.name;
          user.city = item.city;
          user.img = item.gender==1 ? this.utility.maleimg : this.utility.femaleimg;
          user.img = item.user_image.length > 0 ? item.user_image[0].image_url : user.img;
          user.country = item.country_detail.cname;
          user.age = this.utility.dateDiff(item.dob);
          user.ismutual = item.match_mutual_favorite.length > 0 ? true : false;
          user.uslug = item.slug;
          let arr = this.userOnlineList.filter(item => item.id === user.id);
          if(arr.length > 0  || item.is_online == 1)
            user.isOnline = true;
          this.matcheslist.push(user);
        }
        
      });
      this.notfound = this.matcheslist.length ? false : true;
      this.loader = false;
      if(this.page >= pagination){
        this.showMoreBtn = false;
      }
    })
    .catch(error =>{
      console.log(error);
    })
  }

  applySearch(isnewsearch:boolean):void {
    let parm = new URLSearchParams();
    //this.page++;
    if(isnewsearch){
      this.matcheslist = [];
      this.showMoreBtn = true;
      this.notfound = false;
      this.page = 1;
    }
    parm.append('page', this.page.toString());
    if(this.search.agefrom != '0' && this.search.ageto != '0'){
      parm.append('agefrom', this.search.agefrom);
      parm.append('ageto', this.search.ageto);
    }
    if(this.search.search != '' ){
      parm.append('name', this.search.search);
    }
    if(this.search.isimage){
      parm.append('isimg', this.search.isimage);
    }
    if(this.search.countryget){
      parm.append('countryget', this.search.countryget);
    }
    console.log(this.search);
    this.getMateches(parm);
  }

  resetSearch():void {
    this.search.agefrom = '0';
    this.search.ageto = '0';
    this.search.search = '';
    this.search.isimage = false;
    this.matcheslist = [];
    this.notfound = false;
    this.showMoreBtn = true;
    this.getMateches(new URLSearchParams());
  }

 

  removeToFavt(user, index):void {
    this.btnindex = index;
    let param:URLSearchParams = new URLSearchParams();
    param.append('fid', user.id)
    this.api.PostWithToken( param,'remove-from-favorite')
    .then(data => {
      if(data.error > 0){
        throw data.errmsg;
      }
        
      this.toastr.success('You have sucessfully remove ' + user.name + ' from your favorite list. ', 'Success');
      this.btnindex = -1;
      this.matcheslist.splice(index,1);
      if(this.matcheslist.length == 0 )
        this.notfound = true;
    })
    .catch(error =>{
      this.btnindex = -1;
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }
  
  openChatWindwo(user):void {
    this.flash.set('huser', user.id, (0.5/1440));
    this.router.navigate(['/messages']);
  }

}
