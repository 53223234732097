import { Component , OnInit, Input , OnChanges , Output , EventEmitter} from '@angular/core';  
  
@Component({  
  selector: 'app-pagination',  
  templateUrl: './app-pagination.component.html'  
})  
  
export class PaginationComponent implements OnChanges {  
  
    //@Input() totalRecords = 0;  
    @Input() lastPage = 0;  
    @Input() recordsPerPage = 0;  
  
    @Output() onPageChange: EventEmitter<number> = new EventEmitter();  
  
    public pages: number [] = [];  
    activePage: number;  
    pageCounts: number;  
  
    ngOnChanges(): any {  
      const pageCount = this.lastPage;//this.getPageCount();         
      this.activePage = 1; 
      this.pageCounts = pageCount;  
      this.pages = this.getArrayOfPage(pageCount,this.activePage); 
      this.onPageChange.emit(1);  
    }  
  
    // private  getPageCount(): number {  
    //   let totalPage = 0;  
  
    //   if (this.totalRecords > 0 && this.recordsPerPage > 0) {  
    //     const pageCount = this.totalRecords / this.recordsPerPage;  
    //     const roundedPageCount = Math.floor(pageCount);  
  
    //     totalPage = roundedPageCount < pageCount ? roundedPageCount + 1 : roundedPageCount;  
    //   }  
  
    //   return totalPage;  
    // }  
  
    private getArrayOfPage(pageCount: number,activePage: number): number [] {  
      const pageArray = [];  
      var showPage = 2;
      var loopPage = pageCount;
      var loopStart = 1;
      if(pageCount > 3)
      {
          loopPage = activePage+showPage;
          loopStart = activePage;
      }

      if(this.pageCounts <= 3)
      {
        loopStart = 1;
        loopPage = this.pageCounts;
      }
          
      //alert(loopPage+"   =   "+this.activePage)
      
      if(loopPage >= pageCount && pageCount > 3)
      {
          loopStart = pageCount-3;
          loopPage = pageCount;
      }
      if(loopStart <= 0)
        loopStart = 1;
      //alert(loopStart+"   =   "+loopPage)
      if (pageCount > 0) {  
          for(let i = loopStart ; i <= loopPage ; i++) {  
            if(pageCount >= 2 && pageCount != i )
              pageArray.push(i);  
          }  
      }  
      console.log(pageCount+'  =   pageCount   =   '+loopStart+'  == kkkkkkkkkk   ===   '+ loopPage +'  ===  kkkkkkkk',pageArray)
      return pageArray;  
    }  
  
    onClickPage(pageNumber: number): void {  
        if (pageNumber >= 1 && pageNumber <= this.lastPage) {  
            this.activePage = pageNumber;  
            this.pages = this.getArrayOfPage(this.pageCounts,pageNumber); 
            this.onPageChange.emit(1); 
            this.onPageChange.emit(this.activePage);  
        }  
    }  
}
