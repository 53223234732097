import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';

import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { UtilityService } from '../../utility/utility.service'
import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { ChatService } from '../../chat-service/chat.service';

declare var $:any;

@Component({
  selector: 'app-religion',
  templateUrl: './religion.component.html',
  styleUrls: ['./religion.component.css']
})
export class ReligionComponent implements OnInit {

  Object = Object;
  spirituality:string = '';
  impt:string = '';
  constructor(public chat: ChatService, public utility:UtilityService, public api:ApicallService, public pdata:OptionDataService, public user:UserinfoService, public toastr: ToastsManager, public vcr: ViewContainerRef) { 
    // this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.user.listen.subscribe(data=> this.initAfterService());
  }

  initAfterService():void {
    if(this.user.prefreligionimt == null)
    this.user.prefreligionimt = '1';
  }

  saveProfile():void {
    let param:URLSearchParams = new URLSearchParams();
    param.append('spirituality', this.spirituality);

    this.api.PostWithToken( param,'update-profile')
    .then(data => {
      console.log(data);
      if(data.error > 0)
        throw data.errmsg;

      this.user.uspirituality = this.spirituality;
      this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});

      $("#reli").modal('hide');
      this.toastr.success('Profile has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

  savePreference():void {
    let param:URLSearchParams = new URLSearchParams();
    param.append('religion', this.impt);
    
    this.api.PostWithToken( param,'update-profile-preference')
    .then(data => {
      if(data.error > 0)
        throw data.errmsg;

        this.user.prefreligionimt = this.impt;
        $("#important").modal('hide');
        this.toastr.success('Religion and Spirituality has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

}
