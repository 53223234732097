/**
 * Created by griga on 7/11/16.
 */
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from "@angular/core";

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ProfileSetupComponent } from './profile-setup/profile-setup.component';
import { PreferenceComponent } from './preference/preference.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile/profile.component';
import { ChatComponent } from './chat/chat.component';
import { ProfileEditComponent } from './profile-edit/profile-edit/profile-edit.component';
import { GenerelSettingComponent } from './preference/generel-setting/generel-setting.component';
import { EthnicityComponent } from './preference/ethnicity/ethnicity.component';
import { LanguageComponent } from './preference/language/language.component';
import { AgeComponent } from './preference/age/age.component';
import { ChildrenComponent } from './preference/children/children.component';
import { ReligionComponent } from './preference/religion/religion.component';
import { IncomeComponent } from './preference/income/income.component';
import { SmokeComponent } from './preference/smoke/smoke.component';
import { DistanceComponent } from './preference/distance/distance.component';
import { MatchesComponent } from './allmatches/matches/matches.component';
import { MyfavoriteComponent } from './allmatches/myfavorite/myfavorite.component';
import { MeasfavoriteComponent } from './allmatches/measfavorite/measfavorite.component';
import { MutualuserComponent } from './allmatches/mutualuser/mutualuser.component';
import { IgnoreComponent } from './allmatches/ignore/ignore.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { ActivityComponent } from './activity/activity.component';
import { PaymentComponent } from './payment/payment.component';
import { PaynowComponent } from './paynow/paynow.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { AboutComponent } from './about/about.component';
import { HelpComponent } from './help/help.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermComponent } from './term/term.component';
import { ContactComponent } from './contact/contact.component';
import { ForgetpwdComponent } from './forgetpwd/forgetpwd.component';
import { ViewedMeComponent } from './viewed-me/viewed-me.component';
import { NewEventComponent } from './event/new-event/new-event.component';
import { EventComponent } from './event/event/event.component';
import { EventLoginUserListComponent } from './event/event-login-user-list/event-login-user-list.component';
import { NearEventsComponent } from './event/near-events/near-events.component';
import { EventDetailsComponent } from './event/event-details/event-details.component';
import { EventJoinComponent } from './event/event-join/event-join.component';
import { EventReplyComponent } from './event/event-reply/event-reply.component';
import { AuthGuard } from './auth.guard';
import { AsiaComponent } from './asia/asia.component';
import { AustraliaComponent } from './australia/australia.component';
import { EuropeComponent } from './europe/europe.component';
import { MiddleEastComponent } from './middle-east/middle-east.component';
import { MuslimDatingComponent } from './muslim-dating/muslim-dating.component';
import { PostComponent } from './post/post/post.component';
import { NewPostComponent } from './post/new-post/new-post.component';
import { PostDetailsComponent } from './post/post-details/post-details.component';
import { NearPostsComponent } from './post/near-posts/near-posts.component';
import { PostReplyComponent } from './post/post-reply/post-reply.component';
import { VideoListComponent } from './post/video-list/video-list.component';

const routes: Routes = [
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{ path: 'home', component: HomeComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'profile-setup', component: ProfileSetupComponent, canActivate: [AuthGuard], },
	{ path: 'preference', component: PreferenceComponent, canActivate: [AuthGuard], },
	{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], },
	{ path: 'messages', component: ChatComponent, canActivate: [AuthGuard], },
	{ path: 'profile/:slug', component: ProfileComponent, canActivate: [AuthGuard], },
	{ path: 'myprofile/:slug', component: ProfileComponent, canActivate: [AuthGuard], },
	{ path: 'profile-edit', component: ProfileEditComponent, canActivate: [AuthGuard], },
	{ path: 'general-setting', component: GenerelSettingComponent, canActivate: [AuthGuard], },
	{ path: 'ethnicity', component: EthnicityComponent, canActivate: [AuthGuard], },
	{ path: 'language', component: LanguageComponent, canActivate: [AuthGuard] },
	{ path: 'age', component: AgeComponent, canActivate: [AuthGuard], },
	{ path: 'children', component: ChildrenComponent, canActivate: [AuthGuard], },
	{ path: 'religion', component: ReligionComponent, canActivate: [AuthGuard], },
	{ path: 'income', component: IncomeComponent, canActivate: [AuthGuard], },
	{ path: 'smoke', component: SmokeComponent, canActivate: [AuthGuard], },
	{ path: 'distance', component: DistanceComponent, canActivate: [AuthGuard], },
	{ path: 'matches', component: MatchesComponent, canActivate: [AuthGuard], },
	{ path: 'my-favorite', component: MyfavoriteComponent, canActivate: [AuthGuard], },
	{ path: 'favorites-me', component: MeasfavoriteComponent, canActivate: [AuthGuard], },
	{ path: 'mutual-favorite', component: MutualuserComponent, canActivate: [AuthGuard], },
	{ path: 'hidden', component: IgnoreComponent, canActivate: [AuthGuard], },
	{ path: 'myaccount', component: MyaccountComponent, canActivate: [AuthGuard], },
	{ path: 'profile-setting', component: MyaccountComponent, canActivate: [AuthGuard], },
	{ path: 'change-password', component: MyaccountComponent, canActivate: [AuthGuard], },
	{ path: 'billing', component: MyaccountComponent, canActivate: [AuthGuard], },
	{ path: 'notifications-setting', component: MyaccountComponent, canActivate: [AuthGuard], },
	{ path: 'activities', component: ActivityComponent, canActivate: [AuthGuard], },
	{ path: 'visitors', component: ActivityComponent, canActivate: [AuthGuard], },
	{ path: 'profile-update', component: ActivityComponent, canActivate: [AuthGuard], },
	{ path: 'photo-update', component: ActivityComponent, canActivate: [AuthGuard], },
	{ path: 'who-viewed-me', component: ViewedMeComponent, canActivate: [AuthGuard], },

	{ path: 'payment-options', component: PaymentComponent, canActivate: [AuthGuard], },
	{ path: 'payment-pay-now/:sid', component: PaynowComponent, canActivate: [AuthGuard], },
	{ path: 'payment-history', component: PaymentHistoryComponent, canActivate: [AuthGuard], },

	{ path: 'new-event/:slug', component: NewEventComponent, canActivate: [AuthGuard], },
	{ path: 'post-list', component: EventLoginUserListComponent, canActivate: [AuthGuard], },
	{ path: 'event', component: EventComponent, canActivate: [AuthGuard], },
	{ path: 'near-events', component: NearEventsComponent, canActivate: [AuthGuard], },
	{ path: 'event-details/:slug', component: EventDetailsComponent, canActivate: [AuthGuard], },
	{ path: 'event-ask-to-join', component: EventJoinComponent, canActivate: [AuthGuard], },
	{ path: 'event-question-reply/:slug', component: EventReplyComponent, canActivate: [AuthGuard], },

	{ path: 'post', component: PostComponent, canActivate: [AuthGuard], },
	{ path: 'video-list', component: VideoListComponent, canActivate: [AuthGuard], },
	{ path: 'new-post/:slug', component: NewPostComponent, canActivate: [AuthGuard], },
	{ path: 'post-details/:slug', component: PostDetailsComponent, canActivate: [AuthGuard], },
	{ path: 'near-posts', component: NearPostsComponent, canActivate: [AuthGuard], },
	{ path: 'post-question-reply/:slug', component: PostReplyComponent, canActivate: [AuthGuard], },

	{ path: 'about', component: AboutComponent, },
	{ path: 'help', component: HelpComponent, },
	{ path: 'privacy', component: PrivacyComponent, },
	{ path: 'termsandcondition', component: TermComponent, },
	{ path: 'contact', component: ContactComponent, },
	{ path: 'forget-password/:token', component: ForgetpwdComponent, },
	{ path: 'asia', component: AsiaComponent, },
	{ path: 'australia', component: AustraliaComponent, },
	{ path: 'europe', component: EuropeComponent, },
	{ path: 'middle-east', component: MiddleEastComponent, },
	{ path: 'muslim', component: MuslimDatingComponent, },
	{ path: '**', component: HomeComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: false });
