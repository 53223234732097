import { Injectable } from '@angular/core';

@Injectable()
export class OptionDataService {

  public agearr: any = [];
  constructor() {
    for (let i = 18; i <= 90; i++)
      this.agearr.push(i);
  }

  public relationstatus = {
    '0': 'Not Specified',
    '1': 'Never Married',
    '2': 'Divorced',
    '3': 'Separated',
    '4': 'Widowed'
  };

  public ethnicity = {
    '0': 'Not Specified',
    '1': 'White',
    '2': 'Hispanic / Latino',
    '3': 'Black / African descent',
    '4': 'Asian / Pacific Islander',
    '5': 'Indian',
    '6': 'Chinese',
    '7': 'Native American',
    '8': 'Arabic / Middle Eastern',
    '9': 'Korean',
    '10': 'Japanese',
    '11': 'Other',
    '12': 'No Preference'
  };

  public spirituality = {
    '0': 'Not Specified',
    '1': 'Buddhist',
    '2': 'Christian',
    '3': 'Hindu',
    '4': 'Jewish',
    '5': 'Muslim',
    '6': 'New Age',
    '8': 'Sikh',
    '9': 'Shinto',
    '1002': 'Spiritual, but not religious',
    '1003': 'Neither religious nor spiritual',
    '13': 'Baha\'i',
    '15': 'Confucianism',
    '16': 'Jainism',
    '17': 'Christian Science',
    '18': 'Rastafarianism',
    '19': 'Taoism',
    '20': 'Tenrikyo',
    '21': 'Unitarian-Universalism',
    '22': 'Scientology',
    '23': 'Metaphysical',
    '24': 'Pagan',
    '25': 'Wiccan',
    '26': 'Prefer not to specify'
  };

  public political = {
    '0': 'I don\'t have any or I don\'t know',
    '1': 'Some other viewpoint',
    '2': 'Very Conservative',
    '3': 'Somewhat Conservative',
    '4': 'Centrist/Middle of the Road',
    '5': 'Somewhat Liberal',
    '6': 'Very Liberal',
    '8': 'Green',
    '9': 'Libertarian'
  };

  public education = {
    '0': 'Not Specified',
    '1': 'Doctorate',
    '2': 'Masters',
    '3': 'Bachelors',
    '4': 'Associates',
    '5': 'Some College',
    '6': 'High School'
  };

  public income = {
    '0': 'Not Specified',
    '2': ' Less  then $20,000',
    '3': '$20,000 - $40,000',
    '4': '$40,000 - $60,000',
    '5': '$60,000 - $125,000',
    '6': '$125,000 - $250,000',
    '7': 'More then $250,000'
  };

  public kidstatus = {
    '0': 'Not Specified',
    '1': 'Yes, I want (more) kids',
    '2': 'Maybe',
    '3': 'No, I don\'t want (more) kids'
  };

  public drink = ['Never', 'Socially', 'Once a week', 'Few times a week', 'Daily', 'Not Specified'];
  public smoke = ['Never', 'Socially', 'Once a week', 'Few times a week', 'Daily', 'Not Specified'];
  public uimlookingfor = ['Guy', 'Male', 'From 21 to 30', 'Friendship', 'Go to the chess club'];
  public uinterests = ['Journeys', 'Snowboarding', 'Design', 'Video games', 'Cross stitc', 'Foreign languages', 'Watching horror movies', 'Detectives', 'Dances', 'Game of thrones', 'Evening walks'];

  public pimportant = {
    '1': 'Not at all important',
    //'4' : 'Some what important',
    '7': 'Very important'
  };

  public distance = {
    '200': 'In my city',
    '3000': 'In my country',
    '0': 'Any where in the world!',
    //'1': 'Specific Countries'
  };

  public usadistance = {
    '5': 'Within 5 miles',
    '10': 'Within 10 miles',
    '20': 'Within 20 miles',
    '30': 'Within 30 miles',
    '200': 'In my city',
    '3000': 'In my country',
    '0': 'Any where in the world!',
    //'1': 'Specific Countries'
  };

  public language = ['FRENCH', 'ENGLISH', 'CANTONESE', 'GERMAN', 'HINDI', 'ITALIAN', 'JAPANESE', 'MANDARIN', 'PORTUGUESE', 'RUSSIAN', 'SPANISH', 'OTHER'];

  public frndDescribe = ['Good Listener', 'Modest', 'Respectful', 'Affectionate', 'Caring', 'Spontaneous', 'Physically Fit', 'Warm', 'Outgoing', 'Optimistic', 'Dependable', 'Romantic', 'Creative', 'Loyal', 'Spiritual', 'Kind', 'Ambitious', 'Articulate', 'Rational', 'Easy-Going', 'Generous', 'Happy', 'Quiet', 'Genuine', 'Intelligent', 'Hard Working', 'Thoughtful', 'Sweet', 'Passionate', 'Energetic', 'Funny', 'Perceptive'];

  public listenMusic = ['Blues', 'Classical', 'Country', 'Folk & Traditional', 'Heavy Metal', 'Jazz', 'Other', 'Pop|Rock', 'Punk | Alternative', 'R&B and Soul', 'Rap | Hiphop', 'Top 40'];

  public playMusic = ['Bluegrass', 'Blues', 'Classical', 'Country', 'Electronic', 'Tradiational', 'Gospel', 'Jazz', 'Lating', 'Metal', 'Punk', 'R&B and Soul', 'Hip-Hop', 'Reggae', 'Rock and Pop', 'Other'];

  public reading = ['Biography', 'Contemporary Fiction', 'Historical Fiction', 'History', 'Literature | Fiction', 'Mystery | Thrillers', 'Nonfiction', 'Other'];

  public movie = ['Action', 'Animation', 'Classic Hollywood', 'Comedy', 'Crime Drama', 'Documentary| Non Fiction', 'Drama', 'Foreign | Art House', 'Historical Drama', 'Other', 'Romantic', 'Science Fiction', 'Superhero (Superman, Batman, X-Men, etc.)'];

  public sportPlaying = ['Baseball', 'Basketball', 'Bowling', 'Cycling', 'Football', 'Hiking', 'Hockey', 'Horseback Riding', 'Kayaking', 'Martial Arts', 'Mountain Biking', 'Mountain Climbing', 'Rollerblading', 'Running', 'Shooting', 'Skiing or Snowboarding', 'Surfing', 'Swimming', 'Other'];

  public sportWatching = ['Auto Racing', 'Baseball', 'Basketball', 'Extreme Sports', 'Football (American)', 'Golf', 'Gymnastics', 'Hockey', 'Skateboarding', 'Skiing', 'Soccer', 'Swimming', 'Tennis', 'Track and Field', 'Other'];

  public watchingTV = ['Action', 'Animation', 'Comedy', 'Crime Drama', 'Documentay Non fiction', 'Drama', 'Educational', 'News Journalism', 'Other', 'Reality Show', 'Science Fiction', 'Soap Opera', 'True Crime'];

  public pet = ['Birds', 'Cats', 'Comedy', 'Dogs', 'Exotic pets', 'Fish', 'Horses', 'Rabbits', 'Reptiles', 'Rodents', 'I don\'t like pets'];

  public yesno = ['No', 'Yes'];

  public notification: any = {
    generalErrSub: 'Oops!',
    generalSuccessSub: 'Success!',
    generalErrMsg: 'Please make sure that you have put correct data.....',
    generalSucessMsg: 'Profile has been updated successfully...',
    chatSndMsg: 'Message has been sent successfully...'

  }

  public defultEndDate = '01/31/2000';
}
