import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';

import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { UtilityService } from '../../utility/utility.service'
import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { ChatService } from '../../chat-service/chat.service';

declare var $:any;

@Component({
  selector: 'app-children',
  templateUrl: './children.component.html',
  styleUrls: ['./children.component.css']
})
export class ChildrenComponent implements OnInit {

  Object = Object;
  kinno = 0;
  constructor(public chat: ChatService, public utility:UtilityService, public api:ApicallService, public pdata:OptionDataService, public user:UserinfoService,public toastr: ToastsManager, public vcr: ViewContainerRef) {
    // this.toastr.setRootViewContainerRef(vcr);
   }

  ngOnInit() {
    this.user.listen.subscribe((data)=> {if(data) this.initAfterService()});
  }
  
  initAfterService(){
    console.log(this.user.prefpartnerkid)
    if(this.user.prefpartnerkid === null){
      this.user.prefpartnerkid = '0';
    }
  }
  saveProfile():void {
    let param:URLSearchParams = new URLSearchParams();
    param.append('children', this.kinno.toString());

    this.api.PostWithToken( param,'update-profile')
    .then(data => {
      console.log(data);
      if(data.error > 0)
        throw data.errmsg;

        this.user.uchildren = this.kinno.toString();
        this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});
        $("#uchild").modal('hide');
        this.toastr.success('Profile has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }
  savePreference(field:string, value:string):void {
    let param:URLSearchParams = new URLSearchParams();
    param.append(field, value);
    
    this.api.PostWithToken( param,'update-profile-preference')
    .then(data => {
      //console.log(data);
      if(data.error > 0)
        throw data.errmsg;

        $("#partner_kid").modal('hide');
        $("#own_kid").modal('hide');
        this.toastr.success('Children information has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }
}
