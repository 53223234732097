import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { ApicallService } from '../app-service/apicall.service';
import { UtilityService } from '../utility/utility.service';
import { ChatService } from '../chat-service/chat.service';
import { UserinfoService } from '../userinfo/userinfo.service';
import { OptionDataService } from '../option-data/option-data.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {

  activePage:number = 1; 
  search:any = {
    agefrom:18,
    ageto:60,
    country:0,
    search:'',
    isimage:false,
    isOnline: false
  };
  country:any = [];
  userlist:any = [];
  newuser:any = [];
  btnindex:number = -1;
  showMoreBtn:boolean = true;
  loader:boolean = false;
  page:number  = 1;
  onlinePage: number = 1;
  showMoreBtnOnline:boolean = true;
  passenger: any; 
  itemsPerPage = 16;
  totalPages : any; 
  
  
  constructor(public chat:ChatService, public api:ApicallService, private router: Router, public utility:UtilityService, public userinfo:UserinfoService, public pdata:OptionDataService, private flash:CookieService, public toastr: ToastsManager, vcr: ViewContainerRef) {
    // this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.userinfo.listen.subscribe((data)=> {if(data) this.initAfterService()});
    this.chat.listen.subscribe((data)=> {if(data) this.initChatService()});
    this.loadCountry();
    
  }

  initAfterService(){
    this.getMatchUsers();
    this.loadOnlineUsers();
  }

  displayActivePage(activePageNumber:number){  
    this.activePage = activePageNumber  
    this.onlinePage  = activePageNumber  
    this.loadOnlineUsers();
  }  

  initChatService():void {
    this.chat.sendNewUserNotification();
    this.getLoginUserInfo();
    this.getNewUser();
    this.onDisconnect();
  }

  getMatchUsers():void{
    this.loader = true;
    let userobj = function(){};
    let pagination:number = 0;
    
    let param:URLSearchParams = new URLSearchParams();
    param.append('page', this.page.toString());
    this.api.PostWithToken( param,'desktop-search')
    .then(data => {
      if(data.error > 0){
        throw data.errmsg;
      }
      pagination = data.pagination.length;
     
      data.response.forEach(item => {
        let user = new userobj();
        user.id = item.id;
        user.name = item.name;
        user.img = item.gender==1 ? this.utility.maleimg : this.utility.femaleimg;
        user.img = item.user_image.length > 0 ? item.user_image[0].image_url : user.img;
        user.country = item.country_detail != null ? item.country_detail.cname : "";
        user.age = this.utility.dateDiff(item.dob);
        user.uslug = item.slug;
        user.ismutal = item.match_mutual_favorite.length > 0 ? true : false;
        user.isfavorite = item.match_user_favorite.length > 0 ? true : false;
        this.userlist.push(user);
      });
      if(this.page >= pagination){
        this.showMoreBtn = false;
      }
      this.loader = false;
      //this.page++;
    })
    .catch(error =>{
      console.log(error);
    })
  }

  addToFavt(user, index):void {
    this.btnindex = index;
    let param:URLSearchParams = new URLSearchParams();
    param.append('fid', user.id)
    this.api.PostWithToken( param,'add-as-favorite')
    .then(data => {
      if(data.error > 0){
        throw data.errmsg;
      }
      this.toastr.success('You have added ' + user.name + ' to your favorite list. ', 'Success');
      this.btnindex = -1;
      this.userlist.splice(index,1);
      //this.chat.sendNewMsgNotification({id: user.id, msg: data.response, slug: this.userinfo.uslug, action: 'add-as-favorite'});
    })
    .catch(error =>{
      this.btnindex = -1;
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

  removeNow(user, index):void {
    this.userlist.splice(index,1);
  }

  addToIgnore(user, index):void {
    this.btnindex = index;
    let param:URLSearchParams = new URLSearchParams();
    param.append('gid', user.id)
    this.api.PostWithToken( param,'add-to-ignore')
    .then(data => {
      if(data.error > 0)
        throw data.errmsg;
      
      this.toastr.success(user.name + ' is now in your ignore list. ', 'Success');
      this.btnindex = -1;
      this.userlist.splice(index,1);
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

  
  getNewUser():void {
    this.chat.getNewUserNotification()
     .subscribe( value => {
        let data = JSON.parse(value);
        console.log(data);
        data.isOnline = true;
        if(this.userinfo.user.seeking == data.gender && !this.isNewExist(data))
          this.chat.newuser.push(data);
        // else if(this.isNewExist(data)) {

        // }
        setTimeout(() => {
          this.sendLoginUserInfo(data);
        }, 1000);
        
     });
  }

  isNewExist(data):boolean {
    let status:boolean = false;
    this.chat.newuser.forEach(user => {
      if(user.id == data.id){
        status = true;
      }
    });
    return status;
  }
  sendLoginUserInfo(data):void {
    this.chat.sendPrivateMesssage(data.id,'loginuser', this.userinfo.getUserGeneralInfo())
    
  }

  getLoginUserInfo():void {
    this.chat.getPrivateMessage('loginuser')
    .subscribe( value => {
      let data = JSON.parse(value);
      data.isOnline = true;
      // console.log(this.isNewExist(data));
      // console.log(this.userinfo.user.seeking +"/"+ data.gender);

      if(this.userinfo.user.seeking == data.gender && !this.isNewExist(data))
        this.chat.newuser.push(data);
    });
  }

  onDisconnect():void { 
    this.chat.onDisconnect()
    .subscribe( value => {
      let index:number = 0;
      this.chat.newuser.forEach(usr => {
        if(usr.id == value.id){
          this.chat.newuser.splice(index, 1);
        }
        index++;
      });
    });
  }

  setSearchString():void {
    this.flash.set( 'homesearch', JSON.stringify(this.search), (0.5/1440));
    this.router.navigate(['/matches']);
  }

  loadCountry():void {
    this.api.PostWithOutToken( new URLSearchParams(),'country-list')
    .then(data => {
      this.country = data
    })
    .catch(error =>{
      console.log(error);
    })
  }

  loadOnlineUsers():void {
    //this.onlinePage ++;
    let parm = new URLSearchParams();
    this.newuser = [];
    parm.append('page', this.onlinePage.toString());
    this.api.PostWithToken( parm,'get-online-users')
    .then(data => {
      this.totalPages = data.pagination.length;
      if(this.onlinePage + 1 >=  data.pagination.length){
        this.showMoreBtnOnline = false;
      }
      this.chat.newuser = [];
      data.response.forEach(usr => {
        if(!this.isNewExist(usr)) {
          usr.img = usr.gender == 1 ? this.utility.maleimg : this.utility.femaleimg;
          usr.img = usr.user_image.length > 0 ? usr.user_image[0].image_url : usr.img;
          usr.country = usr.country_detail.cname;
          this.chat.newuser.push(usr);
        }
      });
    })
    .catch(error =>{
      console.log(error);
    })
  }

  removeToFavt(user, index): void {
    this.btnindex = index;
    let param: URLSearchParams = new URLSearchParams();
    param.append('fid', user.id)
    this.api.PostWithToken(param, 'remove-from-favorite')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }
        this.toastr.success('You have remove ' + user.name + ' from your mutual list. ', 'Success');
        this.btnindex = -1;
        //this.matcheslist.splice(index,1);
        user.ismutal = false;
        user.isfavorite = false;
      })
      .catch(error => {
        this.btnindex = -1;
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })
  }

  openChatWindwo(user): void {
    this.flash.set('huser', user.id, (0.5 / 1440));
    this.router.navigate(['/messages']);
  }
}
