import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { URLSearchParams } from '@angular/http';

import { ApicallService } from '../app-service/apicall.service';

@Injectable()
export class NotificationService {

  public notify = {
    activity:'0',
    message:'0',
  }
  activity:BehaviorSubject<any>;
  listen:any;
  constructor(private api:ApicallService) { 
    this.activity = new BehaviorSubject<any>(this.notify);
    this.listen = this.activity.asObservable();
    this.getNewActivity();
    this.getNewMessage();
  }

  getNewActivity():void {
    let parm = new URLSearchParams();
    this.api.PostWithToken( parm,'get-unread-notification')
    .then(data => {
      if(data.error > 0){
        throw data.errmsg;
      }

      this.notify.activity = data.response.length;
    })
    .catch(error =>{
      console.log(error);
    })
  }

  getNewMessage():void {
    let parm = new URLSearchParams();
    this.api.PostWithToken( parm,'get-new-message-notification')
    .then(data => {
      if(data.error > 0){
        throw data.errmsg;
      }
      this.notify.message = data.response;
    })
    .catch(error =>{
      console.log(error);
    })
  }
}
