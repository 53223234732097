

import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';

import { URLSearchParams } from '@angular/http';
import { CookieService } from 'ngx-cookie-service';
import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { UtilityService } from '../../utility/utility.service'
import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { ChatService } from '../../chat-service/chat.service';

declare var $: any;

@Component({
  selector: 'app-matches',
  templateUrl: './matches.component.html',
  styleUrls: ['./matches.component.css']
})
export class MatchesComponent implements OnInit {

  activePage:number = 1;
  userOnlineList: any = [];
  matcheslist: any = [];
  country: any = [];
  notfound: boolean = false;
  btnindex: number = -1;
  username: string = '';
  page: number = 1;
  showMoreBtn: boolean = true;
  loader: boolean = false;
  search: any = {
    agefrom: '18',
    ageto: '60',
    search: '',
    country: '0',
    isimage: false,
    isOnline: false
  };
  itemsPerPage = 16;
  totalPages : any; 

  constructor(public utility: UtilityService, public api: ApicallService, public pdata: OptionDataService, public user: UserinfoService, private flash: CookieService, public toastr: ToastsManager, vcr: ViewContainerRef, public chat: ChatService, private router: Router) {
    //this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.user.listen.subscribe((data) => { if (data) this.initAfterService() });
    this.chat.listen.subscribe((data) => { if (data) this.initChatActivities() });
    this.loadCountry();
  }

  initChatActivities(): void {
    this.chat.sendNewUserNotification();
    this.getLoginUserInfo();
    // this.getNewUser();
    this.onDisconnect();
  }

  initAfterService(): void {
    if (this.flash.get('homesearch') != '') {
      this.search = JSON.parse(this.flash.get('homesearch'));
      this.applySearch(true);
      this.flash.delete('homesearch');
    } else
      this.getMateches(new URLSearchParams());
  }

  displayActivePage(activePageNumber:number){  
    this.activePage = activePageNumber  
    this.page = activePageNumber  
    this.applySearch(false);
  } 

  loadCountry():void {
    this.api.PostWithOutToken( new URLSearchParams(),'country-list')
    .then(data => {
      this.country = data
    })
    .catch(error =>{
      console.log(error);
    })
  }

  getNewUser(): void {
    this.chat.getNewUserNotification()
      .subscribe(value => {
        let data = JSON.parse(value);
        let r = this.userOnlineList.filter(item => item.id === data.id);
        if(r.length == 0) 
          this.userOnlineList.push(data);
          
        this.matcheslist.forEach(item => {
          if (data.id == item.id) {
            item.isOnline = true;
          }
        });
        setTimeout(() => {
          this.sendLoginUserInfo(data);
        }, 1000);
      });
  }

  sendLoginUserInfo(data): void {
    this.chat.sendPrivateMesssage(data.id, 'loginuser', this.user.getUserGeneralInfo());
  }

  getLoginUserInfo(): void {
    this.chat.getPrivateMessage('loginuser')
      .subscribe(value => {
        let data = JSON.parse(value);
        let r = this.userOnlineList.filter(item => item.id === data.id);
        if(r.length == 0) 
          this.userOnlineList.push(data);
        this.matcheslist.forEach(item => {
          if (data.id == item.id) {
            item.isOnline = true;
          }
        });
      });
  }


  getMateches(param: any): void {
    this.loader = true;
    let pagination: number = 0;
    let userobj = function () { };
    this.api.PostWithToken(param, 'match-users')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }
        
        //this.getNewUser();
        console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk',data);
        this.notfound = false;
        pagination = data.pagination.length;
        this.totalPages = pagination;
        this.matcheslist = [];
        data.response.forEach(item => {
          let user = new userobj();
          user.id = item.id;
          user.name = item.name;
          user.city = item.city;
          user.img = item.gender == 1 ? this.utility.maleimg : this.utility.femaleimg;
          user.img = item.user_image.length > 0 ? item.user_image[0].image_url : user.img;
          user.country = item.country_detail != null ? item.country_detail.cname : "";
          user.age = this.utility.dateDiff(item.dob);
          user.uslug = item.slug;
          user.isOnline = item.is_online == 1 ? true : false;
          user.ismutal = item.match_mutual_favorite.length > 0 ? true : false;
          user.isfavorite = item.match_user_favorite.length > 0 ? true : false;
          let arr = this.userOnlineList.filter(item => item.id === user.id);
          if(arr.length > 0)
            user.isOnline = true;

          this.matcheslist.push(user);
        });
        console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk',this.matcheslist.length)
        this.notfound = this.matcheslist.length ? false : true;
        this.loader = false;
        if (this.page >= pagination) {
          this.showMoreBtn = false;
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  applySearch(isnewsearch: boolean): void {
    let parm = new URLSearchParams();
    //this.page++;
    if (isnewsearch) {
      this.matcheslist = [];
      this.showMoreBtn = true;
      this.notfound = false;
      this.page = 1;
    }

    parm.append('page', this.page.toString());
    if (this.search.agefrom != '0' && this.search.ageto != '0') {
      parm.append('agefrom', this.search.agefrom);
      parm.append('ageto', this.search.ageto);
    }
    if (this.search.search != '') {
      parm.append('name', this.search.search);
    }
    if (this.search.isimage) {
      parm.append('isimg', this.search.isimage);
    }
    if (this.search.country != '0') {
      parm.append('country', this.search.country);
    }
    if (this.search.isOnline) {
      parm.append('isOnline', this.search.isOnline);
    }

    this.getMateches(parm);
  }

  resetSearch(): void {
    this.page = 1;
    this.search.agefrom = '0';
    this.search.ageto = '0';
    this.search.search = '';
    this.search.isimage = false;
    this.matcheslist = [];
    this.notfound = false;
    this.showMoreBtn = true;
    this.getMateches({});
  }

  addToFavt(user, index): void {
    this.btnindex = index;
    let param: URLSearchParams = new URLSearchParams();
    param.append('fid', user.id);
    this.api.PostWithToken(param, 'add-as-favorite')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }

        this.toastr.success('You have sucessfully added ' + user.name + ' to your favorite list. ', 'Success');
        this.btnindex = -1;
        //this.matcheslist.splice(index,1);
        user.isfavorite = true;
        this.chat.sendNewMsgNotification({id: user.id, msg: data.response, slug: this.user.uslug, action: 'add-as-favorite'});
      })
      .catch(error => {
        this.btnindex = -1;
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })
  }

  addToIgnore(user, index): void {
    this.btnindex = index;
    let param: URLSearchParams = new URLSearchParams();
    param.append('gid', user.id)
    this.api.PostWithToken(param, 'add-to-ignore')
      .then(data => {
        if (data.error > 0)
          throw data.errmsg;

        this.toastr.success(user.name + ' is now in your ignore list. ', 'Success');
        this.btnindex = -1;
        this.matcheslist.splice(index, 1);
        if (this.matcheslist.length == 0)
          this.notfound = true;
      })
      .catch(error => {
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })
  }

  removeToFavt(user, index): void {
    this.btnindex = index;
    let param: URLSearchParams = new URLSearchParams();
    param.append('fid', user.id)
    this.api.PostWithToken(param, 'remove-from-favorite')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }
        this.toastr.success('You have remove ' + user.name + ' from your mutual list. ', 'Success');
        this.btnindex = -1;
        //this.matcheslist.splice(index,1);
        user.ismutal = false;
        user.isfavorite = false;
        if (this.matcheslist.length == 0)
          this.notfound = true;
      })
      .catch(error => {
        this.btnindex = -1;
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })
  }

  openChatWindwo(user): void {
    this.flash.set('huser', user.id, (0.5 / 1440));
    this.router.navigate(['/messages']);
  }

  onDisconnect():void { 
    this.chat.onDisconnect()
    .subscribe( value => {
      let index:number = 0;
      this.matcheslist.forEach(usr => {
        if(usr.id == value.id){
          this.matcheslist[index].isOnline = false;
        }
        index++;
      });
    });
  }
}
