import { Component, OnInit, ViewContainerRef, AfterViewChecked  } from '@angular/core';
import { ToastsManager } from 'ng2-toastr/ng2-toastr';
import { URLSearchParams } from '@angular/http';
import { ActivatedRoute, Router} from '@angular/router';

import { UtilityService } from '../utility/utility.service'
import { ApicallService } from '../app-service/apicall.service';
import { UserinfoService } from '../userinfo/userinfo.service';
import { OptionDataService } from '../option-data/option-data.service';

declare let paypal: any;

@Component({
  selector: 'app-paynow',
  templateUrl: './paynow.component.html',
  styleUrls: ['./paynow.component.css']
})
export class PaynowComponent implements OnInit {

  cardNo:string = '';//'4242424242424242';
  expMonth:string = '';//'1';
  expYear:string = '';//'20';
  expcvv:string = '';//'123';
  term:boolean = false;
  agree:boolean = false;
  ntfMsg:any = null;
  plan:string = '';
  stripe:number = 1;
  showbutton:boolean = true;
  paypalConfig:any;
  paypalamt:number = 0 ;
  payuserinfo:any = {
    email:'',
    country:'',
    postalcode:''
  };
  plan_summary:any;
    
  addScript: boolean = false;
  paypalLoad: boolean = true;

 
  constructor(public utility:UtilityService, public api:ApicallService, public pdata:OptionDataService, public user:UserinfoService, public toastr: ToastsManager, vcr: ViewContainerRef, private router:Router, private route:ActivatedRoute) {
    // this.toastr.setRootViewContainerRef(vcr);
    this.ntfMsg = this.pdata.notification;
    let self = this;
    this.paypalConfig = {
      env: 'production',
      client: {
        sandbox: 'AelIgANgzVAhqlV_6p4ctR6IIDTVM9a9iITTys9uvHvPL6T6ay_SnFixKQVfSwLPoEhXkrMPwtb1vU0B',
        // production: 'AbcdiCGHbw5xjUZtSJRGfRL9diZB_XUbuiQWOWupWDhQ2VD2WtDVTsEsIH_gci6b0kRXS-ZNX1oDFKKA'
        production: 'AY4mLxUEIhyq1RHq4YqJ7feRPADjQoEhGbvmUnS8bqdQLGaEQDapCXc2c_jhLsEPyq38yaz3aksgNXzF'
      },
      commit: true,
      validate: function(actions) {
        actions.disable();
        self.addPaypalListner(function(){
          self.toggleAction(actions);
        })
      },
      onClick: function() {
        self.isTermAgreeChecked();
      },
      payment: (data, actions) => {
        return actions.payment.create({
          payment: {
            transactions: [
              { amount: { total: self.paypalamt, currency: 'USD' } }
            ]
          }
        });
      },
      onAuthorize: (data, actions) => {
        return actions.payment.execute().then((payment) => {
          self.paypalPayNow(payment);
        })
      }
    };
   }

   
   addPaypalListner(callback){
    document.querySelector('#term').addEventListener('change', callback);
    document.querySelector('#agree').addEventListener('change', callback);
   }

   toggleAction(actions){
    if(!this.term || !this.agree){
      actions.disable();
      return false;
    }
    actions.enable();
    return true;
   }

   isTermAgreeChecked():boolean {
    if(!this.term || !this.agree || this.payuserinfo.email.trim()=='' || this.payuserinfo.postalcode.trim()=='' || this.payuserinfo.country.trim()==''){
      this.toastr.error('Make sure that you have entered all required data.', this.ntfMsg.generalErrSub);
      return false;
    }
    return true;
   }
   ngAfterViewChecked(): void {
    if (!this.addScript) {
      this.addPaypalScript().then(() => {
        paypal.Button.render(this.paypalConfig, '#paypalbtn');
        this.paypalLoad = false;
      })
    }
  }
  
  addPaypalScript() {
    this.addScript = true;
    return new Promise((resolve, reject) => {
      let scripttagElement = document.createElement('script');    
      scripttagElement.src = 'https://www.paypalobjects.com/api/checkout.js';
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
      
    })
  }

  ngOnInit() {
    this.user.listen.subscribe((data)=> {if(data) this.initAfterService()});
    this.plan = this.route.snapshot.params['sid'];
    this.getPlanID();

  }

  initAfterService():void {
    this.payuserinfo.email = this.user.uemail;
    this.payuserinfo.country = this.user.ucountry_obj.cname;
    this.payuserinfo.postalcode = this.user.zipcode;
  }

  paynow(isvalid:boolean):boolean{
    if(!isvalid || !this.term || !this.agree){
      this.toastr.error('Make sure that you have entered all required data.', this.ntfMsg.generalErrSub);
      return false;
    }

    this.showbutton = false;
    let param:URLSearchParams = new URLSearchParams();
    param.append('card_no', this.cardNo);
    param.append('exp_month', this.expMonth);
    param.append('exp_year', this.expYear);
    param.append('cvc', this.expcvv);
    param.append('plan', this.plan);
    
    this.api.PostWithToken( param,'pay-subscription')
    .then(data => {
      console.log(data);
      if(data.error > 0)
        throw data.errmsg;

      this.user.hasPayAuth = true;
      this.showbutton = true;
      this.toastr.success('Payment process has been completed successfully..', this.ntfMsg.generalSuccessSub);
      this.router.navigate(['/payment-history']);
    })
    .catch(error =>{
      this.showbutton = true;
      this.toastr.error(error, this.ntfMsg.generalErrSub);
    });

    return true;
  }

  paypalPayNow(payment):void {
    console.log(payment);
    let param:URLSearchParams = new URLSearchParams();
    param.append('sub_id', payment.id)
    param.append('cus_id', payment.payer.payer_info.payer_id);
    param.append('email', payment.payer.payer_info.email);
    param.append('plan', this.plan);
    
    this.api.PostWithToken( param,'payment-paypal-paynow')
    .then(data => {
      console.log(data);
      if(data.error > 0)
        throw data.errmsg;

      this.user.hasPayAuth = true;
      this.toastr.success('Payment process has been completed successfully..', this.ntfMsg.generalSuccessSub);
      this.router.navigate(['/payment-history']);
    })
    .catch(error =>{
      this.toastr.error(error, this.ntfMsg.generalErrSub);
    });

  }

  getPlanID():void {
    let param:URLSearchParams = new URLSearchParams();
    param.append('pid', this.plan);
    this.api.PostWithToken( param,'payment-plan-amount')
    .then(data => {
      console.log(data);
      if(data.error > 0)
        throw data.errmsg;

      this.paypalamt = data.response;
      this.plan_summary = data.plan; 
    })
    .catch(error =>{
      console.log(error);
      
    });
  }

}
