import { Component, OnInit } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { Router } from '@angular/router'
import { CookieService } from 'ngx-cookie-service';
import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { UtilityService } from '../utility/utility.service'
import { ApicallService } from '../app-service/apicall.service';
import { UserinfoService } from '../userinfo/userinfo.service';
import { OptionDataService } from '../option-data/option-data.service';
import { NotificationService } from '../notification/notification.service';
import { ChatService } from '../chat-service/chat.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {

  notifylist: any = [];
  pagination: any = [];
  page: number = 1;
  btnindex: number = -1;
  loader: boolean = false;
  showMoreBtn: boolean = true;
  type: string = '';
  pagetitle: string = '';
  itemsPerPage = 16;
  totalPages : any; 
  activePage:number = 1;
  constructor(public utility: UtilityService, public api: ApicallService, public pdata: OptionDataService, public user: UserinfoService, private router: Router, public observe: NotificationService, private flash: CookieService,public toastr: ToastsManager, public chat: ChatService) {
    this.setType(this.router.url);
    console.log(this.router.url);
  }

  ngOnInit() {
    this.user.listen.subscribe((data) => { if (data) this.initAfterService() });
    this.markAsRead();
  }

  initAfterService(): void {
    this.loadNotification();

  }
  displayActivePage(activePageNumber:number){  
    this.activePage = activePageNumber  
    this.page  = activePageNumber  
    this.loadNotification();
  } 

  markAsRead(): void {
    let param: URLSearchParams = new URLSearchParams();
    this.api.PostWithToken(param, 'notification-mark-as-read')
      .then(data => {
        if (data.error) {
          throw data.errmsg;
        }
        this.observe.notify.activity = '0';
      })
      .catch(error => {
        console.log(error);
      })
  }

  loadNotification(): void {
    this.loader = true;
    let notifyobj = () => { };
    let param: URLSearchParams = new URLSearchParams();

    param.append('page', this.page.toString());
    if (this.type != '') {
      param.append('type', this.type);
    }
    this.api.PostWithToken(param, 'get-notification')
      .then(data => {
        if (data.error) {
          throw data.errmsg;
        }

        this.utility.serverTime = data.servertime;
        this.pagination = data.response.pagination;
        this.totalPages = data.response.pagination.length;
        this.notifylist = [];
        data.response.notification.forEach(item => {
          let notify = new notifyobj()
          notify.msg = item.description;
          notify.notification_from_user = item.notification_from_user;
          notify.country_detail = item.notification_from_user.country_detail.cname;
          notify.isfavorite = item.notification_from_user.match_user_favorite.length > 0 ? true : false;
          if(item.notification_from_user != null) {
            notify.img = item.notification_from_user.gender == 1 ? this.utility.maleimg : this.utility.femaleimg;
            notify.img = item.notification_from_user.user_image.length > 0 ? item.notification_from_user.user_image[0].image_url : notify.img;
          }
          notify.isnew = item.isnew;
          if (item.slug != '') {
            notify.slug = '/profile/' + item.slug;
          }
          if (item.type == 'reply-question-request' || item.type == 'send-question-request') {
            notify.slug = '/event-question-reply/' + item.slug;
          }
          notify.date = item.created_at;
          this.notifylist.push(notify);
        });
        this.loader = false;
        if (this.pagination.length > this.page + 1) {
          //this.page++;
        } else {
          this.showMoreBtn = false
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  public setType(type: string): void {
    switch (type) {
      case '/visitors':
        this.type = 'visitor';
        this.pagetitle = 'All Visitors';
        break;
      case '/profile-update':
        this.type = 'user-update'
        this.pagetitle = 'All Profile Update';
        break;
      case '/photo-update':
        this.type = 'image-update';
        this.pagetitle = 'All Image Update';
        break;
      default:
        this.type = '';
        this.pagetitle = 'All Update';
    }
  }

  addToFavt(user, index): void {
    this.btnindex = index;
    let param: URLSearchParams = new URLSearchParams();
    param.append('fid', user.id);
    this.api.PostWithToken(param, 'add-as-favorite')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }

        this.toastr.success('You have sucessfully added ' + user.name + ' to your favorite list. ', 'Success');
        this.btnindex = -1;
        //this.matcheslist.splice(index,1);
        user.isfavorite = true;
        this.chat.sendNewMsgNotification({id: user.id, msg: data.response, slug: this.user.uslug, action: 'add-as-favorite'});
      })
      .catch(error => {
        this.btnindex = -1;
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })
  }

  addToIgnore(user, index): void {
    this.btnindex = index;
    let param: URLSearchParams = new URLSearchParams();
    param.append('gid', user.id)
    this.api.PostWithToken(param, 'add-to-ignore')
      .then(data => {
        if (data.error > 0)
          throw data.errmsg;

        this.toastr.success(user.name + ' is now in your ignore list. ', 'Success');
        this.btnindex = -1;
        this.notifylist.splice(index, 1);
      })
      .catch(error => {
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })
  }

  removeToFavt(user, index): void {
    this.btnindex = index;
    let param: URLSearchParams = new URLSearchParams();
    param.append('fid', user.id)
    this.api.PostWithToken(param, 'remove-from-favorite')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }
        this.toastr.success('You have remove ' + user.name + ' from your mutual list. ', 'Success');
        this.btnindex = -1;
        //this.matcheslist.splice(index,1);
        user.ismutal = false;
        user.isfavorite = false;
      })
      .catch(error => {
        this.btnindex = -1;
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })
  }

  openChatWindwo(user): void {
    this.flash.set('huser', user.id, (0.5 / 1440));
    this.router.navigate(['/messages']);
  }

}
