import { Component, OnInit } from '@angular/core';
import { URLSearchParams } from '@angular/http';

import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { UtilityService } from '../../utility/utility.service';
import { ChatService } from '../../chat-service/chat.service';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-near-posts',
  templateUrl: './near-posts.component.html',
  styleUrls: ['./near-posts.component.css']
})
export class NearPostsComponent implements OnInit {

  events: any = [];
  message_list: any = [];
  notfound: boolean = false;
  page: number = 0;
  loader: boolean = false;
  showMoreBtn: boolean = true;
  pagination: any = [];
  activePage:number = 0; 
  itemsPerPage = 16;
  totalPages : any; 
  
  constructor(public chat: ChatService, public api: ApicallService, public user: UserinfoService, public pdata: OptionDataService, private u: UtilityService, private router: Router) { }

  ngOnInit() {
    this.user.listen.subscribe((data) => { if (data) this.initAfterService() });
  }

  initAfterService() {
    this.loadPost({});
  }

  displayActivePage(activePageNumber:number){  
    this.activePage = activePageNumber  
    this.page  = activePageNumber  
    this.loadPost({});
  } 

  loadPost(search: any): void {
    this.loader = true;
    this.notfound = false;
    let param: URLSearchParams = new URLSearchParams();
    let eventobj = function () { };

    if (search.title) {
      param.append('title', search.title);
    }

    if (search.date) {
      param.append('date', this.u.toMySqlDateFormat(search.date));
    }

    param.append('page', this.page.toString());
    this.api.PostWithToken(param, 'post-list')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }
        this.pagination = data.pagination;
        this.totalPages = data.pagination.length;
        data.response.forEach(el => {
          console.log(el);
          
          let tmp = new eventobj()
          tmp.post_title = el.title;
          tmp.description = el.post_details.substring(0, 100);
          tmp.img = (el.thumbnail === null || el.thumbnail === "") ? 'assets/default-video.jpg' : el.thumbnail;
          tmp.posted_on = el.posted_date;
          tmp.posted_by = el.user.name;
          tmp.user_slug = el.user.slug;
          tmp.slug = el.slug;
          tmp.like = el.like.length;
          tmp.login_user_like = el.user_has_like.length;
          tmp.user_id = el.user_id;
          tmp.event_id = el.id;
          tmp.question = el.post_question_count.length;
          tmp.view_count = el.view_count === null ? 0 : el.view_count;
          this.events.push(tmp);
        });
        this.loader = false;

        if (!this.events.length) {
          this.notfound = true;

        }

        if (this.pagination.length > this.page + 1) {
          //this.page++;
        } else {
          this.showMoreBtn = false
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  like_dislike(event): void {
    let param: URLSearchParams = new URLSearchParams();
    param.append('uid', event.user_id);
    param.append('eid', event.event_id);

    this.api.PostWithToken(param, 'post-like-dislike')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }
        console.log(data.response);
        if (event.login_user_like) {
          event.login_user_like = 0;
          event.like--;
        } else {
          event.login_user_like = 1;
          event.like++;
          this.chat.sendNewMsgNotification({id: event.event_id, slug: this.user.uslug, msg: data.response, action: 'post-like'});
        }
 
      })
      .catch(error => {
        console.log(error);
      })
  }

  search(qur): void {
    this.events = [];
    console.log(this.events);
    
    this.showMoreBtn = true;
    this.loadPost(qur);
  }

  userComments(): void {
    $('#comments').modal('show');
  }

  getAllReplay(slug): void {
    this.message_list = []; 
    let msgobj = function () { };
    let parm: URLSearchParams = new URLSearchParams();
    parm.append('slug', slug);
    this.api.PostWithToken(parm, 'post-question-list')
      .then(data => {
        if (data.error) {
          throw data.error;
        }
        this.u.serverTime = data.servertime;
        data.response.forEach(el => {
          let msg = new msgobj();
          msg.id = el.id;
          msg.posted_author_id = el.post_user_id;
          msg.event_id = el.post_id;
          msg.user_name = el.reply_user.name;
          msg.user_id =  el.reply_user.id;
          msg.user_slug = el.reply_user.slug;
          msg.created_at = el.created_at;
          msg.message = el.message;
          msg.reply_list = el.reply_list;
          msg.reply_input = '';
          msg.user_img = el.reply_user.gender == 1 ? this.u.maleimg : this.u.femaleimg;
          msg.user_img = el.reply_user.user_profile_image.length > 0 ? el.reply_user.user_profile_image[0].image_url : msg.user_img;
          this.message_list.push(msg);
        });
        if (!this.message_list) {
          this.notfound = true;
        }
        console.log(data.response);
        
      })
      .catch(error => {
      })
      $('#comments').modal('show');
  }
  
  showUserProfile(slug): void {
    $('#comments').modal('hide');
    this.router.navigate(['/profile', slug]);
  }

}
