import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { Router } from '@angular/router';

import { UtilityService } from '../../utility/utility.service'
import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { LoginService } from '../../login/login.service';
import { ChatService } from '../../chat-service/chat.service';

import { ToastsManager } from 'ng2-toastr/ng2-toastr';

declare var $: any;

@Component({
  selector: 'myaccount-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  phone: string = '';
  email: string = '';
  oldpwd: string = '';
  newpwd: string = '';
  conpwd: string = '';
  reason: string = '1';
  convalid: boolean = false;
  ntfMsg: any = null;
  loader: boolean = false;
  errobj: any = {
    iserr: false,
    errmsg: '',
    errclass: ''
  };

  constructor(public chat: ChatService, public utility: UtilityService, public api: ApicallService, public optdata: OptionDataService, public pdata: OptionDataService, public user: UserinfoService, public toastr: ToastsManager, vcr: ViewContainerRef, private router: Router, private login: LoginService) {
    // this.toastr.setRootViewContainerRef(vcr);
    this.ntfMsg = this.optdata.notification;
  }

  ngOnInit() {
    this.user.listen.subscribe((data) => { if (data) this.initAfterService() });
  }

  initAfterService(): void {
    console.log(this.user);
    this.email = this.user.uemail;
    this.phone = this.user.uphone;
  }

  changeAlert(field, event):void {
    let val:string = event.target.checked ? '1' : '0';
    let param:URLSearchParams = new URLSearchParams();
    param.append(field, val)
    this.api.PostWithToken( param,'update-email-notification')
    .then(data => {
      if(data.error)
        throw data.error;

      this.user.uemailalert[field] = val;
      this.toastr.success('Records has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

}
