import { Component, OnInit, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastsManager } from 'ng2-toastr/ng2-toastr';
import { URLSearchParams } from '@angular/http';

import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { UtilityService } from '../../utility/utility.service';

declare var $: any;

@Component({
  selector: 'app-new-post',
  templateUrl: './new-post.component.html',
  styleUrls: ['./new-post.component.css']
})
export class NewPostComponent implements OnInit {

  loader: boolean = false;
  post_title: string = '';
  post_city: string = '';
  post_country: number = 1;
  country: any = [];
  is_group: string = '0';
  group_member: string = '0';
  posted_date: any = '';
  post_desc: string = '';
  feature_video: any;
  thumbnail_img: any;

  post_id: string = '';
  edit_mode: boolean = false;
  slug: string = '';

  constructor(public toastr: ToastsManager, vcr: ViewContainerRef, private route: ActivatedRoute, private api: ApicallService, public user: UserinfoService, private redirect: Router, public pdata: OptionDataService, public utility: UtilityService) { }

  ngOnInit() {
    this.user.listen.subscribe((data) => { if (data) this.initAfterService() });
  }

  initAfterService(): void {
    if (this.route.snapshot.params['slug'] != 0) {
      this.setvalue(this.route.snapshot.params['slug'])
    }
  }

  setvalue(slug): void {
    let parm: URLSearchParams = new URLSearchParams();
    parm.append('slug', slug);
    console.log(parm.toString());
    this.api.PostWithToken(parm, 'get-post-details')
      .then(data => {
        if (data.error) {
          throw data.error;
        }
        this.post_id = data.response.id;
        this.post_title = data.response.title;
        this.post_desc = data.response.post_details;
        this.posted_date = this.utility.fromMySqlDateFormat(data.response.posted_date);
        this.edit_mode = true;
        this.slug = slug;
        console.log(data);
      })
      .catch(error => {
        this.toastr.error(error, 'Error');
      })
  }


  createPost(isvalid): boolean {
    if(this.slug.length == 0 && (this.feature_video === undefined || this.feature_video === null)) {
      return false;
    }
    if (!isvalid) {
      return false;
    }

    this.loader = true;
    let formData: FormData = new FormData();

    if (this.edit_mode) {
      formData.append('slug', this.slug);
    }else if(!this.feature_video){
        this.toastr.error("Video must be selected");
        return;
    }
    
    formData.append('title', this.post_title);
    formData.append('description', this.post_desc);
    formData.append('thumbnail_img', this.thumbnail_img);
    formData.append('feature_video', this.feature_video);
    formData.append('is_group', this.is_group);
    
    this.api.PostWithTokenImage(formData, 'create-post')
      .then(data => {
        if (data.error) {
          throw data.error;
        }
        this.loader = false;
        if (this.edit_mode) {
          this.toastr.success('Post has been updated successfully.', 'Success');
        }
        else {
          this.toastr.success('Post has been added successfully.', 'Success');
        }
        this.redirect.navigate(['/video-list']);
        //window.location.href = "/video-list"
        this.post_title = '';
        this.post_desc = '';
      })
      .catch(error => {
        this.toastr.error('Can not process. please try later.', 'Error');
      })
    return true;
  }

  previewImage(img: any) {
    this.thumbnail_img = img.target.files[0];
    console.log(img);
    console.log(this.thumbnail_img);
  }

  previewVideo(img: any) {
    this.feature_video = img.target.files[0];
    console.log(img);
    console.log(this.feature_video);
  }

}
