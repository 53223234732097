import { Component, OnInit } from '@angular/core';
import { URLSearchParams } from '@angular/http';

import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { UtilityService } from '../../utility/utility.service';
import { ChatService } from '../../chat-service/chat.service';


@Component({
  selector: 'app-near-events',
  templateUrl: './near-events.component.html',
  styleUrls: ['./near-events.component.css']
})
export class NearEventsComponent implements OnInit {

  events: any = [];
  notfound: boolean = false;
  page: number = 1;
  loader: boolean = false;
  showMoreBtn: boolean = true;
  pagination: any = [];
  activePage:number = 1; 
  itemsPerPage = 16;
  totalPages : any; 

  constructor(public chat: ChatService, public api: ApicallService, public user: UserinfoService, public pdata: OptionDataService, private u: UtilityService) { }

  ngOnInit() {
    this.user.listen.subscribe((data) => { if (data) this.initAfterService() });
  }

  initAfterService() {
    this.loadPost({});
  }

  displayActivePage(activePageNumber:number){  
    this.activePage = activePageNumber  
    this.page  = activePageNumber  
    this.loadPost({});
  }  

  loadPost(search: any): void {
    this.loader = true;
    this.notfound = false;
    let param: URLSearchParams = new URLSearchParams();
    let eventobj = function () { };
    if (search.location) {
      param.append('location', search.location);
    }
    if (search.date) {
      param.append('date', this.u.toMySqlDateFormat(search.date));
    }

    param.append('page', this.page.toString());
    this.api.PostWithToken(param, 'event-list')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }
        this.pagination = data.pagination;
        this.totalPages = data.pagination.length;
        this.events = [];
        data.response.forEach(el => {
          let tmp = new eventobj()
          tmp.post_title = el.title;
          tmp.description = el.post_details.substring(0, 100);
          tmp.img = (el.featureimage.length > 0) ? el.featureimage[0].image_url : 'assets/img1.png';
          tmp.posted_on = el.posted_date;
          tmp.posted_by = el.user === null ? '' : el.user.name;
          tmp.user_slug = el.user === null ? '' : el.user.slug;
          tmp.slug = el.slug;
          tmp.city = el.city;
          tmp.country = el.country.cname;
          tmp.like = el.like.length;
          tmp.login_user_like = el.user_has_like.length;
          tmp.user_id = el.user_id;
          tmp.event_id = el.id;
          this.events.push(tmp);
        });
        this.loader = false;

        if (!this.events.length) {
          this.notfound = true;

        }

        if (this.pagination.length > this.page) {
          //this.page++;
        } else {
          this.showMoreBtn = false
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  like_dislike(event): void {
    let param: URLSearchParams = new URLSearchParams();
    param.append('uid', event.user_id);
    param.append('eid', event.event_id);

    this.api.PostWithToken(param, 'event-like-dislike')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }
        console.log(data.response);
        if (event.login_user_like) {
          event.login_user_like = 0;
          event.like--;
        } else {
          event.login_user_like = 1;
          event.like++;
          this.chat.sendNewMsgNotification({id: event.event_id, slug: this.user.uslug, msg: data.response, action: 'event-like'});
        }

      })
      .catch(error => {
        console.log(error);
      })
  }

  search(qur): void {
    this.events = [];
    this.showMoreBtn = true;
    this.loadPost(qur);
  }
}
