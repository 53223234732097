import { Injectable } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Router } from '@angular/router';

import { UtilityService } from '../utility/utility.service';
import { ApicallService } from '../app-service/apicall.service';
import { LoginService } from '../login/login.service';

@Injectable()
export class UserinfoService {

  public editprofile: boolean;
  public finish: BehaviorSubject<boolean>;
  public listen: any;
  public user: any;
  public uplan_detail: any;
  public uid: string;
  public uname: string;
  public uis_online: string;
  public uweight: string;
  public availabilityofcar: string;
  public housingcondition: string;
  public uemail: string;
  public ugender: string;
  public useeking: string;
  public zipcode: string;
  public ucountry: string;
  public ucountry_obj: any;
  public uactive_plan_detail: any;
  public password: string;
  public uslug: string;
  public uemailalert: any;
  public uphone: string;
  public upet: any;
  public uschool: string;
  public upolitical: string;
  public hasPayAuth: boolean;
  public hasFreeSubscription: boolean;

  public ismutual: boolean;
  public seekingType: string;

  public ucity: string;
  public urelationstatus: string;
  public ueducation: string;
  public umarried: string;
  public uchildren: string;
  public udob: string;
  public ucareer: string;
  public uethnicity: string;
  public uspirituality: string;
  public udenomination: string;
  public uincome: string;
  public usmoke: string;
  public udrink: string;
  public ukids: string;
  public uheight: any;
  public ulanguage: any;
  public answared: string;
  public unansward: string;
  public uimlookingfor: string;
  public uinterests: string;
  public utheimportantthing: string;

  public ubody_type: string;
  public ueye_color: string;
  public upiercing: string;
  public ueducation_institution: string;
  public ufield_of_activity: string;
  public uyear_of_release: string;
  public upost: string;
  public uorientation: string;

  public passionate_about: string;
  public favorite_music: string;
  public favorite_books: string;
  public favorite_dishes: string;
  public favorite_activities: string;
  public favorite_places: string;
  public the_best_things_in_life: string;
  public what_qualities_do_you_value_in_people: string;
  public what_place_does_religion_occupy_in_your_life: string;

  public aboutme: string;
  public passion: string;
  public leisuretime: string;
  public noticeaboutme: string;
  public peoplenotice: string;
  public influential: string;
  public lookinginperson: string;
  public readenjoy: string;

  public livewithout: any;
  public thankful: any;
  public skill: any;
  public frnddesc: any;

  public listenmusic: any;
  public favband: any;
  public likeread: any;
  public favbook: any;
  public movieenjoy: any;
  public favmovie: any;
  public sportplaying: any;
  public sportwatching: any;
  public tvwatching: any;
  public favtv: any;
  public likeplaying: any;

  public prefsmoke: string;
  public prefsmokeimt: string;
  public prefdrink: string;
  public prefdringimt: string;
  public prefethnicity: string;
  public prefethnicityimt: string;
  public prefagefrom: string;
  public prefageto: string;
  public prefageimt: string;
  public prefpartnerkid: string
  public prefmorekids: string;
  public prefreligionimt: string;
  public prefincomeimt: string;
  public prefeducationimt: string;
  public prefdistance: string;
  public prefdistanceimt: string;

  public imgPreview: string;
  public imgPrviewID: string
  public imgGallary: any;
  public questionanswer: any;

  public newmatches: boolean;
  public autohide: boolean;
  public is_subscribe: boolean;
  public subscribe_option: string;
  public subscribe_date: string;
  public subscribe_diff: string;
  public subscribe_expired: boolean;
  public subscribe_expired_date: string;
  public net_expense: string;
  constructor(public utility: UtilityService, public api: ApicallService, private router: Router, private login: LoginService) {
    // this.reloadUserInfo();
  }

  reloadUserInfo(): void {
    this.defaultValue();
    this.loadLoginUserInfo()
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }


        // //console.log(data);
        // if(data.response.is_profile_setup == null || data.response.is_profile_setup == 0){
        //   this.router.navigate(['/profile-setup']);
        // }
        // else if(data.response.is_preference == null || data.response.is_preference == 0){
        //   this.router.navigate(['/preference']);
        // }else{
        //   this.user = data.response;
        //   //console.log(this.user);
        //   this.loadData(this.user);
        // }

        this.user = data.response;
        // console.log(this.user.payment);
        if (data.response) {
          this.loadData(this.user);
          
        }
      })
      .catch(error => {
        console.log(error);
        this.login.removeToken();
        this.login.logOut();
        this.router.navigate(['/home']);
      })
  }

  getUserGeneralInfo(): any {
    let nuser = new function () { };
    nuser.id = this.user.id;
    nuser.name = this.user.name;
    nuser.country = this.user.country_detail.cname;
    nuser.city = this.user.city;
    nuser.email = this.user.email;
    nuser.img = this.user.gender == 1 ? this.utility.maleimg : this.utility.femaleimg;
    nuser.gender = this.user.gender;
    nuser.slug = this.user.slug;
    this.user.auth_user_image.forEach(img => {
      if (img.image_for == 'profile' && img.image_status == 'active') {
        nuser.img = img.image_url;
      }
    });
    return nuser;
  }

  public loadLoginUserInfo(): any {
    let parm: URLSearchParams = new URLSearchParams();
    return this.api.PostWithToken(parm, 'loggin-users-info');
  }

  public defaultValue() {
    this.editprofile = true;
    this.finish = new BehaviorSubject<boolean>(false);
    this.listen = this.finish.asObservable();
    this.prefsmoke = '0';
    this.prefsmokeimt = '1';
    this.prefdrink = '0';
    this.prefdringimt = '1';
    this.prefethnicity = '1';
    this.prefethnicityimt = '1';
    this.prefagefrom = '30';
    this.prefageto = '35';
    this.prefageimt = '1';
    this.prefpartnerkid = '0'
    this.prefmorekids = '0';
    this.prefreligionimt = '1';
    this.prefincomeimt = '1';
    this.prefeducationimt = '1';
    this.prefdistance = '0';
    this.prefdistanceimt = '1';
    this.ulanguage = [];
    this.uplan_detail = [];
    this.uemailalert = null;
    this.uphone = '';
    this.answared = '0';
    this.unansward = '100';
    this.upolitical = '0';
    this.seekingType = 'his';
    this.net_expense = '';
    this.uid = '';
    this.uname = '';
    this.uis_online = '0';
    this.uemail = '';
    this.uweight = '';
    this.availabilityofcar = '';
    this.housingcondition = '';
    this.ugender = '1';
    this.useeking = '1';
    this.zipcode = '';
    this.ucountry = '1';
    this.ucountry_obj = null;
    this.uactive_plan_detail = null;
    this.password = '';
    this.uslug = '';
    this.uschool = '';
    this.uimlookingfor = '';
    this.uinterests = '';
    this.utheimportantthing = '';

    this.ubody_type = '';
    this.ueye_color = '';
    this.upiercing = '';
    this.ueducation_institution = '';
    this.ufield_of_activity = '';
    this.uyear_of_release = '';
    this.upost = '';
    this.uorientation = '';

    this.passionate_about = '';
    this.favorite_music = '';
    this.favorite_books = '';
    this.favorite_dishes = '';
    this.favorite_activities = '';
    this.favorite_places = '';
    this.the_best_things_in_life = '';
    this.what_qualities_do_you_value_in_people = '';
    this.what_place_does_religion_occupy_in_your_life = '';

    this.ucity = '';
    this.urelationstatus = '1';
    this.ueducation = '0';
    this.umarried = '0';
    this.uchildren = '0';
    this.udob = '';
    this.ucareer = '';
    this.uethnicity = '';
    this.uspirituality = '0';
    this.udenomination = '9';
    this.uincome = '2';
    this.usmoke = '';
    this.udrink = '';
    this.ukids = '';
    this.uheight = { 'ft': '0', 'inch': '0' };
    this.hasPayAuth = false;
    this.hasFreeSubscription = false;
    this.ismutual = false;

    this.aboutme = '';
    this.passion = '';
    this.leisuretime = '';
    this.noticeaboutme = '';
    this.peoplenotice = '';
    this.influential = '';
    this.lookinginperson = '';
    this.readenjoy = '';

    this.livewithout = { 'one': '', 'two': '', 'three': '', 'four': '', 'five': '' };
    this.thankful = { 'one': '', 'two': '', 'three': '' };
    this.skill = { 'one': '', 'two': '', 'three': '' };
    this.frnddesc = { 'one': '', 'two': '', 'three': '', 'four': '' };

    this.listenmusic = { 'one': '', 'two': '', 'three': '' };
    this.favband = { 'one': '', 'two': '', 'three': '' };
    this.likeread = { 'one': '', 'two': '', 'three': '' };
    this.favbook = { 'one': '', 'two': '', 'three': '' };
    this.movieenjoy = { 'one': '', 'two': '', 'three': '' };
    this.favmovie = { 'one': '', 'two': '', 'three': '' };
    this.sportplaying = [];
    this.sportwatching = { 'one': '', 'two': '', 'three': '' };
    this.tvwatching = { 'one': '', 'two': '', 'three': '' };
    this.favtv = { 'one': '', 'two': '', 'three': '' };
    this.likeplaying = { 'one': '', 'two': '', 'three': '' };

    this.imgPreview = '';
    this.imgPrviewID = '';
    this.imgGallary = [];

    this.autohide = false;
    this.newmatches = false;

    this.is_subscribe = false;
    this.subscribe_option = '';
    this.subscribe_date = '';
    this.subscribe_diff = '';
    this.subscribe_expired = false;
  }
  public loadData(data): void {
    this.uid = data.id
    this.uname = data.name;
    this.uis_online = data.is_online;
    this.uweight = data.weight;
    this.availabilityofcar = data.availabilityofcar;
    this.housingcondition = data.housingcondition;
    this.uemail = data.email;
    this.password = '';
    this.uslug = data.slug;
    this.ugender = data.gender;
    this.imgPreview = data.gender == 1 ? this.utility.maleimg : this.utility.femaleimg;
    this.seekingType = data.gender == 1 ? 'her' : 'his';
    this.useeking = data.seeking;
    this.zipcode = data.zip_code;
    this.ucountry = data.country;
    this.ucountry_obj = data.country_detail;
    this.uactive_plan_detail = data.active_plan_detail;
    this.ucity = data.city;
    this.urelationstatus = data.relationship;
    this.ueducation = data.education;
    this.umarried = data.married;
    this.uchildren = data.children;
    this.udob = data.dob;
    this.ucareer = data.career;
    this.uethnicity = data.ethnicity;
    this.uspirituality = data.spirituality;
    this.udenomination = data.denomination;
    this.uimlookingfor = data.imlookingfor;
    this.uinterests = data.interests;
    this.utheimportantthing = data.theimportantthing;

    this.ubody_type = data.ubody_type;
    this.ueye_color = data.ueye_color;
    this.upiercing = data.upiercing;
    this.ueducation_institution = data.ueducation_institution;
    this.ufield_of_activity = data.ufield_of_activity;
    this.uyear_of_release = data.uyear_of_release;
    this.upost = data.upost;
    this.uorientation = data.uorientation;

    this.passionate_about = data.passionate_about;
    this.favorite_music = data.favorite_music;
    this.favorite_books = data.favorite_books;
    this.favorite_dishes = data.favorite_dishes;
    this.favorite_activities = data.favorite_activities;
    this.favorite_places = data.favorite_places;
    this.the_best_things_in_life = data.the_best_things_in_life;
    this.what_qualities_do_you_value_in_people = data.what_qualities_do_you_value_in_people;
    this.what_place_does_religion_occupy_in_your_life = data.what_place_does_religion_occupy_in_your_life;

    this.uincome = data.income;
    this.usmoke = data.smoke;
    this.udrink = data.drink;
    this.ukids = data.new_family;
    let tall = data.tall.split('/');
    this.uheight.ft = tall[0];
    this.uheight.inch = tall[1];
    this.newmatches = (data.new_matches == '1') ? true : false;
    this.autohide = (data.auto_hide == '1') ? true : false;
    this.ulanguage = (data.language != null) ? data.language.split('/') : null;
    this.uemailalert = data.user_email_alert;
    this.uplan_detail = data.plan_detail;
    this.uphone = data.mobile;
    this.answared = data.answered;
    this.unansward = data.unanswered;
    this.uschool = data.school;
    this.upolitical = data.political;
    this.hasPayAuth = data.payment == 1 ? true : false;
    this.hasFreeSubscription = data.setting.free_subscription == 1 ? true : false;
    this.aboutme = data.details_about_me;
    this.passion = data.passionate;
    this.leisuretime = data.leisure_time;
    this.noticeaboutme = data.details_notice_about_me;
    this.peoplenotice = data.details_first_thing_notice_about_me;
    this.influential = data.details_most_influential_person;
    this.lookinginperson = data.details_looking_for_in_person;
    this.readenjoy = data.details_read_and_enjoyed;
    this.net_expense = data.net_expense;
    this.upet = data.pet.split('/');
    if (data.live_without != null) {
      let livewithout = data.live_without.split('/')
      this.livewithout.one = livewithout[0];
      this.livewithout.two = livewithout[1];
      this.livewithout.three = livewithout[2];
      this.livewithout.four = livewithout[3];
      this.livewithout.five = livewithout[4];
    }

    if (data.three_thankfuls != null) {
      let thkfll = data.three_thankfuls.split('/');
      this.thankful.one = thkfll[0];
      this.thankful.two = thkfll[1];
      this.thankful.three = thkfll[2];
    }


    if (data.life_skills != null) {
      let skl = data.life_skills.split('/');
      this.skill.one = skl[0];
      this.skill.two = skl[1];
      this.skill.three = skl[2];
    }

    if (data.friend_describe != null) {
      let frnd = data.friend_describe.split('/');
      this.frnddesc.one = frnd[0];
      this.frnddesc.two = frnd[1];
      this.frnddesc.three = frnd[2];
      this.frnddesc.four = frnd[3];
    }

    if (data.details_listening_to != null) {
      let lmc = data.details_listening_to.split('/');
      this.listenmusic.one = lmc[0];
      this.listenmusic.two = lmc[1];
      this.listenmusic.three = lmc[2];
    }

    if (data.details_listening_to_band != null) {
      let fband = data.details_listening_to_band.split('/');
      this.favband.one = fband[0];
      this.favband.two = fband[1];
      this.favband.three = fband[2];
    }

    if (data.details_reading_books != null) {
      let lread = data.details_reading_books.split('/');
      this.likeread.one = lread[0];
      this.likeread.two = lread[1];
      this.likeread.three = lread[2];
    }

    if (data.details_reading_books_read != null) {
      let fbook = data.details_reading_books_read.split('/');
      this.favbook.one = fbook[0];
      this.favbook.two = fbook[1];
      this.favbook.three = fbook[2];
    }

    if (data.details_movies != null) {
      let mvenj = data.details_movies.split('/');
      this.movieenjoy.one = mvenj[0];
      this.movieenjoy.two = mvenj[1];
      this.movieenjoy.three = mvenj[2];
    }

    if (data.details_movies_watched != null) {
      let mwatch = data.details_movies_watched.split('/');
      this.favmovie.one = mwatch[0];
      this.favmovie.two = mwatch[1];
      this.favmovie.three = mwatch[2];
    }

    if (data.details_i_like_playing != null) {
      this.sportplaying = data.details_i_like_playing.split('/');
    }

    if (data.details_i_like_playing_watch != null) {
      let swatching = data.details_i_like_playing_watch.split('/');
      this.sportwatching.one = swatching[0];
      this.sportwatching.two = swatching[1];
      this.sportwatching.three = swatching[2];
    }

    if (data.details_i_like_watching != null) {
      let wtv = data.details_i_like_watching.split('/');
      this.tvwatching.one = wtv[0];
      this.tvwatching.two = wtv[1];
      this.tvwatching.three = wtv[2];
    }

    if (data.details_i_like_watched != null) {
      let ftv = data.details_i_like_watched.split('/');
      this.favtv.one = ftv[0];
      this.favtv.two = ftv[1];
      this.favtv.three = ftv[2];
    }

    if (data.details_listening_to_like_to_play != null) {
      let lkpl = data.details_listening_to_like_to_play.split('/');
      this.likeplaying.one = lkpl[0];
      this.likeplaying.two = lkpl[1];
      this.likeplaying.three = lkpl[2];
    }



    this.prefsmoke = data.user_preference.smoke;
    this.prefsmokeimt = data.user_preference.smoke_important;
    this.prefdrink = data.user_preference.drink;
    this.prefdringimt = data.user_preference.drink_important;
    this.prefethnicity = data.user_preference.area;
    this.prefethnicityimt = data.user_preference.area_important != '' ? data.user_preference.area_important : 1;
    this.prefagefrom = data.user_preference.from_age;
    this.prefageto = data.user_preference.to_age;
    this.prefageimt = data.user_preference.important_age;
    this.prefpartnerkid = data.user_preference.partner_children;
    this.prefmorekids = data.user_preference.own_children;
    this.prefreligionimt = data.user_preference.religion;
    this.prefincomeimt = data.user_preference.income;
    this.prefeducationimt = data.user_preference.education;
    this.prefdistance = data.user_preference.distance;
    this.prefdistanceimt = data.user_preference.q_distance;

    this.questionanswer = data.questitonanswer;

    let imgOBJ = () => { };
    this.imgGallary = [];
    data.auth_user_image.forEach(element => {
      if (element.image_status == 'active') {
        this.imgPreview = element.image_url;
        this.imgPrviewID = element.image_id;
      } else {
        let img = new imgOBJ();
        img.id = element.image_id;
        img.url = element.image_url;
        this.imgGallary.push(img);
      }
    });

    this.is_subscribe = data.is_subscribe;
    this.subscribe_option = data.subscribe_option;
    this.subscribe_date = data.subscribe_date;
    this.subscribe_diff = data.subscribe_diff;
    this.subscribe_expired = data.subscribe_expired;
    this.subscribe_expired_date = data.subscribe_expired_date;
    
    this.finish.next(true);
  }
}
