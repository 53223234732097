import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { UtilityService } from '../../utility/utility.service'
import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { OptionDataService } from '../../option-data/option-data.service';

@Component({
  selector: 'myaccount-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {

  constructor(public utility:UtilityService, public api:ApicallService, public pdata:OptionDataService, public user:UserinfoService,public toastr: ToastsManager, public vcr: ViewContainerRef) {
    // this.toastr.setRootViewContainerRef(vcr);
   }

  ngOnInit() {
    this.user.listen.subscribe((data)=> {if(data) this.initAfterService()});
  }

  initAfterService():void {
    
  }

  changeAlert(field, event):void {
    let val:string = event.target.checked ? '1' : '0';
    let param:URLSearchParams = new URLSearchParams();
    param.append(field, val)
    this.api.PostWithToken( param,'update-email-notification')
    .then(data => {
      if(data.error)
        throw data.error;

      this.user.uemailalert[field] = val;
      this.toastr.success('Records has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }
}
