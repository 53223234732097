import { Component, OnInit,  ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { UserinfoService } from '../../userinfo/userinfo.service';
import { UtilityService } from '../../utility/utility.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { ApicallService } from '../../app-service/apicall.service';
import { ChatService } from '../../chat-service/chat.service';

@Component({
  selector: 'profile-photo-edit',
  templateUrl: './photo-edit.component.html',
  styleUrls: ['./photo-edit.component.css']
})
export class PhotoEditComponent implements OnInit {

  imglist:any = [];
  savetempimage:boolean = false;
  loader:boolean = false;
  constructor(public chat: ChatService, public user:UserinfoService, public utility:UtilityService, public optdata:OptionDataService, public api:ApicallService, public toastr: ToastsManager, vcr: ViewContainerRef) { }

  ngOnInit() {
  }

  deleteImg(id, index):void {
    let param:URLSearchParams = new URLSearchParams();
    param.append('mid', id);
    this.api.PostWithToken(param, 'delete-img')
    .then(data => {
      if(data.error > 0){
        throw data.error;
      }
      
      this.user.imgGallary.splice(index, 1);
      this.toastr.success('Phote has been deleted successfully.', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process. please try later.', 'Error');
    })
  }

  loadimgmulti(fileobj:any):void {
    let imgobj = ()=>{};
    let files = fileobj.target.files;
    let filesLength = files.length;

    for (let i = 0; i < filesLength; i++){
      let f = files[i];
      let fileReader = new FileReader();
      fileReader.readAsDataURL(f);
      fileReader.onload = (e:Event) => {
        let imgs = new imgobj();
        imgs.name = f.name;
        imgs.objfile = e.target;
        imgs.file = f;
        imgs.img = fileReader.result;
        this.imglist.push(imgs);
      };
    }
    this.savetempimage = true;
  }

  tmpImageDelete(index):void {
    this.imglist.splice(index, 1);

    if(this.imglist.length == 0)
      this.savetempimage = false;
  }

  uploadimage():any {
    this.loader = true;
    let formData:FormData = new FormData();
    let files:any = [];
    this.imglist.forEach(function(item){
      formData.append('imageFile[]', item.file);
    })
    if(this.imglist.length == 0)
      return true;

    this.api.uploadImages(formData, 'upload-gallery')
    .then(data => {
      if(data.error){
        throw data.error;
      }
        
      
      this.loader = false;
      this.imglist = [];
      this.savetempimage = false;
      this.reloadUserInfo();
      this.chat.sendNewMsgNotification({id: this.user.uid, msg: this.user.uname + " recently has updated " + (this.user.ugender ==  "1" ? "his" : "her") + " profile.", slug: this.user.uslug, action: 'update-profile'});

      this.toastr.success('Phote has been updated successfully.', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process. please try later.', 'Error');
    })
  }

  reloadUserInfo():void {
    this.user.loadLoginUserInfo()
    .then(data => {
      if(data.error > 0)
        throw data.errmsg;

      this.user.loadData(data.response);
    })
    .catch(error =>{
      this.toastr.error('Can not process. please try later.', 'Error');
    })
  }
}
