import { Component, OnInit, Input, SimpleChanges} from '@angular/core';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { UtilityService } from '../../utility/utility.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { Userclass } from '../../userinfo/userclass';

@Component({
  selector: 'profile-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.css']
})
export class DescriptionComponent implements OnInit {

  @Input() uclass: Userclass = null;
  constructor(public user:UserinfoService, public utility:UtilityService, public optdata:OptionDataService) { }

  ngOnInit() {
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.uclass.currentValue) 
       this.user = <any>this.uclass;
  }
}
