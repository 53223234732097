import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { ToastsManager } from 'ng2-toastr/ng2-toastr';
import { CookieService } from 'ngx-cookie-service';

import { UserinfoService } from '../../userinfo/userinfo.service';
import { UtilityService } from '../../utility/utility.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { Userclass } from '../../userinfo/userclass';
import { ApicallService } from '../../app-service/apicall.service';
import { ChatService } from '../../chat-service/chat.service';

declare var $:any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  userId:string = "";
  descriptionPanel:boolean = true;
  photoPanel:boolean = false;
  ansquePanel:boolean = false;
  uclass:Userclass = null;
  showview:boolean = false;
  nftMsg:any = null;
  umessage:string = '';
  haspayauth:boolean = false;
  ownprofile:boolean = true;
  loader:string = 'assets/img-loader.svg';
  profile_loader:boolean = false;
  showuploadImage:boolean = false;
  authUserSlug:string = "";
  subscribe_diff:string = "";
  subscribe_expired: boolean =  false;
  hasFreeSubscription: boolean = false;
  uemailalert: any = null;
  constructor(public chat: ChatService,public loggin_user:UserinfoService, public user:UserinfoService, public utility:UtilityService, public optdata:OptionDataService, private route:ActivatedRoute,private api:ApicallService, public toastr: ToastsManager, vcr: ViewContainerRef, private flash:CookieService, private router:Router) {
    // this.toastr.setRootViewContainerRef(vcr);
   }

  ngOnInit() {
    this.nftMsg =  this.optdata.notification;
    this.user.listen.subscribe((data)=> {if(data) {this.initAfterService(); this.authUserSlug = this.user.uslug; }});
  }

  initAfterService():void {
    this.uemailalert = this.user.uemailalert;
    this.hasFreeSubscription = this.user.hasFreeSubscription;
    this.haspayauth = this.user.hasPayAuth;
    this.subscribe_diff = this.user.subscribe_diff;
    this.subscribe_expired = this.user.subscribe_expired;//alert(this.route.snapshot.params['slug'])
    //if(this.route.snapshot.params['slug'] != this.user.uslug){
      this.loadUserInfo(this.route.snapshot.params['slug']);
      this.ownprofile = false;
    // }else{
    //   this.showview = true;
    // }
    this.showuploadImage = (this.ownprofile && !this.user.imgPrviewID);
  }
  loadUserInfo(slug):void {
    this.uclass = new Userclass();
    this.uclass.loadUserInfo(slug,this.api)
    .then(data => {
      
      if(data.error > 0){
        throw data.errmsg;
      }
      this.uclass.loadData(data.response,this.utility);
      this.user = <any>this.uclass;
      this.userId = data.response.id;
      this.showview = true;
      this.markedAsVisitor(slug);
    })
    .catch(error =>{
      console.log(error);
    })
  }
  toggle(desc:boolean, photo:boolean, ansq:boolean):void{
    this.descriptionPanel = desc;
    this.photoPanel = photo;
    this.ansquePanel = ansq;
  }
  markedAsVisitor(slug):void {
    let parm:URLSearchParams = new URLSearchParams();
    parm.append('slug',slug);
    this.api.PostWithToken(parm, 'add-profile-visitor')
    .then(data => {
      if(data.error > 0)
        throw data.sendNewActivityNotification();
      this.chat.sendNewMsgNotification({id: this.userId, msg: data.response, slug: this.authUserSlug, action: 'visitor'});
      this.sendVisitorEmail(slug);
    })
    .catch(error =>{
      console.log(error);
    })
  }

  sendMessage(msg:string): void {
    let slug:string = this.route.snapshot.params['slug'];
    let message:string = msg.replace(/\d{3}/g,"#");
    let parm:URLSearchParams = new URLSearchParams();
    parm.append('slug',slug);
    parm.append('cmessage', message);
    
    this.api.PostWithToken(parm, 'private-msg')
    .then(data => {
      if(data.error > 0)
        throw data.errmsg;

      this.chat.sendNewMsgNotification({action: 'chat-message', slug: slug, authUserSlug: this.authUserSlug, id: this.userId});
      
      this.toastr.success(this.nftMsg.chatSndMsg, this.nftMsg.generalSuccessSub);
      $("#message").modal('hide');
      this.sendEmail(slug);
      // if(!this.user.ismutual){
      //   $("#notification").modal('show');
      //   this.sendEmail(slug);
      // }
    })
    .catch(error =>{
      this.toastr.error(error, 'Warning');
      console.log(error);
    })
  }

  sendEmail(slug):any{
    let parm:URLSearchParams = new URLSearchParams();
    parm.append('slug',slug);
    return this.api.PostWithToken(parm, 'user-msg-notication-email');
  }

  sendVisitorEmail(slug):any{
    let parm:URLSearchParams = new URLSearchParams();
    parm.append('slug',slug);
    return this.api.PostWithToken(parm, 'visitor-notication-email');
  }
  redirectToPhotoEdit():void {
    if(this.photoPanel)
      this.flash.set( 'pedit', 'photo', (0.5/1440));
    
    if(this.descriptionPanel)
      this.flash.set( 'pedit', 'desc', (0.5/1440));
    
    if(this.ansquePanel)
      this.flash.set( 'pedit', 'question', (0.5/1440));

    this.router.navigate(['/profile-edit']);
  }

  loadimg(fileobj:any):void {
    this.loader ='assets/img-loader.svg';
    this.profile_loader = true;
    let file = fileobj.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (e:Event) => {
      let formData:FormData = new FormData();
      formData.append('imageFile', file);
      this.api.uploadImages(formData, 'upload-image')
      .then(data => {
        if(data.error)
          throw data.error;
        
        this.user.imgPreview = data.response.url;
        this.user.imgPrviewID  = data.response.imgid;
        this.profile_loader = false;
        this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response.msg, slug: this.user.uslug, action: 'update-profile'});
        setTimeout(function(){ window.location.reload(); }, 500);
      })
      .catch(error =>{
        console.log(error);
        this.profile_loader = false;
      })
    };
  }
  openModal():void{
    this.loggin_user.loadLoginUserInfo()
    .then(data => {
      if(data.error > 0)
        throw data.errmsg;
      
      this.haspayauth = data.response.payment == 1 ? true : false;
      this.subscribe_diff = data.response.subscribe_diff;
      this.subscribe_expired = data.response.subscribe_expired;
      if(this.haspayauth || this.hasFreeSubscription || !this.subscribe_expired)
        $("#message").modal('show');
      else 
        this.router.navigate(['/payment-options']);
    })
    .catch(error =>{
      this.toastr.error('Can not process. please try later.', 'Error');
    })
  }
}
