import { Injectable } from '@angular/core';
import { URLSearchParams, Headers } from '@angular/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { Observable } from 'rxjs/Observable'
import { Socket, SocketIoConfig } from 'ng-socket-io';
import 'rxjs/add/operator/map';

import { ApicallService } from '../app-service/apicall.service';
import { UserinfoService } from '../userinfo/userinfo.service';

@Injectable()
export class ChatService {

  public socket: Socket;
  newuser: any = [];
  isInit: BehaviorSubject<boolean>;
  listen: any;
  config: SocketIoConfig = { url: 'https://app.woovibe.com:9005', options: { query: { cid: '220' }, reconnect: true } };
  // config: SocketIoConfig = { url: 'http://localhost:9005', options: { query: { cid: '220' }, reconnect: true } };
  // config: SocketIoConfig = { url: 'https://dev.woovibe.com:9007', options: { query: { cid: '220' }, reconnect: true } };

  constructor(private api: ApicallService, private uinfo: UserinfoService) {
    this.isInit = new BehaviorSubject<boolean>(false);
    this.listen = this.isInit.asObservable();
    //this.initSocket();
  }

  initSocket() {
    this.socket = new Socket(this.config);
    this.socket
      .fromEvent<any>("init")
      .map(data => data)
      .subscribe(value => {
        this.getNewUserNotification();
        this.onDisconnect();
        this.authenticate(value.ack, value.dcl);
        this.isInit.next(true);
      });
  }

  authenticate(ack, dcl): void {
    let header: Headers = new Headers();
    let param: URLSearchParams = new URLSearchParams();
    header.append('ack', ack);
    header.append('dcl', dcl);
    param.append('nack', this.uinfo.user.id);
    this.api.postChatMessage(param, 'authentication', header)
      .then(data => {
        if (data.error > 0)
          throw data.error;

        localStorage.setItem('rcs', data.rcs);
        this.sendNewUserNotification();
      })
      .catch(error => {
        console.log(error);
      })
  }

  sendNewUserNotification(): void {
    let header: Headers = new Headers();
    let param: URLSearchParams = new URLSearchParams();
    // console.log(localStorage.getItem('rcs'));
    header.append('ack', localStorage.getItem('rcs'));
    param.append('event', 'newuser');
    param.append('data', JSON.stringify(this.uinfo.getUserGeneralInfo()));
    this.api.postChatMessage(param, 'broadcast-message', header)
      .then(data => {
        //console.log(data);
        //console.log(this.uinfo.user);
      })
      .catch(error => {
        console.log(error);
      })
  }

  sendNewMsgNotification(data): void {
    let header: Headers = new Headers();
    let param: URLSearchParams = new URLSearchParams();
    // console.log(localStorage.getItem('rcs'));
    header.append('ack', localStorage.getItem('rcs'));
    param.append('event', 'newmesage');
    if(data.action == 'chat-message')
      param.append('data', JSON.stringify({action: data.action, receiver_id: data.receiver_id,slug: data.slug, authUserSlug: data.authUserSlug, data: this.uinfo.getUserGeneralInfo()}));
    else
      param.append('data', JSON.stringify(data));
    
    this.api.postChatMessage(param, 'broadcast-message', header)
      .then(data => {
        //console.log(data);
        //console.log(this.uinfo.user);
      })
      .catch(error => {
        console.log(error);
      })
  }

  getNewMsgNotification(): any {
    return this.socket
      .fromEvent<any>("newmesage")
      .map(data => data)
  }

  getNewUserNotification(): any {
    return this.socket
      .fromEvent<any>("newuser")
      .map(data => data)
  }

  onDisconnect(): any {
    return this.socket
      .fromEvent<any>("disconnected")
      .map(data => data)
  }


  sendPrivateMesssage(ack: any, event: string, data): Promise<any> {
    let header: Headers = new Headers();
    let param: URLSearchParams = new URLSearchParams();
    header.append('ack', ack);
    param.append('event', event);
    param.append('data', JSON.stringify(data));
    return this.api.postChatMessage(param, 'private-message', header);
  }

  closeSocket(): void {
    this.socket.disconnect();
  }

  getPrivateMessage(event: string): any {
    return this.socket
      .fromEvent<any>(event)
      .map(data => data);
  }
}
