import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';

import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { UtilityService } from '../../utility/utility.service'
import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { ChatService } from '../../chat-service/chat.service';


declare var $:any;

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {

  language:any = [];
  constructor(public chat: ChatService, public utility:UtilityService, public api:ApicallService, public pdata:OptionDataService, public user:UserinfoService, public toastr: ToastsManager, public vcr: ViewContainerRef) { 
    // this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.user.listen.subscribe(data=> this.initAfterService());
  }

  initAfterService():void {
    if(this.user.ulanguage != null )
      this.language = this.user.ulanguage;
  }
  
  saveProfile():void {
    let param:URLSearchParams = new URLSearchParams();
    let lng:string = this.utility.arrjoin(this.language,'/');
    param.append('language', lng);

    this.api.PostWithToken( param,'update-profile')
    .then(data => {
      console.log(data);
      if(data.error > 0){
        throw data.errmsg;
      }
        
      this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});

      this.user.ulanguage = this.language.slice();
      $("#language").modal('hide');
      this.toastr.success('Language has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

  getLan(index, lang, status):void {
    console.log(status);
    if(status)
      this.language.push(lang);
    else
      this.language.splice(index,1);

    console.log(this.language);
  }

  isSelected(lang):boolean {
    if(this.user.ulanguage == null)
      return false;
    return this.utility.inArray(lang, this.user.ulanguage);
  }

}
