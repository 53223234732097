import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { Observable } from 'rxjs/Observable'
import { Socket, SocketIoConfig } from 'ng-socket-io';
import 'rxjs/add/operator/map';
import { CookieService } from 'ngx-cookie-service';


import { ApicallService } from '../app-service/apicall.service';
import { UtilityService } from '../utility/utility.service';
import { UserinfoService } from '../userinfo/userinfo.service';
import { ChatService } from '../chat-service/chat.service';
import { WindowRef } from '../WindowRef';
import { NotificationService } from '../notification/notification.service';


declare var $: any;
declare var EmojiPicker: any;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
  host: {
    '(document:keydown)': 'handleKeyboardEvents($event)'
  }
})

export class ChatComponent implements OnInit {

  message: string = '';
  chatuser: any = [];
  messagelist: any = [];
  loguser: any = '';
  chatBoxShow: string = 'block';
  chatinit: boolean = false;
  isAuthUser: boolean = true;
  ishuser: number = 0;
  huser: any = {
    id: 0,
    name: 'Woovibe',
    img: 'assets/sender-profile.png',
    city: 'None',
    country: 'None'
  }
  subscribe_expired: boolean = false;
  constructor(private api: ApicallService, private utility: UtilityService, public authusr: UserinfoService, public chat: ChatService, private winRef: WindowRef, public toastr: ToastsManager, vcr: ViewContainerRef, private flash: CookieService, private notify: NotificationService) {
    // this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.authusr.loadLoginUserInfo()
    .then(data => {
      if(data.error > 0)
        throw data.errmsg;
        this.authusr.loadData(data.response);
        this.subscribe_expired = this.authusr.subscribe_expired;
        this.initAfterService();
    })
    .catch(error =>{
      this.toastr.error('Can not process. please try later.', 'Error');
    })
    // this.authusr.listen.subscribe((data) => { if (data) this.initAfterService() });
    this.chat.listen.subscribe((data) => { if (data) this.initChatActivities() });
    if (this.flash.get('huser') != '') {
      this.ishuser = parseInt(this.flash.get('huser'));
    }
    this.getChatList();

  }
  initChatActivities(): void {
    console.log(this.authusr);
    this.subscribe_expired = this.authusr.subscribe_expired;
    this.chat.sendNewUserNotification();
    this.getNewUser();
    this.getLoginUserInfo();
    this.onDisconnect();
    this.chatinit = true;
  }
  ngAfterViewInit() {
    this.winRef.nativeWindow.emojiPicker = new EmojiPicker({
      emojiable_selector: '[data-emojiable=true]',
      assetsPath: 'assets/emoji/',
      popupButtonClasses: 'fa fa-smile-o'
    });
    this.winRef.nativeWindow.emojiPicker.discover();
  }

  handleKeyboardEvents(event: KeyboardEvent) {
    if (event.which == 13) {
      this.message = $('.emoji-wysiwyg-editor').text();
      if (this.message.trim() != '') {
        this.saveMessage(true);
      }
    }
  }

  initAfterService(): void {
    this.loguser = this.authusr.getUserGeneralInfo();
    this.isAuthUser = this.authusr.hasPayAuth;
    // if (this.chatinit == false) {
    //   this.chat.initSocket();
    //   this.chatinit = false;
    // }

    this.getNewMessage();
  }
  getChatList(): void {
    this.chatuser = [];
    let parm: URLSearchParams = new URLSearchParams();
    this.api.PostWithToken(parm, 'user-chat-list')
      .then(data => {
        if (data.error > 0)
          throw data.error;
        this.utility.serverTime = data.servertime;
        data.response.forEach(item => {
          let user = new function () { };
          if(item.user != null) {
            
            user.id = item.user.id;
            user.name = item.user.name;
            user.country = item.user.country_detail.cname;
            user.city = item.user.city;
            user.img = item.user.gender == 1 ? this.utility.maleimg : this.utility.femaleimg;
            user.img = item.user.user_image.length > 0 ? item.user.user_image[0].image_url : user.img;
            user.gender = item.user.gender;
            user.canMessage = item.status;
            user.lastseen = item.user.last_login;
            user.isOnline = false;
            user.slug = item.user.slug;
            user.newmsg = item.unread_msg.length > 0 ? item.unread_msg.length : 0;
            this.chatuser.push(user);
            if (this.huser.city == "None") {
              if (this.ishuser != 0) {
                if (user.id == this.ishuser)
                  this.setHuser(user);
                this.flash.delete('huser');
              } else {
                this.setHuser(user);
              }
            }
          }
        });
      })
      .catch(error => {
        console.log(error);
      })
  }

  setHuser(user: any): void {
    this.huser.id = user.id;
    this.huser.name = user.name;
    this.huser.city = user.city;
    this.huser.country = user.country;
    this.huser.img = user.img;
    this.huser.gender = user.gender;
    this.huser.slug = user.slug;
    this.chatBoxShow = user.canMessage == 0 ? 'block' : 'none';
    if (user.newmsg > 0) {
      this.markAsReadMessage(this.huser.id);
    }
    let parm: URLSearchParams = new URLSearchParams();
    parm.append('receiver_id', this.huser.id);
    this.api.PostWithToken(parm, 'match-users-message')
      .then(data => {
        if (data.error > 0)
          throw data.error;

        this.messagelist = [];
        data.response.forEach(item => {
          let msg = new function () { }
          msg.message = item.content;
          msg.class = item.receiver_user.id == this.huser.id ? 'you' : 'me';
          msg.img = item.receiver_user.id == this.huser.id ? this.loguser.img : this.huser.img;
          msg.time = item.created_at;
          msg.date = this.utility.isToday(msg.time) ? '' : msg.time;
          this.messagelist.push(msg);
        });
      })
      .catch(error => {
        console.log(error);
      })
  }

  saveMessage(isValid: boolean): any {

    if (!isValid || this.chatBoxShow == 'none')
      return false;
    
    let emojiData = $("div[data-id='" + $("#emoji").data('id') + "']").html();

    if(emojiData != null)
      this.message = emojiData;

    let msg = new function () { }
    msg.message = this.message;
    msg.class = 'message-right-text-reciver';
    msg.img = this.loguser.img;

    let parm: URLSearchParams = new URLSearchParams();
    parm.append('receiver_id', this.huser.id);
    parm.append('cmessage', this.message);
    this.api.PostWithToken(parm, 'private-msg')
      .then(data => {
        if (data.error > 0)
          throw data.error;

        //console.log(data);
        msg.time = data.servertime;
        this.messagelist.push(msg);
        this.sendPrivateMessage(msg, data.response);
        this.getChatList();
      })
      .catch(error => {
        console.log(error);
      })
    this.message = '';
    $(".chat-box-textarea").html('');
  }

  sendPrivateMessage(msg, refid): void {
    this.chat.sendPrivateMesssage(this.huser.id, 'privatemsg', msg)
      .then(res => {
        if (res.error == 1) {
          this.markAsUnread(refid);
        }
        this.chat.sendNewMsgNotification({action: 'chat-message', receiver_id: this.huser.id});
      })
      .catch(error => {
        console.log(error);
      });

  }

  markAsReadMessage(refid): void {
    let parm: URLSearchParams = new URLSearchParams();
    parm.append('receiver_id', refid);
    this.api.PostWithToken(parm, 'marked-as-read-message')
      .then(data => {
        if (data.error > 0) {
          throw data.error;
        }
        this.chatuser.forEach(el => {
          if(refid == el.id) {
            el.newmsg = '0';
          }
        }); 
        this.notify.getNewMessage();
      })
      .catch(error => {
        console.log(error);
      })
  }

  markAsUnread(refid): void {
    let parm: URLSearchParams = new URLSearchParams();
    parm.append('refid', refid);
    this.api.PostWithToken(parm, 'mark-as-unread-message')
      .then(data => {
        if (data.error > 0) {
          throw data.error;
        }
        console.log(data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  getNewMessage(): void {
    this.chat.getPrivateMessage('privatemsg')
      .subscribe(data => {
        let suser = JSON.parse(data);
        //console.log(data);
        let msg = new function () { }
        msg.message = suser.message;
        msg.class = 'message-right-text-sender';
        msg.img = suser.img;
        msg.time = suser.time
        this.messagelist.push(msg);
        this.getChatList();
      });
  }

  addToIgnore(): void {
    console.log(this.huser);
    let param: URLSearchParams = new URLSearchParams();
    param.append('gid', this.huser.id)
    this.api.PostWithToken(param, 'add-to-ignore')
      .then(data => {
        if (data.error > 0)
          throw data.errmsg;

        this.toastr.success(this.huser.name + ' is now in your ignore list. ', 'Success');
        this.huser.id = 0,
          this.huser.name = 'Woovibe';
        this.huser.img = this.huser.gender == 1 ? this.utility.maleimg : this.utility.femaleimg;
        this.huser.city = 'None';
        this.huser.country = 'None';
        this.getChatList();
      })
      .catch(error => {
        this.toastr.error(error, 'Warning');
      })
  }

  getNewUser(): void {
    this.chat.getNewUserNotification()
      .subscribe(value => {
        let data = JSON.parse(value);
        this.chatuser.forEach(item => {
          if (data.id == item.id && item.canMessage == 0) {
            item.isOnline = true;
            setTimeout(() => {
              this.sendLoginUserInfo(data);
            }, 1000);
          }
        });
      });
  }

  onDisconnect(): void {
    this.chat.onDisconnect()
      .subscribe(value => {
        let index: number = 0;
        this.chatuser.forEach(item => {
          if (value.id == item.id) {
            item.isOnline = false;
          }
        });
      });
  }

  sendLoginUserInfo(data): void {
    this.chat.sendPrivateMesssage(data.id, 'loginuser', this.authusr.getUserGeneralInfo())

  }

  getLoginUserInfo(): void {
    this.chat.getPrivateMessage('loginuser')
      .subscribe(value => {
        let data = JSON.parse(value);
        this.chatuser.forEach(item => {
          if (data.id == item.id && item.canMessage == 0) {
            item.isOnline = true;
          }
        });
      });
  }
}
