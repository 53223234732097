import { Router } from '@angular/router';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-event-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.css']
})
export class SearchPanelComponent implements OnInit {
  @Input() type;

  search_obj: any = {
    location: '',
    date: '',
    type: ''
  }

  autocomplete = {
    inputString: ''
  }

  @Output() search: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router) { }

  ngOnInit() {
    let self = this;
    var date_input = $('input[name="date"]');
    let options = {
      format: 'mm/dd/yyyy',
      todayHighlight: true,
      autoclose: true
    };
    date_input.datepicker(options).on('changeDate', function (ev) {
      self.search_obj.date = $(this).val();
      self.fireSearchEvent();
    });;

    this.search_obj.type = this.type;
  }

  fireSearchEvent(): void {
    this.search.emit(this.search_obj);
    console.log(this.search_obj);
  }

  autoComplete(event: any) {
    if (event.data) {
      this.search_obj.location = event.data.formatted_address
    } else {
      this.search_obj.location = '';
    }
    this.fireSearchEvent();
  }

  redirect(): void {
    switch (this.search_obj.type) {
      case '1':
        this.router.navigate(['/near-events']);
        break;
      case '2':
        this.router.navigate(['/post-list']);
        break;
    }
  }
}
