import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'myaccount-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.css']
})
export class BillComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
