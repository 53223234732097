import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { ApicallService } from '../app-service/apicall.service';

@Component({
  selector: 'app-forgetpwd',
  templateUrl: './forgetpwd.component.html',
  styleUrls: ['./forgetpwd.component.css']
})
export class ForgetpwdComponent implements OnInit {

  token:string = '';
  name:string = '';
  email:string = '';
  password:string = '';
  conpassword:string = '';
  ismatch:boolean = true;
  showbtn:boolean = true;
  constructor(private route:ActivatedRoute, private api:ApicallService, public toastr: ToastsManager, vcr: ViewContainerRef, private router: Router) { 
    this.token =  this.route.snapshot.params['token'];
    // this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.getToken();
  }

  getToken():void {
    let param:URLSearchParams = new URLSearchParams();
    param.append('token', this.token);
    this.api.PostWithOutToken( param, 'forget-password-token')
    .then(data => {
      if(data.error)
        throw data.error;
      
      console.log(data);
      this.name = data.response.name;
      this.email = data.response.email;
    })
    .catch(error =>{
      console.log(error);
    });
  }

  resetPassword(isvalid:boolean):boolean {
    if(!isvalid)
      return false;
    
    if(this.password!=this.conpassword){
      this.toastr.error('Confirm password mismatch.','Warning');
      return false;
    }
    this.showbtn = false;
    let param:URLSearchParams = new URLSearchParams();
    param.append('token', this.token);
    param.append('password', this.password);
    this.api.PostWithOutToken( param, 'reset-forget-password')
    .then(data => {
      if(data.error)
        throw data.error;
      
      console.log(data);
      

      this.toastr.success('Password has been reset successfully...','Sucess');
      setTimeout(()=>{  this.router.navigate(['/home']);},1000);
      
    })
    .catch(error =>{
      console.log(error);
      this.showbtn = true;
    });

    return true;
  }

}
