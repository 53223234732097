import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Component } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ToastModule } from 'ng2-toastr/ng2-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdsenseModule } from 'ng2-adsense';
import { WindowRef } from './WindowRef';
import { Ng4GeoautocompleteModule } from 'ng4-geoautocomplete';


import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "angular5-social-login";

import { ApicallService } from './app-service/apicall.service';
import { LoginService } from './login/login.service'
import { AuthGuard } from './auth.guard';
import { OptionDataService } from './option-data/option-data.service';
import { UtilityService } from './utility/utility.service';
import { ChatService } from './chat-service/chat.service';
import { UserinfoService } from './userinfo/userinfo.service';
import { NotificationService } from './notification/notification.service';
import { Userresolver } from './resolver/userresolver';

import { routing } from './app.routing';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ProfileSetupComponent } from './profile-setup/profile-setup.component';
import { PreferenceComponent } from './preference/preference.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginHeaderComponent } from './login-header/login-header.component';
import { ChatComponent } from './chat/chat.component';
import { InnerNavComponent } from './inner-nav/inner-nav.component';
import { SocketIoModule, SocketIoConfig } from 'ng-socket-io';
import { ProfileComponent } from './profile/profile/profile.component';
import { DescriptionComponent } from './profile/description/description.component';
import { PhotoComponent } from './profile/photo/photo.component';
import { QuesansComponent } from './profile/quesans/quesans.component';
import { ProfileEditComponent } from './profile-edit/profile-edit/profile-edit.component';
import { DescriptionModalComponent } from './profile-modal/description-modal/description-modal.component';
import { DescriptionEditComponent } from './profile-edit/description-edit/description-edit.component';
import { PhotoEditComponent } from './profile-edit/photo-edit/photo-edit.component';
import { QuesansEditComponent } from './profile-edit/quesans-edit/quesans-edit.component';
import { GenerelSettingComponent } from './preference/generel-setting/generel-setting.component';
import { EthnicityComponent } from './preference/ethnicity/ethnicity.component';
import { LanguageComponent } from './preference/language/language.component';
import { AgeComponent } from './preference/age/age.component';
import { ChildrenComponent } from './preference/children/children.component';
import { ReligionComponent } from './preference/religion/religion.component';
import { IncomeComponent } from './preference/income/income.component';
import { SmokeComponent } from './preference/smoke/smoke.component';
import { DistanceComponent } from './preference/distance/distance.component';
import { MatchesComponent } from './allmatches/matches/matches.component';
import { MyfavoriteComponent } from './allmatches/myfavorite/myfavorite.component';
import { MeasfavoriteComponent } from './allmatches/measfavorite/measfavorite.component';
import { MutualuserComponent } from './allmatches/mutualuser/mutualuser.component';
import { IgnoreComponent } from './allmatches/ignore/ignore.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { EmailComponent } from './myaccount/email/email.component';
import { GeneralComponent } from './myaccount/general/general.component';
import { SettingComponent } from './myaccount/setting/setting.component';
import { BillingComponent } from './myaccount/billing/billing.component';
import { NotificationsComponent } from './myaccount/notifications/notifications.component';
import { PasswordComponent } from './myaccount/password/password.component';
import { BillComponent } from './myaccount/bill/bill.component';
import { ActivityComponent } from './activity/activity.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { RenderHtmlPipe } from './pipe/render-html.pipe';
import { NgxAutoScrollModule } from "ngx-auto-scroll";
import { PaymentComponent } from './payment/payment.component';
import { PaynowComponent } from './paynow/paynow.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { AboutComponent } from './about/about.component';
import { HelpComponent } from './help/help.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermComponent } from './term/term.component';
import { ContactComponent } from './contact/contact.component';
import { ForgetpwdComponent } from './forgetpwd/forgetpwd.component';
import { ViewedMeComponent } from './viewed-me/viewed-me.component';
import { LoginFooterComponent } from './login-footer/login-footer.component';
import { NewEventComponent } from './event/new-event/new-event.component';
import { EventComponent } from './event/event/event.component';
import { EventLoginUserListComponent } from './event/event-login-user-list/event-login-user-list.component';
import { NearEventsComponent } from './event/near-events/near-events.component';
import { EventDetailsComponent } from './event/event-details/event-details.component';
import { EventJoinComponent } from './event/event-join/event-join.component';
import { EventReplyComponent } from './event/event-reply/event-reply.component';
import { SearchPanelComponent } from './event/search-panel/search-panel.component';
import { AsiaComponent } from './asia/asia.component';
import { AustraliaComponent } from './australia/australia.component';
import { EuropeComponent } from './europe/europe.component';
import { MiddleEastComponent } from './middle-east/middle-east.component';
import { MuslimDatingComponent } from './muslim-dating/muslim-dating.component';
import { PostComponent } from './post/post/post.component';
import { NewPostComponent } from './post/new-post/new-post.component';
import { PostDetailsComponent } from './post/post-details/post-details.component';
import { NearPostsComponent } from './post/near-posts/near-posts.component';
import { SearchPostPanelComponent } from './post/search-post-panel/search-post-panel.component';
import { VideoComponent } from './video/video/video.component';
import { PostReplyComponent } from './post/post-reply/post-reply.component';
import { VideoListComponent } from './post/video-list/video-list.component';
import { PaginationComponent } from './app-pagination/app-pagination.component';

const config: SocketIoConfig = { url: 'https://app.woovibe.com:9005', options: { query: { cid: '220' }, reconnect: true } };

export function getAuthServiceConfigs() {
  let config_auth = new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        /*************Staging Auth******************/
        provider: new FacebookLoginProvider("2318927781725490")

        /*************Local host****************** */
        //provider: new FacebookLoginProvider("242221273209165")
      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        /****************Staging *****************/
        // provider: new GoogleLoginProvider("1035596848443-jfel1dvbuq1qcih20kiiqioslvqrdl8u.apps.googleusercontent.com")
        provider: new GoogleLoginProvider("1035596848443-jv176t45gontjgaa1j1ni0h670katpo5.apps.googleusercontent.com")


        /**************Local host***************************** */
        // provider: new GoogleLoginProvider("844494888892-ta9k8au4sjlhcnf58dv2q066hn4iq0rf.apps.googleusercontent.com")

      },
    ]
  );
  return config_auth;
}
@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    LoginComponent,
    ProfileSetupComponent,
    PreferenceComponent,
    DashboardComponent,
    LoginHeaderComponent,
    ChatComponent,
    InnerNavComponent,
    ProfileComponent,
    DescriptionComponent,
    PhotoComponent,
    QuesansComponent,
    ProfileEditComponent,
    DescriptionModalComponent,
    DescriptionEditComponent,
    PhotoEditComponent,
    QuesansEditComponent,
    GenerelSettingComponent,
    EthnicityComponent,
    LanguageComponent,
    AgeComponent,
    ChildrenComponent,
    ReligionComponent,
    IncomeComponent,
    SmokeComponent,
    DistanceComponent,
    MatchesComponent,
    MyfavoriteComponent,
    MeasfavoriteComponent,
    MutualuserComponent,
    IgnoreComponent,
    MyaccountComponent,
    EmailComponent,
    GeneralComponent,
    SettingComponent,
    NotificationsComponent,
    PasswordComponent,
    BillingComponent,
    BillComponent,
    ActivityComponent,
    ConfirmModalComponent,
    RenderHtmlPipe,
    PaymentComponent,
    PaynowComponent,
    PaymentHistoryComponent,
    AboutComponent,
    HelpComponent,
    PrivacyComponent,
    TermComponent,
    ContactComponent,
    ForgetpwdComponent,
    ViewedMeComponent,
    LoginFooterComponent,
    NewEventComponent,
    EventComponent,
    EventLoginUserListComponent,
    NearEventsComponent,
    EventDetailsComponent,
    EventJoinComponent,
    EventReplyComponent,
    SearchPanelComponent,
    AsiaComponent,
    AustraliaComponent,
    EuropeComponent,
    MiddleEastComponent,
    MuslimDatingComponent,
    PostComponent,
    NewPostComponent,
    PostDetailsComponent,
    NearPostsComponent,
    SearchPostPanelComponent,
    VideoComponent,
    PostReplyComponent,
    VideoListComponent,
    PaginationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    SocketIoModule.forRoot(config),
    routing,
    ToastModule.forRoot(),
    NgxAutoScrollModule,
    NgSelectModule,
    SocialLoginModule,
    AdsenseModule.forRoot({
      adClient: 'ca-pub-8700169514396413',
      pageLevelAds: true
    }),
    Ng4GeoautocompleteModule.forRoot()
  ],
  providers: [
    ApicallService,
    LoginService,
    ChatService,
    AuthGuard,
    OptionDataService,
    UtilityService,
    UserinfoService,
    Userresolver,
    CookieService,
    WindowRef,
    NotificationService,
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
