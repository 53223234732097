import { Injectable } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { Http, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';

@Injectable()
export class ApicallService {


  baseUrl:string = environment.baseUrl;
  // baseUrl:string = "https://dev.woovibe.com/backend/v1/";
  // baseUrl: string = "http://localhost/WooVibeAPI/v1/";
  // baseUrl: string = "http://woovibe.local/v1/";

  // chatbaseurl:string = "http://localhost:9005/api/";
  chatbaseurl: string = "https://app.woovibe.com:9005/api/";
  authurl: string = "https://woovibe.com";

  urlSearchParams: URLSearchParams;
  seckey: string = 'ikju87uyhggteojhb6790ojke34';
  tmp_seckey: string = 'ujhnhgyt6ytgftr';

  constructor(private httpclient: HttpClient, private http: Http, private cookie: CookieService, private platformLocation: PlatformLocation) { }

  PostWithOutToken(urlSearchParams: URLSearchParams, suburl: string): Promise<any> {
    //let headers:Headers = new Headers();
    //let url:string = this.baseUrl + suburl;
    //headers.append('Content-Type', 'application/x-www-form-urlencoded');

    let url: string = this.baseUrl + suburl;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    if ((this.platformLocation as any).location.origin != this.authurl) {
      // if (urlSearchParams instanceof URLSearchParams) {
      //   urlSearchParams.append('testserver', 'dev');
      // }
    }
    return this.httpclient.post(url, urlSearchParams.toString(), { headers: headers })
      .toPromise()
      .then(respose => {
        return respose;
      })
      .catch(error => {
        return 'system-error';
      })
  }

  PostWithToken(urlSearchParams: URLSearchParams, suburl: string): Promise<any> {
    // let headers:Headers = new Headers();
    // let url:string = this.baseUrl + suburl;
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');
    // headers.append('Authorization', "Bearer " + sessionStorage.getItem(this.seckey));

    let url: string = this.baseUrl + suburl;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    //headers = headers.set('Authorization', "Bearer " + sessionStorage.getItem(this.seckey));
    headers = headers.set('Authorization', "Bearer " + this.cookie.get(this.seckey));
    if ((this.platformLocation as any).location.origin != this.authurl) {
      if (urlSearchParams instanceof URLSearchParams) {
        // urlSearchParams.append('testserver', 'dev');
      }
    }
    return this.httpclient.post(url, urlSearchParams.toString(), { headers: headers })
      .toPromise()
      .then(respose => {
        return respose;
      })
      .catch(error => {
        return 'system-error';
      })
  }

  postChatMessage(urlSearchParams: URLSearchParams, suburl: string, headers: Headers): Promise<any> {
    let url: string = this.chatbaseurl + suburl;
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Accept', 'application/json');
    headers.append('originurl', this.authurl);
    headers.append('clientid', 'rahul-100');
    headers.append('secretkey', 'sec-100');

    return this.http.post(url, urlSearchParams, new RequestOptions({ headers: headers }))
      .toPromise()
      .then(respose => {
        return respose.json();
      })
      .catch(error => {
        return 'system-error';
      })
  }

  uploadImages(formdata: FormData, suburl: string): Promise<any> {
    let header = new Headers();
    let url: string = this.baseUrl + suburl;
    //header.append('Authorization', "Bearer " + sessionStorage.getItem(this.seckey));
    header.set('Authorization', "Bearer " + this.cookie.get(this.seckey));
    return this.http.post(url, formdata, new RequestOptions({ headers: header }))
      .toPromise()
      .then(respose => {
        return respose.json();
      })
      .catch(error => {
        return 'system-error';
      })
  }

  PostWithTokenImage(formdata: FormData, suburl: string): Promise<any> {
    let header = new Headers();
    let url: string = this.baseUrl + suburl;
    //header.append('Authorization', "Bearer " + sessionStorage.getItem(this.seckey));
    header.set('Authorization', "Bearer " + this.cookie.get(this.seckey));
    if ((this.platformLocation as any).location.origin != this.authurl) {
      // formdata.append('testserver', 'dev');
    }
    return this.http.post(url, formdata, new RequestOptions({ headers: header }))
      .toPromise()
      .then(respose => {
        return respose.json();
      })
      .catch(error => {
        return 'system-error';
      })
  }
}

