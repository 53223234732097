import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';

import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { UtilityService } from '../../utility/utility.service'
import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { ChatService } from '../../chat-service/chat.service';

declare var $: any;

@Component({
  selector: 'app-distance',
  templateUrl: './distance.component.html',
  styleUrls: ['./distance.component.css']
})
export class DistanceComponent implements OnInit {

  Object = Object;
  countrylist: any = [];
  country: string = '';
  zipcode: string = '';
  prefdistance: string = '0';
  distanceimpt: string = '1';
  countryname: string = '';
  constructor(public chat: ChatService, public user: UserinfoService, public utility: UtilityService, public api: ApicallService, public pdata: OptionDataService, public toastr: ToastsManager, public vcr: ViewContainerRef) {
    // this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.user.listen.subscribe((data) => { if (data) this.initAfterService() });
    this.loadCountry();
  }

  initAfterService() {
    console.log(this.country);
    this.country = this.user.ucountry;
    this.zipcode = this.user.zipcode;
    if (this.user.prefdistance == null)
      this.user.prefdistance = '0';
    if (this.user.prefdistanceimt == null)
      this.user.prefdistanceimt = '1';

    this.prefdistance = this.user.prefdistance;
    console.log(this.country);
    //console.log(this.user.prefdistance);
  }

  loadCountry(): void {
    let param: URLSearchParams = new URLSearchParams();
    this.api.PostWithToken(param, 'country-list')
      .then(data => {
        if (data.error > 0)
          throw data.errmsg;
        this.countrylist = data;
      })
      .catch(error => {
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })
  }

  saveProfile(field, value): void {
    let param: URLSearchParams = new URLSearchParams();
    param.append(field, value);

    this.api.PostWithToken(param, 'update-profile')
      .then(data => {
        console.log(data);
        if (data.error > 0)
          throw data.errmsg;

        switch (field) {
          case 'zip_code':
            this.user.zipcode = this.zipcode;
            break;
          case 'country':
            this.user.ucountry = this.country;
            this.user.ucountry_obj.cname = this.countryname;
            break;
        }
        this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});

        $("#postal").modal('hide');
        $("#country").modal('hide');
        $("#location").modal('hide');
        $("#important").modal('hide');
        this.toastr.success('Profile has been updated successfully. ', 'Success');
      })
      .catch(error => {
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })
  }

  getCountryName(): void {
    this.countrylist.forEach(item => {
      if (item.code == this.country) {
        this.countryname = item.cname;
      }
    });
  }

  savePreference(field: string, value: string): void {
    let param: URLSearchParams = new URLSearchParams();
    param.append(field, value);
    this.api.PostWithToken(param, 'update-profile-preference')
      .then(data => {
        if (data.error > 0)
          throw data.errmsg;


        switch (field) {
          case 'distance':
            this.user.prefdistance = this.prefdistance;
            break;
          case 'q_distance':
            this.user.prefdistanceimt = this.distanceimpt;
            break;
        }

        $("#location").modal('hide');
        $("#important").modal('hide');
        this.toastr.success('Distance information has been updated successfully. ', 'Success');
      })
      .catch(error => {
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })
  }
}
