import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RequestOptions, URLSearchParams } from '@angular/http';
import { Router } from '@angular/router';

import { UtilityService } from '../utility/utility.service';
import { ApicallService } from '../app-service/apicall.service';
import { LoginService } from '../login/login.service'
import { OptionDataService } from '../option-data/option-data.service';

declare var $: any;
@Component({
  selector: 'app-australia',
  templateUrl: './australia.component.html',
  styleUrls: ['./australia.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ApicallService]
})
export class AustraliaComponent implements OnInit {

  firstForm: string = 'block';
  secondForm: string = 'none';
  country: any = [];
  user: any = {
    name: '',
    gender: '1',
    seeking: '2',
    email: '',
    zipcode: '',
    hearfrom: '0',
    pwd: '',
    city: '',
    dob: '',
    country: 1
  };

  errobj: any = {
    iserr: false,
    errmsg: ''
  };

  maleone: boolean = true;
  femaleone: boolean = false;
  constructor(public api: ApicallService, private router: Router, private login: LoginService, public pdata: OptionDataService, public utility: UtilityService) {
    this.user.dob = this.pdata.defultEndDate;
  }

  ngOnInit() {
    if (this.login.isLogin()) {
      this.router.navigate(['/dashboard']);
    }
    this.loadCountry();
    this.loadDatePicker();
  }

  loadDatePicker(): void {
    let self = this;
    var date_input = $('input[name="date"]');
    let options = {
      format: 'mm/dd/yyyy',
      todayHighlight: true,
      autoclose: true,
      endDate: self.pdata.defultEndDate
    };

    date_input.change(function () {
      if ($(this).val() == '') {
        $(this).val(self.pdata.defultEndDate);
      }
    });
    date_input.datepicker(options);
  }

  showNextForm(isValid: boolean): any {
    if (isValid) {
      this.secondForm = 'block';
      this.firstForm = 'none';
    }
  }

  setgender(gnd: any, sck: any): void {
    this.user.gender = gnd;
    this.user.seeking = sck;
    this.femaleone = !this.femaleone;
    this.maleone = !this.femaleone
  }

  setSeeking(gnd: any, sck: any): void {
    this.user.gender = gnd;
    this.user.seeking = sck;
    this.femaleone = !this.femaleone;
    this.maleone = !this.femaleone;
  }

  loadCountry(): void {
    this.api.PostWithOutToken(new URLSearchParams(), 'country-list')
      .then(data => {
        if (data.error) {
          throw data.error;
        }
        //console.log(data);
        this.country = data
      })
      .catch(error => {
        console.log(error);
      })
  }

  saveUserInfo(isValid: boolean): any {
    if (!isValid)
      return false;

    let dobstr = $('input[name="date"]').val();
    let param: URLSearchParams = new URLSearchParams();
    param.append('email', this.user.email);
    param.append('name', this.user.name);
    param.append('password', this.user.pwd);
    param.append('gender', this.user.gender);
    param.append('seeking', this.user.seeking);
    param.append('zip_code', this.user.zipcode);
    param.append('country', this.user.country);
    param.append('city', this.user.city);
    param.append('dob', this.utility.toMySqlDateFormat(dobstr));
    param.append('register_page', 'australia');
    this.api.PostWithOutToken(param, 'register')
      .then(data => {
        console.log(data);
        if (data.error > 0)
          throw data.errmsg;

        this.login.saveToken(data.response.token);
        this.sendEmailToAdmin();
        //this.router.navigate(['/preference']);

        this.router.navigate(['/matches']);
      })
      .catch(error => {
        this.errobj.iserr = true;
        this.errobj.errmsg = error.toString();
      })
  }

  sendEmailToAdmin() {
    let param: URLSearchParams = new URLSearchParams();
    this.api.PostWithToken(param, 'new-user-notication-email');
  }

}
