import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { Router } from '@angular/router';

import { UtilityService } from '../../utility/utility.service'
import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { LoginService } from '../../login/login.service';
import { ChatService } from '../../chat-service/chat.service';

import { ToastsManager } from 'ng2-toastr/ng2-toastr';

declare var $: any;

@Component({
  selector: 'myaccount-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit {

  phone: string = '';
  email: string = '';
  oldpwd: string = '';
  newpwd: string = '';
  conpwd: string = '';
  reason: string = '1';
  convalid: boolean = false;
  ntfMsg: any = null;
  loader: boolean = false;
  errobj: any = {
    iserr: false,
    errmsg: '',
    errclass: ''
  };

  constructor(public chat: ChatService, public utility: UtilityService, public api: ApicallService, public optdata: OptionDataService, public pdata: OptionDataService, public user: UserinfoService, public toastr: ToastsManager, vcr: ViewContainerRef, private router: Router, private login: LoginService) {
    // this.toastr.setRootViewContainerRef(vcr);
    this.ntfMsg = this.optdata.notification;
  }

  ngOnInit() {
    this.user.listen.subscribe((data) => { if (data) this.initAfterService() });
  }

  initAfterService(): void {
    console.log(this.user);
    this.email = this.user.uemail;
    this.phone = this.user.uphone;
  }

  updateProfile(isvalid: boolean): boolean {

    if (!isvalid) return false;
    this.loader = true;
    let param: URLSearchParams = new URLSearchParams();
    param.append('mobile', this.phone);

    if (this.email != '')
      param.append('email', this.email);

    this.api.PostWithToken(param, 'update-profile')
      .then(data => {
        this.loader = false;
        if (data.error)
          throw data.error;
        this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});
        
        this.toastr.success(this.ntfMsg.generalSucessMsg, this.ntfMsg.generalSuccessSub);
      })
      .catch(error => {
        //alert(error);
        this.toastr.error('Can\'t update you profile. Make sure you have given unique mobile or email address.', this.ntfMsg.generalErrSub);
        this.loader = false;
      })

    return true;
  }

  changePassword(isvalid: boolean): boolean {
    if (!isvalid || !this.convalid) return false;

    this.loader = true;
    let param: URLSearchParams = new URLSearchParams();

    param.append('oldpwd', this.oldpwd);
    param.append('newpwd', this.newpwd);

    this.api.PostWithToken(param, 'change-password')
      .then(data => {
        this.loader = false;
        if (data.error > 0)
          throw data.errmsg;

        this.errobj.iserr = true;
        this.errobj.errclass = 'alert alert-success'
        this.errobj.errmsg = 'Password has been changed successfully..';
        this.oldpwd = '';
        this.newpwd = '';
        this.conpwd = '';
        this.toastr.success('Password has been changed successfully..', this.ntfMsg.generalSuccessSub);
      })
      .catch(error => {
        this.loader = false;
        this.errobj.iserr = true;
        this.errobj.errmsg = error.toString();
        this.errobj.errclass = 'alert alert-danger'
        this.toastr.error('Can\'t update your password. Make sure you enterted right format.', this.ntfMsg.generalErrSub);
      })

    return true;
  }

  showWarning(): void {
    $("#dlgreason").modal('show');
  }

  closeAccount() {
    $("#dlgreason").modal('hide');
    this.api.PostWithToken(new URLSearchParams(), 'delete-user')
      .then(data => {
        if (data.error)
          throw data.error;
        this.loader = false;
        this.toastr.success('Account has been deleted successfully.', this.ntfMsg.generalSuccessSub);
        this.logout();
      })
      .catch(error => {
        this.toastr.error('Can\'t delete your account.', this.ntfMsg.generalErrSub);
        this.loader = false;
      })
  }

  logout(): void {
    this.login.logOut()
      .then(data => {
        this.login.removeToken();
        this.router.navigate(['/home']);
      })
      .catch(error => {
        this.toastr.error('Can\'t logout.', this.ntfMsg.generalErrSub);
      });
  }

}
