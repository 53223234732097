import { Component, OnInit } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { Router } from '@angular/router';

import {
  AuthService,
  FacebookLoginProvider,
  GoogleLoginProvider
} from 'angular5-social-login';

import { ApicallService } from '../app-service/apicall.service';
import { LoginService } from '../login/login.service';
import { CookieService } from 'ngx-cookie-service';
import { UserinfoService } from '../userinfo/userinfo.service';
import { OptionDataService } from '../option-data/option-data.service';
import { UtilityService } from '../utility/utility.service';

declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  email: string = '';
  password: string = '';
  femail: string = '';
  showbtn: boolean = true;
  forget_pwd_checked = false;
  loader: boolean = false;
  social_error = false;
  country: any;
  errobj: any = {
    iserr: false,
    errmsg: ''
  };
  userobj: any = {
    name: '',
    gender: '1',
    seeking: '2',
    email: '',
    zipcode: '',
    hearfrom: '0',
    pwd: '',
    city: '',
    dob: '',
    country: 1
  };

  toggle: string = "login";
  constructor(public api: ApicallService, public login: LoginService, private router: Router, private holdpwd: CookieService, public user: UserinfoService, private socialAuthService: AuthService, public pdata: OptionDataService, public utility: UtilityService) { }

  ngOnInit() {
    this.login.listen.subscribe((data) => { if (data) this.toggle = 'login' });
    if (this.holdpwd.get('useremail') != '') {
      this.email = this.holdpwd.get('useremail');
      this.password = this.holdpwd.get('userpwdword');
      this.forget_pwd_checked = true;

    }

    this.loadCountry();
  }
  ngAfterViewInit() {
    this.loadDatePicker();
  }

  doLogin(isValid: boolean): any {
    if (!isValid)
      return false;
    this.login.doLogIn(this.email, this.password)
      .then(data => {

        if (data.error > 0)
          throw data.errmsg;

        if (this.forget_pwd_checked) {
          this.holdpwd.set('useremail', this.email, 100);
          this.holdpwd.set('userpwdword', this.password, 100);
        } else {
          this.holdpwd.delete('useremail');
          this.holdpwd.delete('userpwdword');
        }
        this.login.saveToken(data.response.token)
        $('.modal.in:visible').modal('hide');
        this.holdpwd.set('isLogin', "1", (0.5 / 1440));
        this.router.navigate(['/dashboard']);
        //window.location.reload();
      })
      .catch(error => {
        console.log(error);
        this.errobj.iserr = true;
        this.errobj.errmsg = error.toString();
      })
  }

  toggleReset(value: string, status: boolean): void {
    this.toggle = value;
    this.login.isTrigger.next(status);
    this.errobj.iserr = false;;
    this.errobj.errmsg = '';
    this.showbtn = true;

  }

  sendEmail(isvalid: boolean): boolean {
    if (!isvalid)
      return false;

    this.showbtn = false;
    let param: URLSearchParams = new URLSearchParams();
    param.append('email', this.femail);
    this.api.PostWithOutToken(param, 'forget-password')
      .then(data => {
        if (data.error)
          throw data;

        // console.log(data);
        this.toggle = 'esend';
        this.showbtn = true;
      })
      .catch(error => {
        console.log(error);
        this.errobj.iserr = true;
        this.errobj.errmsg = error.errmsg;
        this.showbtn = true;
      });

    return true;
  }

  public socialSignIn(socialPlatform: string) {
    this.loader = true;
    let socialPlatformProvider;
    if (socialPlatform == "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform == "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        this.login.doLoginWithApp(userData.email)
          .then(data => {

            if (data.error == 0) {
              this.loader = false;
              this.login.saveToken(data.response.token)
              $('.modal.in:visible').modal('hide');
              this.holdpwd.set('isLogin', "1", (0.5 / 1440));
              this.router.navigate(['/dashboard']);
            } else {
              this.loader = false;
              if (data.error == 214) {
                this.social_error = true;
              } else {
                this.userobj.name = userData.name;
                this.userobj.email = userData.email;
                $("#loginModal").modal('hide');
                $("#social-info").modal('show');
              }
            }
          })
          .catch(error => {
            console.log(error);
            this.loader = false;
          });
        console.log(socialPlatform + " sign in data : ", userData);
      }
    );
  }

  loadDatePicker(): void {
    let self = this;
    var date_input = $('input[name="date"]');
    let options = {
      format: 'mm/dd/yyyy',
      todayHighlight: true,
      autoclose: true,
      endDate: self.pdata.defultEndDate
    };

    date_input.change(function () {
      if ($(this).val() == '') {
        $(this).val(self.pdata.defultEndDate);
      }
    });
    date_input.datepicker(options);
  }

  loadCountry(): void {
    this.api.PostWithOutToken(new URLSearchParams(), 'country-list')
      .then(data => {
        if (data.error) {
          throw data.error;
        }
        //console.log(data);
        this.country = data
      })
      .catch(error => {
        console.log(error);
      })
  }

  alterGender(status: string): void {
    if (status == 'gender') {
      if (this.userobj.gender == 1) {
        this.userobj.seeking = 2;
      }
      else {
        this.userobj.seeking = 1;
      }
    }

    if (status == 'seeking') {
      if (this.userobj.seeking == 1) {
        this.userobj.gender = 2;
      }
      else {
        this.userobj.gender = 1;
      }
    }

  }

  loginWithSocialAuth(isvalid): boolean {
    if (isvalid) {
      return false
    }
    this.loader = true;
    let dobstr = $('input[name="date"]').val();
    let param: URLSearchParams = new URLSearchParams();
    param.append('email', this.userobj.email);
    param.append('name', this.userobj.name);
    param.append('password', this.userobj.email);
    param.append('gender', this.userobj.gender);
    param.append('seeking', this.userobj.seeking);
    param.append('zip_code', this.userobj.zipcode);
    param.append('country', this.userobj.country);
    param.append('city', this.userobj.city);
    param.append('dob', this.utility.toMySqlDateFormat(dobstr));
    param.append('user_type', 'social');
    this.api.PostWithOutToken(param, 'register')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }

        this.login.saveToken(data.response.token);
        $('.modal.in:visible').modal('hide');
        this.sendEmailToAdmin();
        this.loader = false;
        this.router.navigate(['/profile-edit']);
      })
      .catch(error => {
        console.log(error);
        this.loader = false;
      })
  }

  sendEmailToAdmin() {
    let param: URLSearchParams = new URLSearchParams();
    this.api.PostWithToken(param, 'new-user-notication-email');
  }

}
