import { Component, OnInit, Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { UserinfoService } from '../userinfo/userinfo.service';

@Injectable()
export class Userresolver implements Resolve<any> {

    constructor(public user:UserinfoService){}

    resolve(route:ActivatedRouteSnapshot) {
        this.user.loadLoginUserInfo()
        .then(data => {
          
          if(data.error > 0)
            throw data.errmsg;
    
          //this.user = data.response;
          //console.log(this.user);
          this.user.loadData(data.response);
          return this.user;
        })
        .catch(error =>{
          console.log(error);
        })
        console.log(this.user.aboutme);
    }
}
