import { Router } from '@angular/router';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-search-post-panel',
  templateUrl: './search-post-panel.component.html',
  styleUrls: ['./search-post-panel.component.css']
})
export class SearchPostPanelComponent implements OnInit {
  @Input() type;

  search_obj: any = {
    title: '',
    date: '',
    type: ''
  }

  @Output() search: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router) { }

  ngOnInit() {
    let self = this;
    var date_input = $('input[name="date"]');
    let options = {
      format: 'mm/dd/yyyy',
      todayHighlight: true,
      autoclose: true
    };
    date_input.datepicker(options).on('changeDate', function (ev) {
      self.search_obj.date = $(this).val();
      self.fireSearchEvent();
    });;

    this.search_obj.type = this.type;
  }

  fireSearchEvent(): void {
    this.search.emit(this.search_obj);
    console.log(this.search_obj);
  }

  redirect(): void {
    switch (this.search_obj.type) {
      case '1':
        this.router.navigate(['/near-posts']);
        break;
      case '2':
        this.router.navigate(['/video-list']);
        break;
    }
  }
}

