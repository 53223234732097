import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/operator/map';
import { URLSearchParams } from '@angular/http';
import { CookieService } from 'ngx-cookie-service';

import { ApicallService } from '../app-service/apicall.service';

@Injectable()
export class LoginService {

  logstatus: boolean = false;
  isTrigger: BehaviorSubject<boolean>;
  listen: any;
  constructor(public api: ApicallService, private cookie: CookieService) {
    this.isTrigger = new BehaviorSubject<boolean>(false);
    this.listen = this.isTrigger.asObservable();
  }

  doLogIn(email: string, password: string): any {
    let param: URLSearchParams = new URLSearchParams();
    param.append('email', email);
    param.append('password', password);

    return this.api.PostWithOutToken(param, 'login')

  }

  doLoginWithApp(email: string): any {
    let param: URLSearchParams = new URLSearchParams();
    param.append('email', email);

    return this.api.PostWithOutToken(param, 'login-with-social-app')
  }

  logOut(): any {
    let param: URLSearchParams = new URLSearchParams();
    return this.api.PostWithToken(param, 'logout')
  }

  isLogin(): boolean {
    //return (sessionStorage.getItem(this.api.seckey) !== null);
    return (this.cookie.get(this.api.seckey) ? true : false);
  }

  saveToken(token): void {
    this.cookie.set(this.api.seckey, token);
    //sessionStorage.setItem(this.api.seckey, token);
    this.logstatus = true;
  }

  removeToken(): void {
    //sessionStorage.clear();
    this.cookie.delete(this.api.seckey);
    this.cookie.deleteAll();
    this.logstatus = false;
  }

}
