import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';

import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { ApicallService } from '../app-service/apicall.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  name:string = '';
  email:string = '';
  mobile:string = '';
  subject:string = '';
  message:string = '';
  btnshow:boolean = true;
  constructor(public api:ApicallService, public toastr: ToastsManager, vcr: ViewContainerRef) {
    // this.toastr.setRootViewContainerRef(vcr);
   }

  ngOnInit() {
    
  }

  sendSubject(isvalid):boolean {
    if(!isvalid)
      return false;
    let param:URLSearchParams = new URLSearchParams();
    this.btnshow = false;
    param.append('name',this.name);
    param.append('mobile',this.mobile);
    param.append('subject',this.subject);
    param.append('email',this.email);
    param.append('message',this.message);

    this.api.PostWithOutToken( param,'contact-email')
    .then(data => {
      console.log(data);
      if(data.error)
        throw data;

      
     
      this.toastr.success('Your query has been successfully submitted to Woovibe Team. Would inform you soon.', 'Success');
      this.name = '';
      this.mobile = '';
      this.subject = '';
      this.email = '';
      this.message = '';
      this.btnshow = true;
    })
    .catch(error =>{
      this.btnshow = true;
      this.toastr.error('Can not send your query. Please try later.', 'warning');
    })
    return true;
  }

}
