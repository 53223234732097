import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';

import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { UserinfoService } from '../../userinfo/userinfo.service';
import { UtilityService } from '../../utility/utility.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { ApicallService } from '../../app-service/apicall.service';

@Component({
  selector: 'profile-quesans-edit',
  templateUrl: './quesans-edit.component.html',
  styleUrls: ['./quesans-edit.component.css']
})
export class QuesansEditComponent implements OnInit {

  questionlist:any = [];
  filter:string = 'all';
  loader:boolean = false;
  spiner:boolean = false;

  constructor(public user:UserinfoService, public utility:UtilityService, public optdata:OptionDataService, public api:ApicallService, public toastr: ToastsManager, vcr: ViewContainerRef) { 
    // this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.getUserQuestionAnswer(false);
  }

  searchByFilter(status):void {
    this.filter = status;
    this.getUserQuestionAnswer(false);
  }

  getUserQuestionAnswer(isalert:boolean):void {
    this.questionlist = [];
    this.loader = true;
    let param:URLSearchParams = new URLSearchParams(); 
    this.api.PostWithToken(param, 'get-questions')
    .then(data => {
      if(data.error){
        throw data.error;
      }
      if(isalert){
        this.toastr.success('Answers has been updated successfully.', 'Success');
      }
      
      //console.log(data);
      data.response.forEach(item => {
        let ques = new function(){};
        ques.question = item.question;
        ques.id = item.id;
        ques.ischeck = false;
        ques.details = '';
        ques.hasquestion = false;
        ques.answers = [];
        item.answer_detail.forEach(ans => {
          let answer = new function(){}
            answer.answer = ans.answer;
            answer.details = '';
            answer.id = ans.id;
            answer.question_id = ans.question_id;
            answer.ischeck = false;
            answer.hasquestion = false;
            this.haveTakenQuestion(ques.id, ans.id, answer);
            answer.status = answer.ischeck;
            ques.hasquestion = answer.hasquestion;
            if(ques.details == '')
              ques.details = answer.details;

            if(this.filter == 'answered' && answer.hasquestion)  
              ques.answers.push(answer);
            else if(this.filter == 'unanswered' && !answer.hasquestion)
              ques.answers.push(answer);
            else if(this.filter == 'all')
              ques.answers.push(answer);
        });
        if(ques.answers.length > 0)
          this.questionlist.push(ques);

        
        
      });
      this.loader = false;
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }
  haveTakenQuestion(qid, aid, answerobj):void {
    this.user.questionanswer.forEach(obj => {
      if(obj.question.id == qid){
        obj.question.answer.forEach(ansobj => {
          if(ansobj.answer_id == aid){
            answerobj.ischeck = true;
            answerobj.details = ansobj.description;
          }
          answerobj.hasquestion = true;
        });
      }
    });
  }

  save(question):void {
    let promises:Promise<any>[] = [];
    let qid = question.id;
    let desc = question.details;
    question.answers.forEach(ans => {
      if(ans.ischeck && !ans.status){
        console.log('update');
        promises.push(this.saveIntoDB(qid,ans.id, desc, '1'))
      }else if(!ans.ischeck && ans.status){
        console.log('insert');
        promises.push(this.saveIntoDB(qid,ans.id, desc, '0'));
      }else if(ans.details != question.details && ans.ischeck == ans.status){
        console.log('description update');
        promises.push(this.saveIntoDB(qid,ans.id, desc, '2'))
      }
      ans.ischeck = ans.status; 
    });

    Promise.all(promises).then((data:any) => { 
      if(data.error){
        throw data.errmsg;
      }
      
      this.user.defaultValue();
      this.user.loadLoginUserInfo()
        .then(data => {
          if(data.error > 0){
            throw data.errmsg;
          }
          this.user.user = data.response;
          this.user.loadData(this.user.user);
          this.getUserQuestionAnswer(true);
          
        })
        .catch(error =>{
          console.log('error');
        })
      //this.user.reloadUserInfo()
    })
    .catch(error => {
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    });

  }

  saveIntoDB(qid:string, aid:string, desc:string, status:string):Promise<any> {
    let parm:URLSearchParams = new URLSearchParams();
    if(desc.trim() == '' && status == '2'){
      desc = "#######";
    }
    parm.append('qid', qid);
    parm.append('aid', aid);
    parm.append('desc', desc);
    parm.append('uqs', status);
    
    return this.api.PostWithToken(parm,'set-user-question-answer');
  }

  setAnswer(list,ans):void {
    list.forEach(item => {
      if(item.id == ans.id)
      item.status = true;
      else
      item.status = false;
    });
  }

}
