import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import { URLSearchParams } from '@angular/http';

import { ChatService } from '../chat-service/chat.service';
import { UserinfoService } from '../userinfo/userinfo.service';
import { ApicallService } from '../app-service/apicall.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastsManager } from 'ng2-toastr/ng2-toastr';



@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.css']
})
export class LoginHeaderComponent implements OnInit {

  constructor( public toastr: ToastsManager, private cookie: CookieService, public chat:ChatService, public login:LoginService, private router: Router, public userinfo:UserinfoService, public api:ApicallService) { }

  ngOnInit() {
    let parm:URLSearchParams = new URLSearchParams();
    this.userinfo.loadLoginUserInfo()
    .then(data => {
      if(data.error > 0)
        throw data.error;
      this.userinfo.user = data.response;
      this.chat.initSocket();
      this.chat.sendNewUserNotification();
    })
    .catch(error =>{
      console.log(error);
    })

    //
    if(this.cookie.get('isLogin') === "1") {
      this.cookie.set('isLogin', "0", (0.5 / 1440));
      this.api.PostWithToken( parm,'get-unread-notification')
      .then(data => {
        if(data.error > 0){
          throw data.errmsg;
        }
        
        for (let index = 0; index < data.response.length; index++) {
          if(index == 10)
            break;
          this.toastr.onClickToast()
          .subscribe( toast => {         
            let url = '/myprofile/' + data.response[index].slug;
            this.router.navigate([url]);
          });
          this.toastr.success(data.response[index].description, null, {toastLife: 3000});
        }
      })
      .catch(error =>{
        console.log(error);
      })
    }
  }
}
