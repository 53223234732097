import { Component, OnInit, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastsManager } from 'ng2-toastr/ng2-toastr';
import { URLSearchParams } from '@angular/http';
import {Location} from '@angular/common';
import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { ChatService } from '../../chat-service/chat.service';
import { UtilityService } from '../../utility/utility.service';
@Component({
  selector: 'app-post-details',
  templateUrl: './post-details.component.html',
  styleUrls: ['./post-details.component.css']
})
export class PostDetailsComponent implements OnInit {

  loader: boolean = false;
  question: string = '';
  event: any = {
    title: '',
    desc: '',
    feature_img: '',
    id: '',
    uid: '',
    question :'',
    like : '',
    view:''
  }
  message_list: any = [];
  notfound: boolean = false;
  isCountView:boolean = false;
  constructor(private _location: Location, public chat: ChatService, public toastr: ToastsManager, vcr: ViewContainerRef, private route: ActivatedRoute, private api: ApicallService, public user: UserinfoService, private redirect: Router, public utility: UtilityService) { }

  ngOnInit() {
    this.user.listen.subscribe((data) => { if (data) this.initAfterService() });
  }

  initAfterService(): void {
    this.setvalue(this.route.snapshot.params['slug'])
    this.getAllReplay();
  }
  setvalue(slug): void {
    let parm: URLSearchParams = new URLSearchParams();
    parm.append('slug', slug);
    this.api.PostWithToken(parm, 'get-post-details')
      .then(data => {
        if (data.error) {
          throw data.error;
        }
        console.log(data.response);
        this.event.id = data.response.id;
        this.event.title = data.response.title;
        this.event.desc = data.response.post_details;
        this.event.slug = data.response.slug;
        this.event.posted_on = data.response.posted_date;
        this.event.feature_video = (data.response.featurevideo.length > 0) ? data.response.featurevideo[0].video_url : 'assets/img1.png';
        this.event.uid = data.response.user_id;
        this.event.like = data.response.like.length;
        this.event.question = data.response.post_question_count.length;
        this.event.view = data.response.view_count;
        console.log(this.event);
        

      })
      .catch(error => {
        this.toastr.error(error, 'Error');
      })
  }

  askQuestion(): boolean {
    if (this.question.trim() == '' || this.question.length > 1200) {
      this.toastr.error('Make sure you have entered conrrect informatioin', 'Error');
      return false;
    }
    this.loader = true;
    let parm: URLSearchParams = new URLSearchParams();
    parm.append('uid', this.event.uid);
    parm.append('eid', this.event.id);
    parm.append('question', this.question);
    this.api.PostWithToken(parm, 'add-post-question')
      .then(data => {
        if (data.error) {
          throw data.error;
        }
        this.loader = false;
        this.question = '';
        this.toastr.success('Your question has been sent successfully', 'Success');
        this.chat.sendNewMsgNotification({id: this.event.id, slug: this.event.slug ,msg: data.response, action: 'post-question'});
        this.getAllReplay();
      })
      .catch(error => {
        this.toastr.error(error, 'Error');
        this.loader = false;
      })
    return true;
  }

  getAllReplay(): void {
    this.message_list = [];
    let msgobj = function () { };
    let slug = this.route.snapshot.params['slug'];
    let parm: URLSearchParams = new URLSearchParams();
    parm.append('slug', slug);
    this.api.PostWithToken(parm, 'post-question-list')
      .then(data => {
        if (data.error) {
          throw data.error;
        }
        this.utility.serverTime = data.servertime;
        data.response.forEach(el => {
          let msg = new msgobj();
          msg.id = el.id;
          msg.posted_author_id = el.post_user_id;
          msg.event_id = el.post_id;
          msg.user_name = el.reply_user.name;
          msg.user_id =  el.reply_user.id;
          msg.user_slug = el.reply_user.slug;
          msg.created_at = el.created_at;
          msg.message = el.message;
          msg.reply_list = el.reply_list;
          msg.reply_input = '';
          msg.user_img = el.reply_user.gender == 1 ? this.utility.maleimg : this.utility.femaleimg;
          msg.user_img = el.reply_user.user_profile_image.length > 0 ? el.reply_user.user_profile_image[0].image_url : msg.user_img;
          this.message_list.push(msg);
        });
        if (!this.message_list) {
          this.notfound = true;
        }
        console.log(data.response);
      })
      .catch(error => {
        this.toastr.error(error, 'Error');
      })
  }

  reply(msg: any, thread: any): void {
    let parm: URLSearchParams = new URLSearchParams();
    parm.append('parent_id', msg.id);
    parm.append('reply_msg', msg.reply_input);
    parm.append('author', msg.posted_author_id);
    parm.append('post_id', msg.post_id);
    msg.reply_input = '';
    this.api.PostWithToken(parm, 'add-reply-msg')
      .then(data => {
        if (data.error) {
          throw data.error;
        }
        thread.push(data.response.data[0]);
        this.toastr.success('Reply has been sent successfully', 'Success');
        let slug = this.route.snapshot.params['slug']
        this.chat.sendNewMsgNotification({id: msg.user_id, slug: slug, posted_author_id: this.user.uid, msg: data.response.msg, action: 'post-add-reply-msg'});

      })
      .catch(error => {
        this.toastr.error(error, 'Error');
      })

    console.log(msg);
  }

  like_dislike(post): void {
    let param: URLSearchParams = new URLSearchParams();
    param.append('uid', post.uid);
    param.append('eid', post.id);

    this.api.PostWithToken(param, 'post-like-dislike')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }
        console.log(data.response);
        if (post.login_user_like) {
          post.login_user_like = 0;
          post.like--;
        } else {
          post.login_user_like = 1;
          post.like++;
          this.chat.sendNewMsgNotification({id: post.post_id, slug: this.user.uslug, msg: data.response, action: 'post-like'});
        }
 
      })
      .catch(error => {
        console.log(error);
      })
  }
  
  countView() : void{
    if(!this.isCountView){
      let parm: URLSearchParams = new URLSearchParams();
      parm.append('slug', this.route.snapshot.params['slug']);
      this.api.PostWithToken(parm, 'increate-view').then(
        data=>{
          this.isCountView = true;
          this.event.view = data.response;
        }
      )

    }
  }

  onBack() {
    this._location.back();
  }
}
