import { Component, OnInit } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { Router } from '@angular/router'
import { CookieService } from 'ngx-cookie-service';
import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { UtilityService } from '../utility/utility.service'
import { ApicallService } from '../app-service/apicall.service';
import { UserinfoService } from '../userinfo/userinfo.service';
import { OptionDataService } from '../option-data/option-data.service';
import { NotificationService } from '../notification/notification.service';
import { ChatService } from '../chat-service/chat.service';

declare var $:any;
@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.css']
})
export class MyaccountComponent implements OnInit {

  type: string = '';
  pagetitle: string = '';

  constructor(public utility: UtilityService, public api: ApicallService, public pdata: OptionDataService, public user: UserinfoService, private router: Router, public observe: NotificationService, private flash: CookieService,public toastr: ToastsManager, public chat: ChatService) {
    this.setType(this.router.url);
    console.log(this.router.url);
  }

  ngOnInit() {

    // $('#horizontalTab').responsiveTabs({
    //     rotate: false,
    //     startCollapsed: 'accordion',
    //     collapsible: 'accordion',
    //     setHash: false,
    // });

    // $('#verticalTab').responsiveTabs({
    //     rotate: false,
    //     startCollapsed: 'accordion',
    //     collapsible: 'accordion',
    //     setHash: false,
    // });
  }

  public setType(type: string): void {
    switch (type) {
      case '/profile-setting':
        this.type = 'profile-setting';
        this.pagetitle = 'Profile Setting';
        break;
      case '/change-password':
        this.type = 'change-password'
        this.pagetitle = 'Change Password';
        break;
      case '/billing':
        this.type = 'billing';
        this.pagetitle = 'Billing';
        break;
      case '/notifications-setting':
        this.type = 'notifications-setting';
        this.pagetitle = 'Notifications Setting';
        break;
      default:
        this.type = '';
        this.pagetitle = 'Update Email Address';
    }
  }

}
