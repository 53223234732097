import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';

import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { UtilityService } from '../../utility/utility.service'
import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { ChatService } from '../../chat-service/chat.service';


@Component({
  selector: 'app-generel-setting',
  templateUrl: './generel-setting.component.html',
  styleUrls: ['./generel-setting.component.css']
})
export class GenerelSettingComponent implements OnInit {

  constructor(public chat: ChatService, public user:UserinfoService, public utility:UtilityService, public api:ApicallService, public toastr: ToastsManager, vcr: ViewContainerRef) { 
    // this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
  }

  saveData(field:string, item:string):void {
    let param:URLSearchParams = new URLSearchParams();
    let value:string = (item) ? '1' : '0';
    param.append(field.toString(), value);
    this.api.PostWithToken( param,'update-profile')
    .then(data => {
      console.log(data);
      if(data.error > 0){
        throw data.error;
      }
      this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});
      
      this.toastr.success('Setting has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }
}
