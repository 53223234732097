import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { LoginService } from './login/login.service';
import { UserinfoService } from './userinfo/userinfo.service';
import { ApicallService } from './app-service/apicall.service';
import { URLSearchParams } from '@angular/http';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private logservice:LoginService, public user:UserinfoService, public api: ApicallService){}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    
    let parm: URLSearchParams = new URLSearchParams();
    this.api.PostWithToken(parm, 'loggin-users-info').then(data => {
      if(data.response != null)
        this.user.hasFreeSubscription = data.response.setting.free_subscription == 1 ? true : false;
    });
    
    let status = false;
    if(!this.logservice.isLogin()){
      this.router.navigate(['/home']);
    }
      
    status = this.logservice.isLogin();
    if(status){
      if(!this.user.finish || !this.user.finish.getValue()){
        this.user.reloadUserInfo();
      }
    }
      return status;
  }
}
