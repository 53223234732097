import { ApicallService } from '../app-service/apicall.service';
import { URLSearchParams } from '@angular/http';

export class Userclass {
    public editprofile:boolean;
    public uname:string;
    public uemail:string;
    public ugender:string;
    public useeking:string;
    public zipcode:string;
    public ucountry:string;
    public ucountry_obj:any;
    public password:string;
    public uslug:string;
    public upet:any;
    public uschool = '';
    public upolitical:string;
    public ismutual:boolean;
  
    public ucity:string;
    public urelationstatus:string;
    public ueducation:string;
    public umarried:string;
    public uchildren:string;
    public udob:string;
    public ucareer:string;
    public uethnicity:string;
    public uspirituality:string;
    public udenomination:string;
    public uincome:string;
    public usmoke:string;
    public udrink:string;
    public ukids:string;
    public uheight:any;
    public ulanguage:any;
    public answared:string;
    public unansward:string;
  
    public aboutme:string;
    public passion:string;
    public leisuretime:string;
    public noticeaboutme:string;
    public peoplenotice:string;
    public influential:string;
    public lookinginperson:string;
    public readenjoy:string;
  
    public livewithout:any;
    public thankful:any;
    public skill:any;
    public frnddesc:any;
  
    public listenmusic:any;
    public favband:any;
    public likeread:any;
    public favbook:any;
    public movieenjoy:any;
    public favmovie:any;
    public sportplaying:any;
    public sportwatching:any;
    public tvwatching:any;
    public favtv:any;
    public likeplaying:any;
  
    public prefsmoke:string;
    public prefsmokeimt:string;
    public prefdrink:string;
    public prefdringimt:string;
    public prefethnicity:string;
    public prefethnicityimt:string;
    public prefagefrom:string;
    public prefageto:string;
    public prefageimt:string;
    public prefpartnerkid:string
    public prefmorekids:string;
    public prefreligionimt:string;
    public prefincomeimt:string;
    public prefeducationimt:string;
    public prefdistance:string;
    public prefdistanceimt:string;
  
    public imgPreview:string;
    public imgPrviewID:string
    public imgGallary:any;
    public questionanswer:any;

    public is_subscribe: boolean;
    public subscribe_option: string;
    public subscribe_date: string;
    public subscribe_diff: string;
    public subscribe_expired: boolean;

    public loadUserInfo(slug, api:ApicallService):any{
        let parm:URLSearchParams = new URLSearchParams();
        parm.append('slug',slug);
        return api.PostWithToken(parm, 'users-info');
    }
    public defaultValue(){
        this.editprofile = false;
        this.prefsmoke = '0';
        this.prefsmokeimt ='1';
        this.prefdrink ='0';
        this.prefdringimt ='1';
        this.prefethnicity ='1';
        this.prefethnicityimt ='1';
        this.prefagefrom ='30';
        this.prefageto ='35';
        this.prefageimt ='1';
        this.prefpartnerkid ='0'
        this.prefmorekids ='0';
        this.prefreligionimt ='1';
        this.prefincomeimt ='1';
        this.prefeducationimt ='1';
        this.prefdistance ='0';
        this.prefdistanceimt ='1';
        this.ulanguage = [];

        this.uschool = '';
    
        this.uname = '';
        this.uemail = '';
        this.ugender = '1';
        this.useeking = '1';
        this.zipcode = '';
        this.ucountry = '1';
        this.ucountry_obj = null;
        this.password = '';
        this.uslug = '';
        this.upolitical = '0';
    
        this.ucity = '';
        this.urelationstatus = '1';
        this.ueducation = '0';
        this.umarried = '0';
        this.uchildren = '0';
        this.udob = '';
        this.ucareer = '';
        this.uethnicity = '';
        this.uspirituality = '0';
        this.udenomination = '9';
        this.uincome = '2';
        this.usmoke = '';
        this.udrink = '';
        this.ukids = '';
        this.uheight = {'ft': '0','inch': '0'};
    
        this.aboutme = '';
        this.passion = '';
        this.leisuretime = '';
        this.noticeaboutme = '';
        this.peoplenotice = '';
        this.influential = '';
        this.lookinginperson = '';
        this.readenjoy = '';
        this.ismutual = false;
    
        this.livewithout = {'one': '','two': '','three': '','four': '','five': ''};
        this.thankful = {'one': '','two': '','three': ''};
        this.skill = {'one': '','two': '','three': ''};
        this.frnddesc = {'one': '','two': '','three': '','four': ''};
    
        this.listenmusic = {'one': '','two': '','three': ''};
        this.favband = {'one': '','two': '','three': ''};
        this.likeread = {'one': '','two': '','three': ''};
        this.favbook = {'one': '','two': '','three': ''};
        this.movieenjoy = {'one': '','two': '','three': ''};
        this.favmovie = {'one': '','two': '','three': ''};
        this.sportplaying = [];
        this.sportwatching = {'one': '','two': '','three': ''};
        this.tvwatching = {'one': '','two': '','three': ''};
        this.favtv = {'one': '','two': '','three': ''};
        this.likeplaying = {'one': '','two': '','three': ''};;
    
        this.imgPreview = '';
        this.imgPrviewID = '';
        this.imgGallary = [];
      }
    public loadData(data, utility:any):void {
        this.defaultValue();
        this.uname = data.name;
        this.uemail = data.email;
        this.uslug = data.slug;
        this.ugender = data.gender;
        this.imgPreview = data.gender == 1 ? utility.maleimg : utility.femaleimg;
        this.useeking = data.seeking;
        this.zipcode = data.zip_code;
        this.ucountry = data.country;
        this.ucountry_obj = data.country_detail;
        this.ucity = data.city;
        this.urelationstatus = data.relationship;
        this.ueducation = data.education;
        this.umarried = data.married;
        this.uchildren = data.children;
        this.udob = data.dob;
        this.ucareer = data.career;
        this.uethnicity = data.ethnicity;
        this.uspirituality = data.spirituality;
        this.udenomination = data.denomination;
        this.uincome = data.income;
        this.usmoke = data.smoke;
        this.udrink = data.drink;
        this.ukids = data.new_family;
        let tall = data.tall.split('/');
        this.uheight.ft = tall[0];
        this.uheight.inch = tall[1];
        this.ulanguage = (data.language != null) ? data.language.split('/') : null;
        this.answared = data.answered;
        this.unansward = data.unanswered;
        this.uschool = data.school;
        this.upolitical = data.political;
        this.ismutual = data.mutual_favorite.length > 0 ? true : false;
      
        this.aboutme = data.details_about_me;
        this.passion = data.passionate;
        this.leisuretime = data.leisure_time;
        this.noticeaboutme = data.details_notice_about_me;
        this.peoplenotice = data.details_first_thing_notice_about_me;
        this.influential = data.details_most_influential_person;
        this.lookinginperson = data.details_looking_for_in_person;
        this.readenjoy = data.details_read_and_enjoyed;
        

        this.upet = data.pet.split('/');
      
        if(data.live_without != null){
            let livewithout = data.live_without.split('/')
            this.livewithout.one = livewithout[0];
            this.livewithout.two = livewithout[1];
            this.livewithout.three = livewithout[2];
            this.livewithout.four = livewithout[3];
            this.livewithout.five = livewithout[4];
        }
        
        if(data.three_thankfuls != null){
            let thkfll = data.three_thankfuls.split('/');
            this.thankful.one = thkfll[0];
            this.thankful.two = thkfll[1];
            this.thankful.three = thkfll[2];
        }
        
        if(data.life_skills != null){
            let skl = data.life_skills.split('/');
            this.skill.one = skl[0];
            this.skill.two = skl[1];
            this.skill.three = skl[2];
        }
        
        if(data.friend_describe != null){
            let frnd = data.friend_describe.split('/');
            this.frnddesc.one = frnd[0];
            this.frnddesc.two = frnd[1];
            this.frnddesc.three = frnd[2];
            this.frnddesc.four = frnd[3];
        }
        
        if(data.details_listening_to != null){
            let lmc = data.details_listening_to.split('/');
            this.listenmusic.one = lmc[0];
            this.listenmusic.two = lmc[1];
            this.listenmusic.three = lmc[2];
        }
        
        if(data.details_listening_to_band != null){
            let fband = data.details_listening_to_band.split('/');
            this.favband.one = fband[0];
            this.favband.two = fband[1];
            this.favband.three = fband[2];
        }
        
        if(data.details_reading_books != null){
            let lread = data.details_reading_books.split('/');
            this.likeread.one = lread[0];
            this.likeread.two = lread[1];
            this.likeread.three = lread[2];
        }
        
        if(data.details_reading_books_read != null){
            let fbook = data.details_reading_books_read.split('/');
            this.favbook.one = fbook[0];
            this.favbook.two = fbook[1];
            this.favbook.three = fbook[2];
        }
        
        if(data.details_movies != null){
            let mvenj = data.details_movies.split('/');
            this.movieenjoy.one = mvenj[0];
            this.movieenjoy.two = mvenj[1];
            this.movieenjoy.three = mvenj[2];
        }
        
        if(data.details_movies_watched != null){
            let mwatch = data.details_movies_watched.split('/');
            this.favmovie.one = mwatch[0];
            this.favmovie.two = mwatch[1];
            this.favmovie.three = mwatch[2];
        }
        
        if(data.details_i_like_playing != null){
            let sportplaying = data.details_i_like_playing.split('/');
        }
        
        if(data.details_i_like_playing_watch != null){
            let swatching = data.details_i_like_playing_watch.split('/');
            this.sportwatching.one = swatching[0];
            this.sportwatching.two = swatching[1];
            this.sportwatching.three = swatching[2];
        }
        
        if(data.details_i_like_watching != null){
            let wtv = data.details_i_like_watching.split('/');
            this.tvwatching.one = wtv[0];
            this.tvwatching.two = wtv[1];
            this.tvwatching.three = wtv[2];
        }
        
        if(data.details_i_like_watched != null){
            let ftv = data.details_i_like_watched.split('/');
            this.favtv.one = ftv[0];
            this.favtv.two = ftv[1];
            this.favtv.three = ftv[2];
        }
        
        if(data.details_listening_to_like_to_play != null){
            let lkpl = data.details_listening_to_like_to_play.split('/');
            this.likeplaying.one = lkpl[0];
            this.likeplaying.two = lkpl[1];
            this.likeplaying.three = lkpl[2];
        }
        
        this.prefsmoke = data.user_preference.smoke;
        this.prefsmokeimt = data.user_preference.smoke_important;
        this.prefdrink = data.user_preference.drink;
        this.prefdringimt = data.user_preference.drink_important;
        this.prefethnicity = data.user_preference.area;
        this.prefethnicityimt = data.user_preference.area_important;
        this.prefagefrom = data.user_preference.from_age;
        this.prefageto = data.user_preference.to_age;
        this.prefageimt = data.user_preference.important_age;
        this.prefpartnerkid = data.user_preference.partner_children;
        this.prefmorekids = data.user_preference.own_children;
        this.prefreligionimt = data.user_preference.religion;
        this.prefincomeimt = data.user_preference.income;
        this.prefeducationimt = data.user_preference.education;
        this.prefdistance = data.user_preference.distance;
        this.prefdistanceimt = data.user_preference.q_distance;
      
        this.questionanswer = data.questitonanswer;
      
        let imgOBJ = ()=>{};
        this.imgGallary = []; 
        data.user_image.forEach(element => {
            if(element.image_status == 'active'){
                this.imgPreview = element.image_url;
                this.imgPrviewID = element.image_id;
            }else{
                let img = new imgOBJ();
                img.id = element.image_id;
                img.url = element.image_url;
                this.imgGallary.push(img);
            }
        });

        this.is_subscribe = data.is_subscribe;
        this.subscribe_option = data.subscribe_option;
        this.subscribe_date = data.subscribe_date;
        this.subscribe_diff = data.subscribe_diff;
        this.subscribe_expired = data.subscribe_expired;

    }

}
