import { Router } from '@angular/router';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';

import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { UtilityService } from '../../utility/utility.service';

declare var $: any;

@Component({
  selector: 'app-event-login-user-list',
  templateUrl: './event-login-user-list.component.html',
  styleUrls: ['./event-login-user-list.component.css']
})
export class EventLoginUserListComponent implements OnInit {

  events: any = [];
  notfound: boolean = false;
  page: number = 1;
  loader: boolean = false;
  index_loader: number = -1;
  showMoreBtn: boolean = true;
  pagination: any = [];
  like_users: any = [];
  activePage:number = 1; 
  itemsPerPage = 16;
  totalPages : any; 

  constructor(public api: ApicallService, public user: UserinfoService, public pdata: OptionDataService, public toastr: ToastsManager, private vcr: ViewContainerRef, private u: UtilityService, private router: Router) {
    // this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.user.listen.subscribe((data) => { if (data) this.initAfterService() });
  }

  initAfterService() {
    this.loadPost({});
  }

  displayActivePage(activePageNumber:number){  
    this.activePage = activePageNumber  
    this.page  = activePageNumber  
    this.loadPost({});
  }  

  loadPost(search: any): void {
    this.loader = true;
    let param: URLSearchParams = new URLSearchParams();
    let eventobj = function () { };

    if (search.location) {
      param.append('location', search.location);
    }
    if (search.date) {
      param.append('date', this.u.toMySqlDateFormat(search.date));
    }

    param.append('page', this.page.toString());
    this.api.PostWithToken(param, 'login-user-event-list')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }

        this.pagination = data.pagination;
        this.totalPages = data.pagination.length;
        //console.log(data.response)
        data.response.forEach(el => {
          let tmp = new eventobj()
          tmp.post_title = el.title.substring(0, 50);
          tmp.description = el.post_details.substring(0, 100);
          tmp.img = (el.featureimage.length > 0) ? el.featureimage[0].image_url : 'assets/img1.png';
          tmp.posted_on = el.posted_date;
          tmp.slug = el.slug;
          tmp.city = el.city;
          tmp.country = el.country.cname;
          tmp.like = el.like;
          tmp.login_user_like = el.user_has_like.length;
          tmp.user_id = el.user_id;
          tmp.event_id = el.event_id;
          tmp.join = el.join_user.length;
          tmp.question = el.event_question_count.length;
          this.events.push(tmp);
        });
        this.loader = false;

        if (!this.events.length) {
          this.notfound = true;

        }

        if (this.pagination.length > this.page) {
          //this.page++;
        } else {
          this.showMoreBtn = false
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  deleteEvent(slug: string, index): void {
    this.index_loader = index;
    let param: URLSearchParams = new URLSearchParams();

    param.append('slug', slug);
    this.api.PostWithToken(param, 'remove-event')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }
        this.events.splice(index, 1);;
        this.toastr.success('Event has been removed successfully.', 'Success');
        this.index_loader = -1;
      })
      .catch(error => {
        console.log(error);
      })
  }

  search(qur): void {
    this.events = [];
    this.showMoreBtn = true;
    this.loadPost(qur);
  }

  userLikeList(likes): void {
    likes.forEach(el => {
      let likeobj = new function () { }
      likeobj.slug = el.user.slug;
      likeobj.name = el.user.name;
      likeobj.img = el.user.gender == 1 ? this.u.maleimg : this.u.femaleimg;
      likeobj.img = el.user.user_profile_image.length > 0 ? el.user.user_profile_image[0].image_url : likeobj.img;
      this.like_users.push(likeobj);
    });
    console.log(this.like_users)
    $('#profile-user').modal('show');
  }

  showUserProfile(slug): void {
    $('#profile-user').modal('hide');
    this.router.navigate(['/profile', slug]);
  }
}
