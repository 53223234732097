import { Component, NgZone, Renderer2, ViewContainerRef } from '@angular/core';
import { LoginService } from './login/login.service'
import { ToastsManager } from 'ng2-toastr';
import {
  Router,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError, provideRoutes
} from '@angular/router'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Woovibe';
  loading: boolean = true;
  public viewContainerRef: ViewContainerRef;
  constructor(public login: LoginService, private router: Router, private ngZone: NgZone, private renderer: Renderer2, public toastr: ToastsManager, viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
    
    this.toastr.setRootViewContainerRef(viewContainerRef);
    router.events.subscribe((event: RouterEvent) => {
      this._navigationInterceptor(event)
      window.scroll(0, 0);
    });
  }

  private _navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
      this.renderer.removeClass(document.body, 'remove-img');
    }
    if (event instanceof NavigationEnd) {
      this._hideSpinner()
    }
    if (event instanceof NavigationCancel) {
      this._hideSpinner()
    }
    if (event instanceof NavigationError) {
      this._hideSpinner()
    }
  }

  private _hideSpinner(): void {
    this.renderer.addClass(document.body, 'add-img');
    this.renderer.addClass(document.body, this.router.url.replace("/",""));
    this.loading = false;
  }





}
