import { Component, OnInit, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastsManager } from 'ng2-toastr/ng2-toastr';
import { URLSearchParams } from '@angular/http';

import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { UtilityService } from '../../utility/utility.service';
import { ChatService } from '../../chat-service/chat.service';

@Component({
  selector: 'app-event-reply',
  templateUrl: './event-reply.component.html',
  styleUrls: ['./event-reply.component.css']
})
export class EventReplyComponent implements OnInit {
  message_list: any = [];
  notfound: boolean = false;
  constructor(public chat: ChatService, public toastr: ToastsManager, vcr: ViewContainerRef, private route: ActivatedRoute, private api: ApicallService, public user: UserinfoService, public utility: UtilityService) { }

  ngOnInit() {
    this.user.listen.subscribe((data) => { if (data) this.initAfterService() });
  }

  initAfterService(): void {
    this.getAllReplay()
  }

  getAllReplay(): void {
    let msgobj = function () { };
    let slug = this.route.snapshot.params['slug']
    let parm: URLSearchParams = new URLSearchParams();
    parm.append('slug', slug);
    this.api.PostWithToken(parm, 'event-question-list')
      .then(data => {
        if (data.error) {
          throw data.error;
        }
        this.utility.serverTime = data.servertime;
        data.response.forEach(el => {
          let msg = new msgobj();
          msg.id = el.id;
          msg.posted_author_id = el.event_user_id;
          msg.event_id = el.event_id;
          msg.user_name = el.reply_user.name;
          msg.user_id =  el.reply_user.id;
          msg.user_slug = el.reply_user.slug;
          msg.created_at = el.created_at;
          msg.message = el.message;
          msg.reply_list = el.reply_list;
          msg.reply_input = '';
          msg.user_img = el.reply_user.gender == 1 ? this.utility.maleimg : this.utility.femaleimg;
          msg.user_img = el.reply_user.user_profile_image.length > 0 ? el.reply_user.user_profile_image[0].image_url : msg.user_img;
          this.message_list.push(msg);
        });
        if (!this.message_list) {
          this.notfound = true;
        }
        console.log(data.response);
      })
      .catch(error => {
        this.toastr.error(error, 'Error');
      })
  }

  reply(msg: any, thread: any): void {
    let parm: URLSearchParams = new URLSearchParams();
    parm.append('parent_id', msg.id);
    parm.append('reply_msg', msg.reply_input);
    parm.append('author', msg.posted_author_id);
    parm.append('event_id', msg.event_id);
    msg.reply_input = '';
    this.api.PostWithToken(parm, 'add-reply-msg')
      .then(data => {
        if (data.error) {
          throw data.error;
        }
        thread.push(data.response.data[0]);
        this.toastr.success('Reply has been sent successfully', 'Success');
        let slug = this.route.snapshot.params['slug']
        this.chat.sendNewMsgNotification({id: msg.user_id, slug: slug, posted_author_id: this.user.uid, msg: data.response.msg, action: 'event-add-reply-msg'});

      })
      .catch(error => {
        this.toastr.error(error, 'Error');
      })

    console.log(msg);
  }

  getReplyUserImage(msg): string {
    let img: string = msg.reply_user.gender == 1 ? this.utility.maleimg : this.utility.femaleimg;
    img = msg.reply_user.user_profile_image.length > 0 ? msg.reply_user.user_profile_image[0].image_url : img;
    return img
  }
}
