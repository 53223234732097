import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';

import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { UtilityService } from '../../utility/utility.service'
import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { ChatService } from '../../chat-service/chat.service';

declare var $:any;

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.css']
})
export class IncomeComponent implements OnInit {

  Object = Object;
  income = '0';
  income_impt = '1';
  education = '0';
  education_impt = '1';
  constructor(public chat: ChatService, public utility:UtilityService, public api:ApicallService, public pdata:OptionDataService, public user:UserinfoService, public toastr: ToastsManager, public vcr: ViewContainerRef) { 
    // this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.user.listen.subscribe(data=> this.initAfterService());
  }

  initAfterService(){
    if(this.user.prefincomeimt == null)
      this.user.prefincomeimt = '1';
    if(this.user.prefeducationimt == null)
      this.user.prefeducationimt = '1';
    console.log(this.user);
  }

  saveProfile(field:string, value:string):void {
    console.log(this.education);
    let param:URLSearchParams = new URLSearchParams();
    param.append(field, value);

    this.api.PostWithToken( param,'update-profile')
    .then(data => {
      console.log(data);
      if(data.error > 0)
        throw data.errmsg;

        switch(field){
          case 'income' :
            this.user.uincome = this.income;
            break;
          case 'education' :
            this.user.ueducation = this.education;
            break;
        }
        this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});

        $("#inco").modal('hide');
        $("#stu").modal('hide');
        this.toastr.success('Profile has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

  savePreference(field:string, value:string):void {
    let param:URLSearchParams = new URLSearchParams();
    param.append(field, value);
    
    this.api.PostWithToken( param,'update-profile-preference')
    .then(data => {
      //console.log(data);
      if(data.error > 0)
        throw data.errmsg;

        switch(field){
          case 'income' :
            this.user.prefincomeimt = this.income_impt;
            break;
          case 'education' :
            this.user.prefeducationimt = this.education_impt;
            break;
        }
        
        $("#income-important").modal('hide');
        $("#match-important").modal('hide');
        this.toastr.success('Income information has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }
}
