import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { Router } from '@angular/router';

import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { UtilityService } from '../utility/utility.service'
import { ApicallService } from '../app-service/apicall.service';
import { OptionDataService } from '../option-data/option-data.service';
import { UserinfoService } from '../userinfo/userinfo.service';
import { ChatService } from '../chat-service/chat.service';

declare var $:any;

@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.css']
})
export class PreferenceComponent implements OnInit {

  Object = Object;
  ageindex:any = [];
  age:any = {
    start: '26',
    end:'40',
    impt:'1'
  };
  matchethnicity:string = '1';
  religionimt:string = '1';
  educationimt:string = '1';
  incomeimt:string = '1';
  partner_children:string = '0';
  distance:string = '0';
  distanceimt:string = "1";
  smokeimt:string = '1';
  smoke:string = 'Never';
  drink:string = 'Never';
  drinkimt:string = '1';
  myethnicity:string = '';
  prefethnicity:string = '';
  ethnicityimpt:string = '';
  language:any = [];
  agearr: any = [];
  ageimpt: string = '';
  dob: string;
  kinno = 0;
  spirituality:string = '';
  impt:string = '';
  income = '0';
  income_impt = '1';
  education = '0';
  education_impt = '1';
  usmoke:string = '0';
  udrink:string = '0';
  cho_smoke:string = '0';
  cho_drink:string = '0';
  smokeimpt:string = '1';
  drinkimpt:string = '1';

  countrylist: any = [];
  country: string = '';
  zipcode: string = '';
  prefdistance: string = '0';
  distanceimpt: string = '1';
  countryname: string = '';

  constructor(public chat: ChatService, public pdata:OptionDataService, public utility:UtilityService, public api:ApicallService, private router: Router, public user:UserinfoService, public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.dob = this.pdata.defultEndDate;
   }

  ngOnInit() {
    for(let i = 16; i<=80; i++){
      this.ageindex.push(i);
    }
    for (let i = 18; i <= 90; i++) {
      this.agearr.push(i);
    }
    this.loadCountry();
    //this.loadDatePicker();
    this.user.listen.subscribe(data=> this.initAfterService());
  }

  initAfterService():void {
    if(this.user.ulanguage != null )
      this.language = this.user.ulanguage;

      if (!this.user.prefagefrom)
      this.user.prefagefrom = '18';

      if (!this.user.prefageto)
      this.user.prefageto = '70';

      if(this.user.prefpartnerkid === null){
        this.user.prefpartnerkid = '0';
      }  
      if(this.user.prefreligionimt == null)
      this.user.prefreligionimt = '1';

      if(this.user.prefincomeimt == null)
      this.user.prefincomeimt = '1';
      
      if(this.user.prefeducationimt == null)
      this.user.prefeducationimt = '1';

      if(this.user.prefsmoke == null)
      this.user.prefsmoke = '0';  
      
      if(this.user.prefdrink == null)
      this.user.prefdrink = '0';

      this.country = this.user.ucountry;
      this.zipcode = this.user.zipcode;
      if (this.user.prefdistance == null)
        this.user.prefdistance = '0';
      if (this.user.prefdistanceimt == null)
        this.user.prefdistanceimt = '1';
  
      this.prefdistance = this.user.prefdistance;

    this.dob = this.utility.fromMySqlDateFormat(this.user.udob);  
  }

  savePreferenceOld():void {
    let param:URLSearchParams = new URLSearchParams();

    param.append('from_age', this.age.start);
    param.append('to_age', this.age.end);
    param.append('important_age', this.age.impt);
    param.append('area', this.matchethnicity);
    param.append('religion', this.religionimt);
    param.append('education', this.educationimt);
    param.append('income', this.incomeimt);
    param.append('partner_children', this.partner_children);
    //param.append('own_children', this.partner_children);
    param.append('distance', this.distance);
    param.append('q_distance', this.distanceimt);
    param.append('smoke', this.smoke);
    param.append('smoke_important', this.smokeimt);
    param.append('drink', this.drink);
    param.append('drink_important', this.drinkimt);

    this.api.PostWithToken( param,'profile-preference')
    .then(data => {
      console.log(data);
      if(data.error > 0){
        throw data.errmsg;
      }
        
      this.sendEmailToAdmin();
      this.user.reloadUserInfo();
      this.router.navigate(['/dashboard']);
    })
    .catch(error =>{
      console.log(error);
    })

  }

  sendEmailToAdmin(){
    let param:URLSearchParams = new URLSearchParams();
    this.api.PostWithToken( param,'new-user-notication-email');
  }

  saveData(field:string, item:string):void {
    let param:URLSearchParams = new URLSearchParams();
    let value:string = (item) ? '1' : '0';
    param.append(field.toString(), value);
    this.api.PostWithToken( param,'update-profile')
    .then(data => {
      console.log(data);
      if(data.error > 0){
        throw data.error;
      }
      this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});
      
      this.toastr.success('Setting has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

  saveProfile():void {
    let param:URLSearchParams = new URLSearchParams();
    this.myethnicity = $('#uethnicity').val();
    param.append('ethnicity', this.myethnicity);

    this.api.PostWithToken( param,'update-profile')
    .then(data => {
      console.log(data);
      if(data.error > 0){
        throw data.errmsg;
      }
        
      this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});

      this.user.uethnicity = this.myethnicity;
      $("#ethnicity").modal('hide');
      this.toastr.success('Ethnicity has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

  savePreferenceNew():void {
    console.log(this.user.user);
    //console.log(this.user);
    let param:URLSearchParams = new URLSearchParams();
    param.append('area', $('#prefethnicity').val());
    param.append('area_important', $('#prefethnicityimt').val());
    
    this.api.PostWithToken( param,'update-profile-preference')
    .then(data => {
      //console.log(data);
      if(data.error > 0)
        throw data.errmsg;

        
        this.user.prefethnicity = $('#prefethnicity').val();
        this.prefethnicity = $('#prefethnicity').val();
      
        this.user.prefethnicityimt = $('#prefethnicityimt').val();
          

        //console.log(this.user.user.id);
        $("#preference-ethnicity").modal('hide');
        this.toastr.success('Ethnicity has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

  saveProfileLanguage():void {
    let param:URLSearchParams = new URLSearchParams();
    let lng:string = this.utility.arrjoin(this.language,'/');
    param.append('language', lng);

    this.api.PostWithToken( param,'update-profile')
    .then(data => {
      console.log(data);
      if(data.error > 0){
        throw data.errmsg;
      }
        
      this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});

      this.user.ulanguage = this.language.slice();
      $("#language").modal('hide');
      this.toastr.success('Language has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

  getLan(index, lang, status):void {
    console.log(status);
    if(status)
      this.language.push(lang);
    else
      this.language.splice(index,1);

    console.log(this.language);
  }

  isSelected(lang):boolean {
    if(this.user.ulanguage == null)
      return false;
    return this.utility.inArray(lang, this.user.ulanguage);
  }



  updateAgePreference(): void {
    let param: URLSearchParams = new URLSearchParams();
    this.ageimpt = $('#prefageimt').val();
    param.append('from_age', this.user.prefagefrom);
    param.append('to_age', this.user.prefageto);
    param.append('important_age', this.ageimpt);

    this.api.PostWithToken(param, 'update-profile-preference')
      .then(data => {

        if (data.error > 0)
          throw data.errmsg;
          this.user.prefageimt = this.ageimpt;
        $("#prefage").modal('hide');
        this.toastr.success('Age has been updated successfully. ', 'Success');
      })
      .catch(error => {
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })
  }


  loadDatePicker(): void {
    let self = this;
    var date_input = $('input[name="date"]');
    let options = {
      format: 'mm/dd/yyyy',
      todayHighlight: true,
      autoclose: true,
      endDate: self.pdata.defultEndDate
    };
    date_input.datepicker(options);
  }

  saveDateOfBirth(): void {
    let param: URLSearchParams = new URLSearchParams();
    this.dob = $('input[name="date"]').val();
    if (this.dob == '') {
      this.dob = this.utility.fromMySqlDateFormat(this.user.udob);
      $('input[name="date"]').val(this.dob);
    }
    param.append('dob', this.utility.toMySqlDateFormat(this.dob));
    this.api.PostWithToken(param, 'profile-setUp')
      .then(data => {
        console.log(data);
        if (data.error > 0)
          throw data.errmsg;

        this.user.prefageimt = this.ageimpt;
        $("#predob").modal('hide');
        this.toastr.success('Date of birth has been updated successfully. ', 'Success');
      })
      .catch(error => {
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })

  }

  saveProfileChild():void {
    let param:URLSearchParams = new URLSearchParams();
    param.append('children', this.kinno.toString());

    this.api.PostWithToken( param,'update-profile')
    .then(data => {
      console.log(data);
      if(data.error > 0)
        throw data.errmsg;

        this.user.uchildren = this.kinno.toString();
        this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});
        $("#uchild").modal('hide');
        this.toastr.success('Profile has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

  savePreferenceChild():void {
    let param:URLSearchParams = new URLSearchParams();
    param.append('partner_children', $('#prefpartnerkid').val());
    param.append('own_children', $('#prefmorekids').val());
    
    this.api.PostWithToken( param,'update-profile-preference')
    .then(data => {
      //console.log(data);
      if(data.error > 0)
        throw data.errmsg;
        this.user.prefpartnerkid = $('#prefpartnerkid').val()
        this.user.prefmorekids = $('#prefmorekids').val()
        $("#partner_kid").modal('hide');
        this.toastr.success('Children information has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

  saveProfileReligion():void {
    let param:URLSearchParams = new URLSearchParams();
    param.append('spirituality', this.spirituality);

    this.api.PostWithToken( param,'update-profile')
    .then(data => {
      console.log(data);
      if(data.error > 0)
        throw data.errmsg;

      this.user.uspirituality = this.spirituality;
      this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});

      $("#reli").modal('hide');
      this.toastr.success('Profile has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

  savePreferenceReligion():void {
    let param:URLSearchParams = new URLSearchParams();
    param.append('religion', this.impt);
    
    this.api.PostWithToken( param,'update-profile-preference')
    .then(data => {
      if(data.error > 0)
        throw data.errmsg;

        this.user.prefreligionimt = this.impt;
        $("#importantReligion").modal('hide');
        this.toastr.success('Religion and Spirituality has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

  saveProfileIncome():void {
    console.log(this.education);
    let param:URLSearchParams = new URLSearchParams();
    this.income = $('#uincome').val()
    this.education = $('#ueducation').val()
    param.append('income', this.income);
    param.append('education', this.education);

    this.api.PostWithToken( param,'update-profile')
    .then(data => {
      console.log(data);
      if(data.error > 0)
        throw data.errmsg;
        
        this.user.uincome = this.income;           
        this.user.ueducation = this.education;
        this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});

        $("#inco").modal('hide');
        this.toastr.success('Profile has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

  savePreferenceIncome():void {
    let param:URLSearchParams = new URLSearchParams();
    this.income_impt  = $('#prefincomeimt').val();
    this.education_impt  = $('#prefeducationimt').val();
    param.append('income', this.income_impt);
    param.append('education', this.education_impt);
    
    this.api.PostWithToken( param,'update-profile-preference')
    .then(data => {
      //console.log(data);
      if(data.error > 0)
        throw data.errmsg;

        this.user.prefincomeimt = this.income_impt;        
        this.user.prefeducationimt = this.education_impt;
        
        $("#income-important").modal('hide');

        this.toastr.success('Income information has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

  saveProfileSmoke():void {
    let param:URLSearchParams = new URLSearchParams();
    param.append('smoke', this.usmoke);
    param.append('drink', this.udrink);

    this.api.PostWithToken( param,'update-profile')
    .then(data => {
      console.log(data);
      if(data.error > 0)
        throw data.errmsg;

      this.user.usmoke = this.usmoke;
      this.user.udrink = this.udrink;
         
      this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});

      $("#smok").modal('hide');
      this.toastr.success('Profile has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

  savePreferenceSmoke():void {
    
    let param:URLSearchParams = new URLSearchParams();
    param.append('smoke', this.cho_smoke);
    param.append('drink', this.cho_drink);
    param.append('smoke_important', this.smokeimpt);
    param.append('drink_important', this.drinkimpt);
    
    this.api.PostWithToken( param,'update-profile-preference')
    .then(data => {
      if(data.error > 0)
        throw data.errmsg;

        
        this.user.prefsmoke = this.cho_smoke;            
        this.user.prefdrink = this.cho_drink;            
        this.user.prefsmokeimt = this.smokeimpt;            
        this.user.prefdringimt = this.drinkimpt;
           
        $("#cho-smok").modal('hide');
        this.toastr.success('Your preference infomration has been updated successfully. ', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

  loadCountry(): void {
    let param: URLSearchParams = new URLSearchParams();
    this.api.PostWithToken(param, 'country-list')
      .then(data => {
        if (data.error > 0)
          throw data.errmsg;
        this.countrylist = data;
      })
      .catch(error => {
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })
  }

  saveProfileDis(): void {
    let param: URLSearchParams = new URLSearchParams();
    param.append('zip_code', this.zipcode);
    param.append('country', this.country);

    this.api.PostWithToken(param, 'update-profile')
      .then(data => {
        console.log(data);
        if (data.error > 0)
          throw data.errmsg;

            this.user.zipcode = this.zipcode;
           
            this.user.ucountry = this.country;
            this.user.ucountry_obj.cname = this.countryname;
           
        this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});

        $("#postal").modal('hide');
        this.toastr.success('Profile has been updated successfully. ', 'Success');
      })
      .catch(error => {
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })
  }

  getCountryName(): void {
    this.countrylist.forEach(item => {
      if (item.code == this.country) {
        this.countryname = item.cname;
      }
    });
  }

  savePreferenceDis(): void {
    let param: URLSearchParams = new URLSearchParams();
    param.append('distance',  this.prefdistance);
    param.append('q_distance',  this.distanceimpt);
    this.api.PostWithToken(param, 'update-profile-preference')
      .then(data => {
        if (data.error > 0)
          throw data.errmsg;

            this.user.prefdistance = this.prefdistance;
           
            this.user.prefdistanceimt = this.distanceimpt;
           

        $("#locationDis").modal('hide');
        this.toastr.success('Distance information has been updated successfully. ', 'Success');
      })
      .catch(error => {
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })
  }

}
