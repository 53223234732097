import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';


import { UserinfoService } from '../../userinfo/userinfo.service';
import { UtilityService } from '../../utility/utility.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { Userclass } from '../../userinfo/userclass';

@Component({
  selector: 'profile-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.css']
})
export class PhotoComponent implements OnInit {

  @Input() uclass: Userclass = null;
  ownprofile:boolean = true;
  constructor(public user:UserinfoService, public utility:UtilityService, public optdata:OptionDataService, private router:Router, private flash:CookieService) { }

  ngOnInit() {
    this.user.listen.subscribe((data)=> {if(data) this.initAfterService()});
  }

  initAfterService():void {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.uclass.currentValue){
      this.ownprofile = false;
      this.user = <any>this.uclass;
    } 
  }

  redirectToPhotoEdit():void {
    console.log('test');
    this.flash.set( 'pedit', 'photo', (0.5/1440));
    this.router.navigate(['/profile-edit']);
  }
}
