import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { ToastsManager } from 'ng2-toastr/ng2-toastr';
import { CookieService } from 'ngx-cookie-service';

import { UserinfoService } from '../../userinfo/userinfo.service';
import { UtilityService } from '../../utility/utility.service';
import { OptionDataService } from '../../option-data/option-data.service';
import { ApicallService } from '../../app-service/apicall.service';
import { ChatService } from '../../chat-service/chat.service';

declare var $: any;

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.css']
})
export class ProfileEditComponent implements OnInit {
  Object = Object;
  descriptionPanel:boolean = true;
  photoPanel:boolean = false;
  ansquePanel:boolean = false;
  preview:string = '';
  profile_loader:boolean = false;
  loader:string = 'assets/img-loader.svg';
  cityEdit:boolean = false;
  heightEdit:boolean = false;
  ntfMsg:any = null;
  city:string = '';
  uname:string = '';
  uweight:string = '';
  btnindex:number = -1;
  availabilityofcar:string = '';
  housingcondition:string = '';
  is_onilne:string = '';
  udob:string = '';
  feet:string = '';
  inches:string = '';
  ucountry:string = '';
  utheimportantthing:string = '';
  aboutme:string = '';
  ueducation:string = '';
  uincome:string = '';

  ubody_type:string = '';
  ueye_color:string = '';
  upiercing:string = '';
  ueducation_institution:string = '';
  ufield_of_activity:string = '';
  uyear_of_release:string = '';
  upost:string = '';
  uorientation:string = '';

  self_portrait_title:string = '';
  self_portrait_label:string = '';
  self_portrait_key:string = '';
  self_portrait_oldvalue:string = ''; 

  imglist:any = [];
  uimlookingfor:any = [];
  uinterests:any = [];
  country:any = [];
  savetempimage:boolean = false;
  loaderimg:boolean = false;
  loaderimggan:boolean = false;
  loaderimgother:boolean = false;
  loaderimgotherself:boolean = false;
  self_portrait_error:boolean = false;

  urelationstatus:string = '1';
  relationstatus:string = '1';
  marriedno:string = '0';
  childno:string = '0';
  ethnicity:string = '1';
  spirituality:string = '1';
  education:string = '1';
  occupation:string ='';
  income:string = '2';
  upet:string = '0';
  kidstatus:string = '3';
  drink:string = "Never";
  smoke:string = 'Never';
  usmoke:string = 'Never';
  udrink:string = 'Never';

  tall:any = {
    ft:0,
    inch:0,
    cm:0
  } 

  passionate:string = '';
  leisure:string = '';
  thankful:any = {'one': '','two': '','three': ''};
  validationError:string = '';
  
  constructor(public chat: ChatService, public user:UserinfoService, public utility:UtilityService, public optdata:OptionDataService,public api:ApicallService, public toastr: ToastsManager, vcr: ViewContainerRef, private flash:CookieService,public pdata:OptionDataService) { 
    // this.toastr.setRootViewContainerRef(vcr);
    this.ntfMsg = this.optdata.notification;
    if(this.flash.get('pedit')!=''){
      if(this.flash.get('pedit') == 'photo'){
        this.toggle(false, true, false);
      }
      if(this.flash.get('pedit') == 'desc'){
        this.toggle(true, false, false);
      }
      if(this.flash.get('pedit') == 'question'){
        this.toggle(false, false, true);
      }
    }
  }

  ngOnInit() {
    this.user.listen.subscribe((data)=> {if(data) this.initAfterService()});
    this.loadCountry();
    $('.js-example-basic-multiple').select2();
  }

  loadCountry():void {
    this.api.PostWithOutToken( new URLSearchParams(),'country-list')
    .then(data => {
      this.country = data
    })
    .catch(error =>{
      console.log(error);
    })
  }

  initAfterService():void {
    this.preview = '/profile/' + this.user.uslug;
    this.tall.ft = this.user.uheight.ft;
    this.tall.inch = this.user.uheight.inch;
    this.city = this.user.ucity;
    this.uname = this.user.uname;
    this.is_onilne = this.user.uis_online;
    this.uweight = this.user.uweight;
    this.availabilityofcar = this.user.availabilityofcar;
    this.ueducation = this.user.ueducation;
    this.housingcondition = this.user.housingcondition;
    this.uincome = this.user.uincome;
    this.urelationstatus = this.user.urelationstatus;
    this.udrink = this.user.udrink;
    this.usmoke = this.user.usmoke;
    this.upet = this.user.upet;
    this.udob = this.user.udob;
    this.uimlookingfor = this.user.uimlookingfor;
    this.aboutme = this.user.aboutme;
    this.uinterests = this.user.uinterests;
    this.utheimportantthing = this.user.utheimportantthing;
    this.ucountry = this.user.ucountry;
    this.feet = this.user.uheight.ft;
    this.inches = this.user.uheight.inch;

    this.ubody_type  = this.user.ubody_type;
    this.ueye_color  = this.user.ueye_color;
    this.upiercing  = this.user.upiercing;
    this.ueducation_institution  = this.user.ueducation_institution;
    this.ufield_of_activity  = this.user.ufield_of_activity;
    this.uyear_of_release  = this.user.uyear_of_release;
    this.upost  = this.user.upost;
    this.uorientation  = this.user.uorientation;

    if(this.user.ucity == '')
      this.city = 'put yout city name';
    this.ftToCm();
  }

  toggle(desc:boolean, photo:boolean, ansq:boolean):void{
    this.descriptionPanel = desc;
    this.photoPanel = photo;
    this.ansquePanel = ansq
  }

  saveProfileUpdate(isValid:boolean):any {
    if(!isValid){
      this.validationError = 'Some fields are not filled up properly. Please go above and fill all fields...';
      return false;
    }
    this.loaderimggan = true;
    let param:URLSearchParams = new URLSearchParams();
    
    param.append('relationship', this.relationstatus);
    param.append('married',this.marriedno);
    param.append('children',this.childno);
    param.append('education',this.education);
    //param.append('career',this.occupation);
    param.append('income',this.uincome);
    param.append('new_family',this.kidstatus);
    param.append('smoke',this.smoke);
    param.append('drink',this.udrink);
    param.append('pet',this.upet);
    param.append('weight',this.uweight);
    param.append('availabilityofcar',this.availabilityofcar);
    param.append('housingcondition',this.housingcondition);

    param.append('ubody_type',this.ubody_type);
    param.append('ueye_color',this.ueye_color);
    param.append('upiercing',this.upiercing);
    param.append('ueducation_institution',this.ueducation_institution);
    param.append('ufield_of_activity',this.ufield_of_activity);
    param.append('uyear_of_release',this.uyear_of_release);
    param.append('upost',this.upost);
    param.append('uorientation',this.uorientation);

    //param.append('three_thankfuls',this.utility.arrjoin(this.thankful,'/'));
    //console.log(param.toString());

    this.api.PostWithToken( param,'update-profile')
    .then(data => {
      this.loaderimggan = false;
      console.log(data);
      if(data.error > 0)
        throw data.errmsg;

    })
    .catch(error =>{
      console.log(error);
    })

  }
  
  selfPortrait(title,label, key, oldvalue): void {
    this.self_portrait_title = title;
    this.self_portrait_label = label;
    this.self_portrait_key = key;
    this.self_portrait_oldvalue = oldvalue;
    $('#'+this.self_portrait_key).val(oldvalue)
    $('#selfPortrait').modal('show')
  }

  removeToFavt(user, index): void {
    this.btnindex = index;
    let param: URLSearchParams = new URLSearchParams();
    param.append('fid', user.id)
    this.api.PostWithToken(param, 'remove-from-favorite')
      .then(data => {
        if (data.error > 0) {
          throw data.errmsg;
        }
        this.toastr.success('You have remove ' + user.name + ' from your mutual list. ', 'Success');
        this.btnindex = -1;
        //this.matcheslist.splice(index,1);
        user.ismutal = false;
        user.isfavorite = false;
      })
      .catch(error => {
        this.btnindex = -1;
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })
  }

  addToFavt(user, index):void {
    this.btnindex = index;
    let param:URLSearchParams = new URLSearchParams();
    param.append('fid', user.id)
    this.api.PostWithToken( param,'add-as-favorite')
    .then(data => {
      if(data.error > 0){
        throw data.errmsg;
      }
      this.toastr.success('You have added ' + user.name + ' to your favorite list. ', 'Success');
      this.btnindex = -1;
      //this.chat.sendNewMsgNotification({id: user.id, msg: data.response, slug: this.userinfo.uslug, action: 'add-as-favorite'});
    })
    .catch(error =>{
      this.btnindex = -1;
      this.toastr.error('Can not process now. Please try later.', 'Warning');
    })
  }

  loadimgmulti(fileobj:any):void {
    let imgobj = ()=>{};
    let files = fileobj.target.files;
    let filesLength = files.length;

    for (let i = 0; i < filesLength; i++){
      let f = files[i];
      let fileReader = new FileReader();
      fileReader.readAsDataURL(f);
      fileReader.onload = (e:Event) => {
        let imgs = new imgobj();
        imgs.name = f.name;
        imgs.objfile = e.target;
        imgs.file = f;
        imgs.img = fileReader.result;
        this.imglist.push(imgs);
      };
      
    }
    this.savetempimage = true;
    setTimeout(() => {
      if(this.imglist.length != 0)
      this.uploadimage();
    }, 300);
    
    
  }

  tmpImageDelete(index):void {
    this.imglist.splice(index, 1);

    if(this.imglist.length == 0)
      this.savetempimage = false;
  }

  uploadimage():any {
    this.loaderimg = true;
    let formData:FormData = new FormData();
    let files:any = [];
    this.imglist.forEach(function(item){
      formData.append('imageFile[]', item.file);
    })
    if(this.imglist.length == 0)
      return true;

    this.api.uploadImages(formData, 'upload-gallery')
    .then(data => {
      if(data.error){
        throw data.error;
      }
        
      
      this.loaderimg = false;
      this.imglist = [];
      this.savetempimage = false;
      this.reloadUserInfo();
      this.chat.sendNewMsgNotification({id: this.user.uid, msg: this.user.uname + " recently has updated " + (this.user.ugender ==  "1" ? "his" : "her") + " profile.", slug: this.user.uslug, action: 'update-profile'});

      this.toastr.success('Phote has been updated successfully.', 'Success');
    })
    .catch(error =>{
      this.toastr.error('Can not process. please try later.', 'Error');
    })
  }

  reloadUserInfo():void {
    this.user.loadLoginUserInfo()
    .then(data => {
      if(data.error > 0)
        throw data.errmsg;

      this.user.loadData(data.response);
    })
    .catch(error =>{
      this.toastr.error('Can not process. please try later.', 'Error');
    })
  }

  loadimg(fileobj:any):void {
    this.loader ='assets/img-loader.svg';
    this.profile_loader = true;
    let file = fileobj.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (e:Event) => {
      let formData:FormData = new FormData();
      formData.append('imageFile', file);
      this.api.uploadImages(formData, 'upload-image')
      .then(data => {
        if(data.error)
          throw data.error;
        
        this.user.imgPreview = data.response.url;
        this.user.imgPrviewID  = data.response.imgid;
        this.profile_loader = false;
        this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response.msg, slug: this.user.uslug, action: 'update-profile'});
        setTimeout(function(){ window.location.reload(); }, 500);
      })
      .catch(error =>{
        console.log(error);
        this.profile_loader = false;
      })
    };
  }

  deleteImg(confirm:boolean):boolean {
    if(!confirm)
      return false;
    
    this.loader ='assets/del-loader.svg';
    this.profile_loader = true;
    let param:URLSearchParams = new URLSearchParams();
    param.append('image_id', this.user.imgPrviewID);
    this.api.PostWithToken(param, 'delete-image')
    .then(data => {
      if(data.error)
        throw data.errmsg;

      this.user.imgPreview = this.user.ugender = 1 ? this.utility.maleimg : this.utility.femaleimg;
      this.user.imgPrviewID = '';
      this.profile_loader = false;
      window.location.reload();
    })
    .catch(error =>{
      console.log(error);
    })

    return true;
  }

  ftToCm():void {
    let ft:number = parseInt(this.tall.ft);
    let inch:number = parseInt(this.tall.inch);
    
    if(isNaN(ft))
      ft = 0;
    if(isNaN(inch))
      inch = 0;

    this.tall.cm =  ((ft * 12) + inch) * 2.54;
    this.tall.cm = parseInt(this.tall.cm);
  }
  updateProfileOtherSelf(isvalid): boolean {
    this.loaderimgotherself = true;
    if ($('#'+this.self_portrait_key).val() == '') {
      this.self_portrait_error = true;
      return false;
    }

    this.self_portrait_error = false;
    
    let formData: FormData = new FormData();
    
    formData.append(this.self_portrait_key, $('#'+this.self_portrait_key).val());
    
    this.api.PostWithTokenImage(formData, 'update-profile')
      .then(data => {
        $('#selfPortrait').modal('hide')
        
        if (data.error) {
          throw data.error;
        }
      this.reloadUserInfo();  
      this.loaderimgotherself = false;  
      this.toastr.success('Profile has been updated successfully.', 'Success');
      $('#'+this.self_portrait_key).val(this.user.what_qualities_do_you_value_in_people)  
      //this.toastr.success(this.ntfMsg.generalSucessMsg, this.ntfMsg.generalSuccessSub);
      //this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});
        
      })
      .catch(error => {
        this.toastr.error('Can not process. please try later.', 'Error');
      })
    return true;
  }
  
  updateProfileOther(isvalid): boolean {
    this.loaderimgother = true;
    // if (!isvalid) {
    //   return false;
    // }
    
    let formData: FormData = new FormData();
    
    formData.append('imlookingfor', this.uimlookingfor);
    formData.append('interests', this.uinterests);
    formData.append('theimportantthing', this.utheimportantthing);
    formData.append('details_about_me', this.aboutme);    
    formData.append('details_about_me', this.aboutme);
    
    this.api.PostWithTokenImage(formData, 'update-profile')
      .then(data => {
        $('#otherinformation').modal('hide')
        if (data.error) {
          throw data.error;
        }
      this.reloadUserInfo();  
      this.loaderimgother = false;  
      this.toastr.success('Profile has been updated successfully.', 'Success');

      //this.toastr.success(this.ntfMsg.generalSucessMsg, this.ntfMsg.generalSuccessSub);
      this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});
        
      })
      .catch(error => {
        this.toastr.error('Can not process. please try later.', 'Error');
      })
    return true;
  }

  updateProfile(isvalid): boolean {
    this.loaderimg = true;
    // if (!isvalid) {
    //   return false;
    // }
    
    let formData: FormData = new FormData();
    
    formData.append('name', this.uname);
    formData.append('is_online', this.is_onilne);
    formData.append('dob', this.udob);
    //formData.append('feet', this.feet);
    //formData.append('inches', this.inches);
    formData.append('city', this.city);
    formData.append('country', this.ucountry);
    formData.append('tall', this.feet+'/'+this.inches);
    
    this.api.PostWithTokenImage(formData, 'update-profile')
      .then(data => {
        $('#editProfileModal').modal('hide')
        if (data.error) {
          throw data.error;
        }
      this.reloadUserInfo();  
      this.loaderimg = false;  
      this.toastr.success('Profile has been updated successfully.', 'Success');

      //this.toastr.success(this.ntfMsg.generalSucessMsg, this.ntfMsg.generalSuccessSub);
      this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});
        
      })
      .catch(error => {
        this.toastr.error('Can not process. please try later.', 'Error');
      })
    return true;
  }

  saveProfile(isvalid:boolean, field:string, value:string):boolean {
    if(!isvalid){
      this.toastr.error(this.ntfMsg.generalErrMsg, this.ntfMsg.generalErrSub);
      this.city  = this.user.ucity;
      this.tall.ft  = this.user.uheight.ft;
      this.tall.inch = this.user.uheight.inch;
      return false;
    }
     // return;

    let param:URLSearchParams = new URLSearchParams();
    param.append(field, value);
    this.api.PostWithToken( param,'update-profile')
    .then(data => {
      console.log(data);
      if(data.error > 0)
        throw data.errmsg;

      if(field == 'city'){
        this.cityEdit = false;
        this.user.ucity = this.city;
      }

      if(field == 'tall'){
        this.heightEdit = false;
        this.user.uheight.ft  = this.tall.ft;
        this.user.uheight.inch = this.tall.inch;
      }
      this.toastr.success(this.ntfMsg.generalSucessMsg, this.ntfMsg.generalSuccessSub);
      this.chat.sendNewMsgNotification({id: this.user.uid, msg: data.response, slug: this.user.uslug, action: 'update-profile'});
    })
    .catch(error =>{
      console.log(error);
    })

    return true;
  }
}

