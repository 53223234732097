import { Component, OnInit } from '@angular/core';
import { URLSearchParams } from '@angular/http';

import { UtilityService } from '../utility/utility.service'
import { ApicallService } from '../app-service/apicall.service';
import { UserinfoService } from '../userinfo/userinfo.service';
import { OptionDataService } from '../option-data/option-data.service';

declare var $:any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  basiclist:any = [];
  premiumlist:any = [];
  selectedPlan:string = '';
  constructor(public utility:UtilityService, public api:ApicallService, public pdata:OptionDataService, public user:UserinfoService) { }

  ngOnInit() {
    
    this.user.listen.subscribe((data)=> {if(data) this.initAfterService()});
  }

  initAfterService():void {
    //this.initTab();
    this.getPlanlist();
  }

  getPlanlist():void {
    let param:URLSearchParams = new URLSearchParams();
    this.api.PostWithToken( param, 'subscription-plan')
    .then(data => {
      if(data.error)
        throw data.error;

      
      this.basiclist = data.response.basic;
      this.premiumlist = data.response.premium;

      setTimeout( ()=>{
        this.initTab();
        }, 500)
      
      
    })
    .catch(error =>{
      console.log(error);
    })
  }

  initTab():void {
    $('.owl-carousel').owlCarousel({
      loop:true,
      margin:0,
      nav:true,
      navText : ["<i class='icon-long-left'></i>","<i class='icon-long-right'></i>"],
      responsive:{
          0:{
              items:1
          },
          768:{
              items:1
          },
          1000:{
              items:3
          }
      }
  });
    // $('#horizontalTab').responsiveTabs({
    //   rotate: false,
    //   startCollapsed: 'accordion',
    //   collapsible: 'accordion',
    //   setHash: false,
    // });

    // $('#verticalTab').responsiveTabs({
    //   rotate: false,
    //   startCollapsed: 'accordion',
    //   collapsible: 'accordion',
    //   setHash: false,
    // });
  }

  setPlan(plan, event){
    this.selectedPlan = plan.id
    console.log(event.target.checked);
    console.log(this.selectedPlan)
  }
}
