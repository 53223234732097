import { Component, OnInit } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { Router } from '@angular/router';

import { UtilityService } from '../utility/utility.service';
import { ApicallService } from '../app-service/apicall.service';
import { OptionDataService } from '../option-data/option-data.service';

declare var $:any;
@Component({
  selector: 'app-profile-setup',
  templateUrl: './profile-setup.component.html',
  styleUrls: ['./profile-setup.component.css']
})
export class ProfileSetupComponent implements OnInit {

  Object = Object;
  relationstatus:string = '1';
  marriedno:string = '0';
  childno:string = '0';
  city:string = '';
  dob:string;
  ethnicity:string = '1';
  spirituality:string = '1';
  education:string = '1';
  occupation:string ='';
  income:string = '2';
  kidstatus:string = '3';
  drink:string = "Never";
  smoke:string = 'Never';
  tall:any = {
    ft:'1',
    inch:'00',
    cm:'0'
  };

  passionate:string = '';
  leisure:string = '';
  thankful:any = {'one': '','two': '','three': ''};
  validationError:string = '';

  constructor(public pdata:OptionDataService, public utility:UtilityService, public api:ApicallService, private router: Router) { 
    this.dob = this.pdata.defultEndDate;
  }

  ngOnInit() {
    this.loadDatePicker();
    this.ftToCm();
  }

  ftToCm():void {
    let ft:number = parseInt(this.tall.ft);
    let inch:number = parseInt(this.tall.inch);
    this.tall.cm =  ((ft * 12) + inch) * 2.54;
    this.tall.cm = parseInt(this.tall.cm);
  }
  loadDatePicker():void {
    let self = this;
    var date_input = $('input[name="date"]');
    let options = {
      format: 'mm/dd/yyyy',
      todayHighlight: true,
      autoclose: true,
      endDate: self.pdata.defultEndDate
    };
    date_input.datepicker(options);
  }
  saveProfile(isValid:boolean):any {
    if(!isValid){
      this.validationError = 'Some fields are not filled up properly. Please go above and fill all fields...';
      return false;
    }

    let param:URLSearchParams = new URLSearchParams();
    let dobstr = $('input[name="date"]').val();
    if(dobstr==''){
      dobstr = this.pdata.defultEndDate;
    }
    param.append('relationship', this.relationstatus);
    param.append('married',this.marriedno);
    param.append('children',this.childno);
    param.append('city',this.city);
    param.append('dob', this.utility.toMySqlDateFormat(dobstr));
    param.append('ethnicity',this.ethnicity);
    param.append('spirituality',this.spirituality);
    param.append('education',this.education);
    param.append('career',this.occupation);
    param.append('income',this.income);
    param.append('new_family',this.kidstatus);
    param.append('smoke',this.smoke);
    param.append('tall',this.utility.arrjoin(this.tall,'/'));
    param.append('drink',this.drink);
    param.append('passionate',this.passionate);
    param.append('leisure_time',this.leisure);
    param.append('three_thankfuls',this.utility.arrjoin(this.thankful,'/'));
    //console.log(param.toString());

    this.api.PostWithToken( param,'profile-setUp')
    .then(data => {
      console.log(data);
      if(data.error > 0)
        throw data.errmsg;

      this.router.navigate(['/preference']);
    })
    .catch(error =>{
      console.log(error);
    })

  }

}
