import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { URLSearchParams } from '@angular/http';

import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { UtilityService } from '../../utility/utility.service'
import { ApicallService } from '../../app-service/apicall.service';
import { UserinfoService } from '../../userinfo/userinfo.service';
import { OptionDataService } from '../../option-data/option-data.service';

declare var $: any;

@Component({
  selector: 'app-age',
  templateUrl: './age.component.html',
  styleUrls: ['./age.component.css']
})

export class AgeComponent implements OnInit {

  agearr: any = [];
  ageimpt: string = '';
  dob: string;
  Object = Object;
  constructor(public utility: UtilityService, public api: ApicallService, public pdata: OptionDataService, public user: UserinfoService, public toastr: ToastsManager, public vcr: ViewContainerRef) {
    // this.toastr.setRootViewContainerRef(vcr);
    this.dob = this.pdata.defultEndDate;
  }

  ngOnInit() {
    for (let i = 18; i <= 90; i++) {
      this.agearr.push(i);
    }

    this.user.listen.subscribe(data => this.initAfterService());
    this.loadDatePicker();
  }

  initAfterService() {
    //console.log(this.user);
    if (!this.user.prefagefrom)
      this.user.prefagefrom = '18';

    if (!this.user.prefageto)
      this.user.prefageto = '70';

    this.dob = this.utility.fromMySqlDateFormat(this.user.udob);
  }

  updateAgePreference(): void {
    let param: URLSearchParams = new URLSearchParams();

    param.append('from_age', this.user.prefagefrom);
    param.append('to_age', this.user.prefageto);

    this.api.PostWithToken(param, 'update-profile-preference')
      .then(data => {

        if (data.error > 0)
          throw data.errmsg;

        $("#prefage").modal('hide');
        this.toastr.success('Age has been updated successfully. ', 'Success');
      })
      .catch(error => {
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })
  }

  updateImportantPreference(): void {
    let param: URLSearchParams = new URLSearchParams();

    param.append('important_age', this.ageimpt);
    this.api.PostWithToken(param, 'update-profile-preference')
      .then(data => {
        console.log(data);
        if (data.error > 0)
          throw data.errmsg;

        this.user.prefageimt = this.ageimpt;
        $("#important").modal('hide');
        this.toastr.success('Age Importance has been updated successfully. ', 'Success');
      })
      .catch(error => {
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })
  }

  loadDatePicker(): void {
    let self = this;
    var date_input = $('input[name="date"]');
    let options = {
      format: 'mm/dd/yyyy',
      todayHighlight: true,
      autoclose: true,
      endDate: self.pdata.defultEndDate
    };
    date_input.datepicker(options);
  }

  saveDateOfBirth(): void {
    let param: URLSearchParams = new URLSearchParams();
    this.dob = $('input[name="date"]').val();
    if (this.dob == '') {
      this.dob = this.utility.fromMySqlDateFormat(this.user.udob);
      $('input[name="date"]').val(this.dob);
    }
    param.append('dob', this.utility.toMySqlDateFormat(this.dob));
    this.api.PostWithToken(param, 'profile-setUp')
      .then(data => {
        console.log(data);
        if (data.error > 0)
          throw data.errmsg;

        this.user.prefageimt = this.ageimpt;
        $("#predob").modal('hide');
        this.toastr.success('Date of birth has been updated successfully. ', 'Success');
      })
      .catch(error => {
        this.toastr.error('Can not process now. Please try later.', 'Warning');
      })

  }


}
